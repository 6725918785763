import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat, timeFormat } from '../../../dynamicController';
import Loading from './Loading';
import { DatePicker, Row, Col } from "antd";
import dayjs from 'dayjs';
import { Select } from 'antd';

const mySwal = withReactContent(Swal);
const { RangePicker } = DatePicker;

const ReferralRewardHistoryReport = ({ token, role, operators, distributors, stamp, etoken, point }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [distributorId, setDistributorId] = useState("");
    const [operatorId, setOperatorId] = useState("");

    useEffect(() => {
        if (role === "operator" || role === "distributor") {
            setLoading(true)
            axios
                .post("/api/admin/report/referralRewardHistoryReport", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const handleDate = dates => {
    //     const [start, end] = dates;

    //     setStartDate(start)
    //     setEndDate(end)
    // };

    const handleDate = (dates) => {
        if (dates) {
            const [start, end] = dates;
            setStartDate(start.toISOString());
            setEndDate(end.toISOString());
        } else {
            setStartDate("");
            setEndDate("");
        }
    };

    const handleFilter = () => {

        if (role === "client" && !distributorId) {
            mySwal.fire(t("Error"), t("Please select distributor"), "error");
        } else {
            let sendThis = {
                operatorId,
                distributorId
            }

            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }

            setLoading(true)

            axios
                .post("/api/admin/report/referralRewardHistoryReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setData([])
        setDistributorId("")
        setOperatorId("")

        if (role === "operator" || role === "distributor") {
            setLoading(true)

            axios
                .post("/api/admin/report/referralRewardHistoryReport", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    let dataTable = {
        columns: [
            {
                label: t("Date"),
                field: "date",
                sort: "asc"
            },
            {
                label: t("Reward To"),
                field: "reward_to",
                sort: "asc"
            },
            {
                label: t("Reward From"),
                field: "reward_from",
                sort: "asc"
            },
            {
                label: t("Transaction Account"),
                field: "transaction_account",
                sort: "asc"
            },
            {
                label: t("Tier"),
                field: "tier",
                sort: "asc"
            },
            {
                label: t("Voucher"),
                field: "voucher",
                sort: "asc"
            },
        ],
        rows: data
    };

    const csvData = {
        headers: [
            { label: "Date", key: "date" },
            { label: "Reward To", key: "reward_to" },
            { label: "Reward From", key: "reward_from" },
            { label: "Transaction Account", key: "transaction_account" },
            { label: "Tier", key: "tier" },
            { label: "Voucher", key: "voucher" },
        ],
        data: data
    };

    if (stamp) {
        dataTable.columns.push(
            {
                label: t("Washer Stamp"),
                field: "washer_stamp",
                sort: "asc"
            },
            {
                label: t("Dryer Stamp"),
                field: "dryer_stamp",
                sort: "asc"
            }
        )

        csvData.headers.push(
            { label: "Washer Stamp", key: "washer_stamp" },
            { label: "Dryer Stamp", key: "dryer_stamp" },
        )
    }

    if (etoken) {
        dataTable.columns.push(
            {
                label: t("Token"),
                field: "token",
                sort: "asc"
            },
        )

        csvData.headers.push(
            { label: "Token", key: "token" },
        )
    }

    if (point) {
        dataTable.columns.push(
            {
                label: t("Point"),
                field: "point",
                sort: "asc"
            },
        )

        csvData.headers.push(
            { label: "Point", key: "point" },
        )
    }

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("Referral Reward History Report")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div>
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    value={distributorId}
                                    onChange={value => setDistributorId(value)}
                                    optionFilterProp="children"
                                >
                                    <Select.Option disabled value="">{t("Select Distributor")}</Select.Option>
                                    {
                                        distributors.map(distributor => (
                                            <Select.Option key={distributor.id} value={distributor.id}>
                                                {distributor.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        ) : null
                    }

                    <Row gutter={16} className="mt-3">
                        <Col span={24}>
                            <label htmlFor="date">{t("Select Date")}: </label>
                            <RangePicker
                                onChange={handleDate}
                                style={{ width: '100%', marginBottom: '10px' }}
                                value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                                format="YYYY-MM-DD"
                                ranges={{
                                    'Today': [dayjs(), dayjs()],
                                    'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                    'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                    'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                }}
                            />
                        </Col>
                    </Row>

                    <div className="d-flex">
                        <button onClick={handleFilter} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={handleReset} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>
                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={dataTable} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`referral_reward_history_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ReferralRewardHistoryReport;