const initState = {
	news: null,
	qna: null,
	isLoading: true
};

const assistantReducer = (state = initState, action) => {
	// console.log(action);
	return state;
};

export default assistantReducer;
