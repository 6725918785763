import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import i18n from "i18next";

class PieTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Transactions"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [],
            rows: []
        }

        if (this.state.filter === "Transactions") {
            data.columns = [
                {
                    label: i18n.t("Outlets"),
                    field: "outlets",
                    sort: "asc"
                },
                {
                    label: i18n.t("Transactions"),
                    field: "transactions",
                    sort: "asc"
                },
                {
                    label: `% ${i18n.t("Transactions")}`,
                    field: "transactionsPercentage",
                    sort: "asc"
                }
            ]

            for (let i = 0; i < this.props.outlets.length; i++) {
               let outlet = this.props.pieTableData.transactions.outletTransaction.filter(outlet => outlet.id === this.props.outlets[i].id)
                const dataObject = {
                    outlets: this.props.outlets[i].outlet_fullname,
                    transactions: outlet.length > 0 ? outlet[0].totalTransactionNumber : 0,
                    transactionsPercentage: (
                        <div style={{ display: "flex", paddingLeft: "5px" }}>
                            <ProgressBar now={( (outlet.length > 0 ? outlet[0].totalTransactionNumber : 0) / this.props.pieTableData.transactions.totalTransactionNumber) * 100} />
                            <span className="px-2">{this.props.pieTableData.transactions.totalTransactionNumber !== 0 ? (( (outlet.length > 0 ? outlet[0].totalTransactionNumber : 0) / this.props.pieTableData.transactions.totalTransactionNumber) * 100).toFixed(2) : 0}%</span>
                        </div>
                    )
                }
                data.rows.push(dataObject);
            }

        } else if (this.state.filter === "E-Payment Sales") {
            data.columns = [
                {
                    label: i18n.t("Outlets"),
                    field: "outlets",
                    sort: "asc"
                },
                {
                    label: i18n.t("E-Payment Sales"),
                    field: "sales",
                    sort: "asc"
                },
                {
                    label: `% ${i18n.t("E-Payment Sales")}`,
                    field: "salesPercentage",
                    sort: "asc"
                }
            ]

            for (let i = 0; i < this.props.outlets.length; i++) {
                let outlet = this.props.pieTableData.transactions.outletTransaction.filter(outlet => outlet.id === this.props.outlets[i].id)
                const dataObject = {
                    outlets: this.props.outlets[i].outlet_fullname,
                    sales: outlet.length > 0 ? outlet[0].totalTransactionAmount : 0,
                    salesPercentage: (
                        <div style={{ display: "flex", paddingLeft: "5px" }}>
                            <ProgressBar now={( (outlet.length > 0 ? outlet[0].totalTransactionAmount : 0) / this.props.pieTableData.transactions.totalTransactionAmount) * 100} />
                            <span className="px-2">{this.props.pieTableData.transactions.totalTransactionAmount !== 0 ? (( (outlet.length > 0 ? outlet[0].totalTransactionAmount : 0) / this.props.pieTableData.transactions.totalTransactionAmount) * 100).toFixed(2) : 0}%</span>
                        </div>
                    )
                }
                data.rows.push(dataObject);
            }
        }

        return (
            <div>
                <div className="pb-3">
                    <label htmlFor="filter">{i18n.t("Filter")}: </label>
                    <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                        <option value="Transactions">{i18n.t("Transactions")}</option>
                        <option value="E-Payment Sales">{i18n.t("E-Payment Sales")}</option>
                    </select>
                </div>
                <div className="text-center mt-3">
                    <MDBTable scrollY striped hover bordered>
                        <MDBTableHead columns={data.columns} />
                        <MDBTableBody rows={data.rows} />
                    </MDBTable>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        outlets: state.admin.admin.outlets
    };
};

export default connect(mapStateToProps, null)(PieTable);