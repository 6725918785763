import { useState, useMemo } from "react";
import { connect } from "react-redux";

import Sidebar from "../utilities/Sidebar";

import { changeNav } from "../../../redux/actions/userActions";

import Timer from "./dashboard/Timer";
import CleanproHeader from "./dashboard/header/cleanproHeader";
import LaundroHeader from "./dashboard/header/laundroHeader";
import WashupHeader from "./dashboard/header/washupHeader";
import CleanproDashboaardBody from "./dashboard/body/cleanproDashboardBody";
import LaundroDashboardBody from "./dashboard/body/laundroDashboardBody";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Dashboard = ({ user, history, changeNav }) => {
  const [toggled, setToggled] = useState(false);

  const renderTimer = useMemo(() => {
    let timerTransaction = []
    if (user) {
      const userTransaction = user.transactions.filter(tran => tran.status === "Success" && (tran.machine_type === "Washer" || tran.machine_type === "Dryer") && new Date(tran.updatedAt).setHours(new Date(tran.updatedAt).getHours() + 24) > new Date())
      for (let i = 0; i < userTransaction.length; i++) {
        const machineTran = timerTransaction.filter(tran => tran.machine_name === userTransaction[i].machine_name)
        if (machineTran.length > 0) {
          if (new Date(userTransaction[i].updatedAt) > new Date(machineTran[0].updatedAt)) {
            timerTransaction = timerTransaction.filter(tran => tran.id !== machineTran[0].id)
            timerTransaction.push(userTransaction[i])
          }
        } else {
          timerTransaction.push(userTransaction[i])
        }
      }

      return (
        timerTransaction.map((tran) => {
          return (
            <Timer key={tran.id} tran={tran} history={history} />
          )
        })
      )
    } else {
      return null
    }
  }, [user, history])

  return (
    <div id="user-dashboard">
      <Sidebar toggled={toggled} setToggled={setToggled} />
      {
        STORETYPE === "cleanpro"
          ? <CleanproHeader user={user} setToggled={setToggled} changeNav={changeNav} />
          : STORETYPE === "washup"
            ? <WashupHeader user={user} setToggled={setToggled} />
            : <LaundroHeader user={user} setToggled={setToggled} />
      }

      {
        STORETYPE === "cleanpro" || STORETYPE === "washup"
          ? <CleanproDashboaardBody user={user} renderTimer={renderTimer} history={history} />
          : <LaundroDashboardBody user={user} renderTimer={renderTimer} history={history} />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
