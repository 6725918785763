import { useEffect } from 'react';
import { connect } from 'react-redux';
import DistributorLogin from './pages/Login';
import DistributorDashboard from './pages/DistributorDashboard';
import { checkToken, distributorTokenLogin, saveDistributorRedirectUrl, saveDistributorUserType } from "../../redux/actions/distributorActions";
import { changeLanguage } from '../../redux/actions/userActions';
import queryString from 'query-string';

const Distributor = ({ isAuthenticated, checkToken, location, tokenLogin, saveDistributorRedirectUrl, saveDistributorUserType, changeLanguage }) => {

    useEffect(() => {
        const { token, redirectUrl, userType, language } = queryString.parse(location.search);
        if (redirectUrl) {
            saveDistributorRedirectUrl(redirectUrl)
        }
        if (userType) {
            saveDistributorUserType(userType)
        }
        if (language) {
            changeLanguage(language)
        }
        if (token) {
            tokenLogin({ token });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Loyalty Login',
                'brand': process.env.REACT_APP_STORETYPE,
                'role': 'distributor'
            })
        } else {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {
                isAuthenticated
                    ? <DistributorDashboard />
                    : <DistributorLogin />
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.distributor.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        tokenLogin: token => dispatch(distributorTokenLogin(token)),
        saveDistributorRedirectUrl: url => dispatch(saveDistributorRedirectUrl(url)),
        saveDistributorUserType: data => dispatch(saveDistributorUserType(data)),
        changeLanguage: (language) => dispatch(changeLanguage(language))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Distributor);