import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { currency } from '../../../../dynamicController';

const PurchaseHistory = ({ purchaseHistory, user }) => {

	const { t } = useTranslation();

	if (!purchaseHistory.length) {
		return (
			<div className="text-center mt-5">
				<h3> - {t("No Purchase History")} - </h3>
			</div>
		);
	} else {
		return (
			<div>
				<ul className="list-group" style={{ maxHeight: "52vh", overflowX: "hidden", overflowY: "auto", boxSizing: "border-box" }}>
					{purchaseHistory.map(x => {
						return (
							<li key={x.id} className="list-group-item px-4">
								<div>
									<div className="d-flex justify-content-between">
										<p className="m-0 p-0">{currency(user.country)} {parseFloat(x.amount).toFixed(2)}</p>
										<h5 className="m-0 p-0">{x.token} {t("e-tokens")}</h5>
									</div>

									<div>
										<p className="m-0 p-0 font-weight-bold">{new Date(x.createdAt).toLocaleString("en-GB")}</p>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps, null)(PurchaseHistory);
