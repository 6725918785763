const Loading = () => {
    return (
        <div className="text-center" style={{width : "100%" , height : "100%" , margin : 0 , padding : "5px 0"}}>
            <div style={{width : "80px" , height : "80px" , fontWeight : "bold" , fontSize : "36px" , margin : "10px" , padding : 0}} className={process.env.REACT_APP_STORETYPE === "cuci" ? "mt-5 spinner-border text-warning" : "mt-5 spinner-border text-primary"}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default Loading;