import { SCANNED_QRCODE, SAVE_CREDS, SAVE_TRANSACTION_ID } from "./type";

export const scannedCode = data => {
	return {
		type: SCANNED_QRCODE,
		payload: data
	};
};

export const saveCreds = data => {
	return {
		type: SAVE_CREDS,
		payload: data
	};
};

export const saveTransactionId = data => {
	return {
		type: SAVE_TRANSACTION_ID,
		payload: data
	};
};