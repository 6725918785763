import { DEV_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, FETCH_OPERATORS, REMOVE_TOKEN } from '../actions/type';

const initState = {
    isAuthenticated : false,
    isLoading: false,
    error: null,
    token: localStorage.getItem("laundry-dev"),
    operatorData: null
};

const devReducer = (state = initState, action) => {
    switch(action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false
            };
        case SHOW_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            };
        case DEV_LOGIN:
            localStorage.setItem("laundry-dev", action.payload);
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload
            };
        case REMOVE_TOKEN:
			localStorage.removeItem("laundry-dev");
			return {
				...state,
				isAuthenticated: false,
                token: null,
			};
        case FETCH_OPERATORS:
            return {
                ...state,
                operatorData: action.payload
            };
        default:
            return state;
    }
};

export default devReducer;