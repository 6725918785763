import axios from "axios"
import { UPDATE_THEME } from './type';

export const fetchTheme = data => dispatch => {
    axios.post('/api/admin/setting/fetchTheme', data)
        .then(res => dispatch({ type: UPDATE_THEME, payload: res.data.data }))
        .catch(err => {
            if (err.response.data.error === "Admin not found") {
                dispatch({
                    type: UPDATE_THEME,
                    payload: {
                        className: "default-theme",
                        logo: "laundroLogo"
                    }
                });
            }
            console.log(err.response);
        });
};