import { connect } from "react-redux";

const AboutUs = ({ history, operatorId }) => {
    return (
        <div id="agreement-page">
            <nav id="agreement-nav">
                <button onClick={e => history.goBack()} style={{ outline: "none", backgroundColor: "transparent", border: "none" }}><i className="bi bi-chevron-left"></i></button>
            </nav>

            <div className="mt-4 mb-5 px-5">
                <h3 style={{ color: "#1C75BC" }}>ABOUT US</h3>
                {
                    operatorId === "rblyrj9i59p2x036jnmjdsye" ?
                        <div style={{ textAlign: "justify" }}>
                            <p>Serviced Apartments Kota Kinabalu (Operated by Laksana Wawasan Sdn Bhd)</p>
                            <p>Citadines Waterfront Kota Kinabalu houses stylish, fully equipped rooms to suit every guest's needs. All living spaces come complete with a kitchenette, spacious bedroom, separate living and dining spaces, and other modern essentials.</p>
                            <p>Apart from these well-furnished serviced apartments in Kota Kinabalu, guests have easy access to a diverse range of excellent amenities and facilities, including the residents' lounge, swimming pool, gymnasium, launderette, and meeting rooms.</p>
                            <p>Strategically positioned within Sabah's capital city, the property enables guests to effortlessly reach a host of captivating destinations. From lively markets and  lavish shopping malls to serene city parks and even the Kota Kinabalu International Airport — every popular attraction is within easy reach.</p>
                            <p>For more information or room booking, click HERE to chat with us via WhatsApp!</p>
                            <p>Citadines Laundrette (Operated By Laksana Wawasan Sdn Bhd)</p>
                            <p>Guests can take advantage of our all-day access launderette facilities, complete with self-service washing machines and dryers to meet their needs.</p>
                            <p>HOURS: 24 hours</p>
                            <p>LOCATION: Level 6</p>
                            <p>Contact Us: enquiry.sabah@the-ascott.com</p>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        operatorId: state.user.operatorId,
    };
};

export default connect(mapStateToProps, null)(AboutUs);