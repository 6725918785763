import { ProSidebar, Menu, /*SubMenu,*/ MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { logo1Controller } from "../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, staff }) => {

	const { t } = useTranslation();

	const handleClick = (feature) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': feature,
			'user_id': staff ? staff.username : null,
			'brand': process.env.REACT_APP_STORETYPE,
			'role': 'staff'
		})
	}

	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/admin/dashboard">
					<img src={logo1Controller(themeLogo)} alt="logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-house-door-fill"></i>}>
						{t("Dashboard")} <Link to="/staff/dashboard" onClick={() => handleClick("Dashboard")} />
					</MenuItem>
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		staff: state.staff.staff
	};
};

export default connect(mapStateToProps, null)(Sidebar);
