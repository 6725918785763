import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class MemberDetailsReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            transaction: [],
            purchaseHistory: [],
            giveaway: [],
            transfer: [],
            voucher: [],
            dng: [],
            totalToken: [],
            stamp: [],
            point: [],
            transactionStatus: "Success",
            remark: "",
            showModal: false,
            transactionId: "",
            refundAmount: "",
            selectedTabs: "transaction",
        };

    }
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.client || !this.props.location.state || !this.props.client.user_detail) {
                this.props.history.push("/client");
            } else {
                const sendThis = {
                    operatorId: this.props.client.admin.map(admin => admin.id),
                    userId: this.props.location.state.userId,
                    transactionStatus: this.state.transactionStatus
                }

                axios
                    .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            transaction: res.data.transaction,
                            purchaseHistory: res.data.purchaseHistory,
                            giveaway: res.data.giveaway,
                            transfer: res.data.transfer,
                            voucher: res.data.voucher,
                            dng: res.data.dng,
                            totalToken: res.data.totalToken,
                            stamp: res.data.stamp,
                            point: res.data.point,
                        });
                    })
                    .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleFilter = e => {

        this.setState({
            ...this.state,
            loading: true,
            transactionStatus: e.target.value
        });

        const sendThis = {
            operatorId: this.props.client.admin.map(admin => admin.id),
            userId: this.props.location.state.userId,
            transactionStatus: e.target.value
        }

        axios
            .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    transaction: res.data.transaction,
                    purchaseHistory: res.data.purchaseHistory,
                    giveaway: res.data.giveaway,
                    transfer: res.data.transfer,
                    voucher: res.data.voucher,
                    dng: res.data.dng,
                    totalToken: res.data.totalToken,
                    stamp: res.data.stamp,
                    point: res.data.point,
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
            });
    };

    refund = () => {
        if (!this.state.remark) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please fill in the remark"), "error")
        } else {
            const sendThis = {
                transactionId: this.state.transactionId,
                refundBy: this.props.client.id,
                remark: this.state.remark,
                role: "client"
            }

            this.setState({
                ...this.state,
                loading: true,
                showModal: false
            });

            axios
                .post("/api/admin/setting/refund", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {

                    const sendThis = {
                        operatorId: this.props.client.admin.map(admin => admin.id),
                        userId: this.props.location.state.userId,
                        transactionStatus: "Refund"
                    }

                    axios
                        .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                        .then(res => {

                            this.setState({
                                ...this.state,
                                loading: false,
                                transaction: res.data.transaction,
                                purchaseHistory: res.data.purchaseHistory,
                                giveaway: res.data.giveaway,
                                transfer: res.data.transfer,
                                transactionStatus: "Refund",
                                remark: "",
                                transactionId: "",
                                refundAmount: "",
                                voucher: res.data.voucher,
                                dng: res.data.dng,
                                totalToken: res.data.totalToken,
                                stamp: res.data.stamp,
                                point: res.data.point,
                            });
                        })
                        .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));

                })
                .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
        }
    }

    requery = (orderId, userId, type) => {
        this.setState({
            ...this.state,
            loading: true
        });

        const api =
            type === "duitnow"
                ? "/api/token/checkDuitnowOrder"
                : type === "hitpay"
                    ? "/api/token/checkHitpayOrder"
                    : type === "razer"
                        ? "/api/token/checkRazerOrder"
                        : type === "scan2pay"
                            ? "/api/token/checkScan2PayOrder"
                            : "/api/token/checkRmOrder"

        axios
            .post(api, { userId, orderId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {

                const sendThis = {
                    operatorId: this.props.client.admin.map(admin => admin.id),
                    userId: this.props.location.state.userId,
                    transactionStatus: this.state.transactionStatus
                }

                axios
                    .post("/api/admin/report/memberDetails", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            transaction: res.data.transaction,
                            purchaseHistory: res.data.purchaseHistory,
                            giveaway: res.data.giveaway,
                            transfer: res.data.transfer,
                            voucher: res.data.voucher,
                            dng: res.data.dng,
                            totalToken: res.data.totalToken,
                            stamp: res.data.stamp,
                            point: res.data.point,
                        });
                    })
                    .catch(err => {
                        mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                    });
            })
            .catch(err => {
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
                this.setState({
                    ...this.state,
                    loading: false
                });
            })
    }

    render() {
        let transaction = {
            columns: [
                {
                    label: i18n.t("Transaction Date"),
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: i18n.t("Outlet"),
                    field: "outlet",
                    sort: "asc"
                },
                {
                    label: i18n.t("Machine"),
                    field: "machine",
                    sort: "asc"
                },
                {
                    label: i18n.t("Machine Type"),
                    field: "type",
                    sort: "asc"
                },
                {
                    label: i18n.t("Machine Capacity"),
                    field: "capacity",
                    sort: "asc"
                },
                {
                    label: i18n.t("Payment Method"),
                    field: "method",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Amount"),
                    field: "totalAmount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Payment Amount"),
                    field: "paymentAmount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount From"),
                    field: "discountFrom",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount Type"),
                    field: "discountType",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount Amount"),
                    field: "discountAmount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Discount Code"),
                    field: "discountCode",
                    sort: "asc"
                }
            ],
            rows: []
        };

        const transactionCsvData = {
            headers: [
                { label: "Transaction Date", key: "createdAt" },
                { label: "Outlet", key: "outlet" },
                { label: "Machine", key: "machine" },
                { label: "Machine Type", key: "type" },
                { label: "Machine Capacity", key: "capacity" },
                { label: "Payment Method", key: "method" },
                { label: "Total Amount", key: "totalAmount" },
                { label: "Payment Amount", key: "paymentAmount" },
                { label: "Discount From", key: "discountFrom" },
                { label: "Discount Type", key: "discountType" },
                { label: "Discount Amount", key: "discountAmount" },
                { label: "Discount Code", key: "discountCode" },
            ],
            data: []
        };

        if (this.state.transactionStatus === "Success") {
            transaction.columns.push(
                {
                    label: i18n.t("Action"),
                    field: "action",
                    sort: "asc"
                }
            )
        }

        if (this.state.transactionStatus === "Failed") {
            transaction.columns.push(
                {
                    label: i18n.t("Remark"),
                    field: "remark",
                    sort: "asc"
                }
            )

            transactionCsvData.headers.push(
                { label: "Remark", key: "remark" }
            )
        }

        if (this.state.transactionStatus === "Refund") {
            transaction.columns.push(
                {
                    label: i18n.t("Remark"),
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: i18n.t("Refund By"),
                    field: "refundBy",
                    sort: "asc"
                },
                {
                    label: i18n.t("Refund Date"),
                    field: "refundDate",
                    sort: "asc"
                }
            )

            transactionCsvData.headers.push(
                { label: "Remark", key: "remark" },
                { label: "Refund By", key: "refundBy" },
                { label: "Refund Date", key: "refundDate" }
            )
        }

        if (this.state.transaction.length > 0) {
            for (let i = 0; i < this.state.transaction.length; i++) {
                const dataObject = {
                    createdAt: this.state.transaction[i].createdAt,
                    outlet: this.state.transaction[i].outlet,
                    machine: this.state.transaction[i].machine,
                    type: this.state.transaction[i].type,
                    capacity: this.state.transaction[i].capacity,
                    method: this.state.transaction[i].method,
                    totalAmount: this.state.transaction[i].method === "TOKEN" ? `${this.state.transaction[i].totalAmount} ${i18n.t("TOKEN")}` : `${currency(this.props.location.state.country)} ${(this.state.transaction[i].totalAmount).toFixed(2)}`,
                    paymentAmount: this.state.transaction[i].method === "TOKEN" ? `${this.state.transaction[i].paymentAmount} ${i18n.t("TOKEN")}` : `${currency(this.props.location.state.country)} ${(this.state.transaction[i].paymentAmount).toFixed(2)}`,
                    discountFrom: this.state.transaction[i].totalAmount === this.state.transaction[i].paymentAmount ? "-" : this.state.transaction[i].voucherId ? i18n.t("Voucher") : this.state.transaction[i].discountId ? i18n.t("Discount Code") : i18n.t("Promotion"),
                    discountType: this.state.transaction[i].totalAmount === this.state.transaction[i].paymentAmount ? "-" : this.state.transaction[i].voucherId ? this.state.transaction[i].voucherType : this.state.transaction[i].discountId ? this.state.transaction[i].discountType : "-",
                    discountAmount: this.state.transaction[i].totalAmount === this.state.transaction[i].paymentAmount ? "-" : this.state.transaction[i].voucherId ? this.state.transaction[i].voucherAmount : this.state.transaction[i].discountId ? this.state.transaction[i].discountAmount : "-",
                    discountCode: this.state.transaction[i].discountCode ? this.state.transaction[i].discountCode : "-",
                    action: (
                        this.state.transaction[i].method === "TOKEN" ?
                            <button type="button" className="btn btn-primary"
                                onClick={e => this.setState({
                                    ...this.state,
                                    showModal: true,
                                    transactionId: this.state.transaction[i].id,
                                    refundAmount: this.state.transaction[i].totalAmount
                                })}
                            >
                                {i18n.t("Refund")}
                            </button> : null
                    ),
                    remark: this.state.transaction[i].remark,
                    refundBy: this.state.transaction[i].refundBy,
                    refundDate: this.state.transaction[i].refundDate
                }

                transaction.rows.push(dataObject);

                transactionCsvData.data.push(dataObject)
            }
        }

        let purchaseHistory = {
            columns: [
                {
                    label: i18n.t("Purchase Date"),
                    field: "date",
                    sort: "asc"
                },
                {
                    label: i18n.t("e-Token Purchased (Tokens)"),
                    field: "token",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Bought e-Token using")} (${currency(this.props.location.state.country)})`,
                    field: "amount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Transaction ID"),
                    field: "transactionId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Reference ID"),
                    field: "referenceId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Method"),
                    field: "method",
                    sort: "asc"
                },
                {
                    label: i18n.t("Status"),
                    field: "status",
                    sort: "asc"
                },
                {
                    label: i18n.t("Action"),
                    field: "action",
                    sort: "asc"
                }
            ],
            rows: []
        };

        const purchaseHistoryCsvData = {
            headers: [
                { label: "Purchase Date", key: "date" },
                { label: "e-Token Purchased (Tokens)", key: "token" },
                { label: `Bought e-Token using (${currency(this.props.location.state.country)})`, key: "amount" },
                { label: "Transaction ID", key: "transactionId" },
                { label: "Reference ID", key: "referenceId" },
                { label: "Method", key: "method" },
                { label: "Status", key: "status" }
            ],
            data: this.state.purchaseHistory
        };

        if (this.state.purchaseHistory.length > 0) {
            for (let i = 0; i < this.state.purchaseHistory.length; i++) {
                const dataObject = {
                    date: this.state.purchaseHistory[i].date,
                    token: this.state.purchaseHistory[i].token,
                    amount: this.state.purchaseHistory[i].amount,
                    transactionId: this.state.purchaseHistory[i].transactionId,
                    referenceId: this.state.purchaseHistory[i].referenceId,
                    method: this.state.purchaseHistory[i].method,
                    status: i18n.t(this.state.purchaseHistory[i].status),
                    action: (
                        this.state.purchaseHistory[i].status !== "Success" && (this.state.purchaseHistory[i].type === "rm" || this.state.purchaseHistory[i].type === "scan2pay" || this.state.purchaseHistory[i].type === "razer" || this.state.purchaseHistory[i].type === "hitpay" || this.state.purchaseHistory[i].type === "duitnow") ?
                            <button type="button" className="btn btn-primary" onClick={() => this.requery(this.state.purchaseHistory[i].id, this.state.purchaseHistory[i].userId, this.state.purchaseHistory[i].type)}>
                                {i18n.t("Requery")}
                            </button>
                            : null
                    )
                }
                purchaseHistory.rows.push(dataObject)
            }
        }

        let giveaway = {
            columns: [
                {
                    label: i18n.t("Reward Date"),
                    field: "date",
                    sort: "asc"
                },
                {
                    label: i18n.t("Reward By"),
                    field: "rewardBy",
                    sort: "asc"
                },
                {
                    label: i18n.t("Reward Type"),
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: i18n.t("Remark"),
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: i18n.t("Notification Method"),
                    field: "notification_method",
                    sort: "asc"
                },
                {
                    label: i18n.t("Voucher ID"),
                    field: "voucherId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Voucher Type"),
                    field: "voucherType",
                    sort: "asc"
                },
                {
                    label: i18n.t("Voucher Amount"),
                    field: "voucherAmount",
                    sort: "asc"
                },
            ],
            rows: this.state.giveaway
        };

        const giveawayCsvData = {
            headers: [
                { label: "Reward Date", key: "date" },
                { label: "Reward By", key: "rewardBy" },
                { label: "Reward Type", key: "rewardType" },
                { label: "Remark", key: "remark" },
                { label: "Notification Method", key: "notification_method" },
                { label: "Voucher ID", key: "voucherId" },
                { label: "Voucher Type", key: "voucherType" },
                { label: "Voucher Amount", key: "voucherAmount" },
            ],
            data: this.state.giveaway
        };

        if (this.props.client.stamp) {
            giveaway.columns.push(
                {
                    label: i18n.t("Washer Stamp"),
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: i18n.t("Dryer Stamp"),
                    field: "dryerStamp",
                    sort: "asc"
                },
            );

            giveawayCsvData.headers.push(
                { label: "Washer Stamp", key: "washerStamp" },
                { label: "Dryer Stamp", key: "dryerStamp" }
            )
        }

        if (this.props.client.token) {
            giveaway.columns.push(
                {
                    label: i18n.t("e-Token Amount"),
                    field: "tokenAmount",
                    sort: "asc"
                },
            );

            giveawayCsvData.headers.push(
                { label: "e-Token Amount", key: "tokenAmount" }
            )
        }

        if (this.props.client.loyalty_point) {
            giveaway.columns.push(
                {
                    label: i18n.t("Point Amount"),
                    field: "pointAmount",
                    sort: "asc"
                }
            )

            giveawayCsvData.headers.push(
                { label: "Point Amount", key: "pointAmount" }
            )
        }

        let transfer = {
            columns: [
                {
                    label: i18n.t("Transfer Date"),
                    field: "date",
                    sort: "asc"
                },
                {
                    label: i18n.t("Transfer Amount (Token)"),
                    field: "amount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Number (Transfer From)"),
                    field: "transferFromNumber",
                    sort: "asc"
                },
                {
                    label: i18n.t("Email (Transfer From)"),
                    field: "transferFromEmail",
                    sort: "asc"
                },
                {
                    label: i18n.t("Name (Transfer From)"),
                    field: "transferFromName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Member ID (Transfer From)"),
                    field: "transferFromMemberId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Number (Transfer To)"),
                    field: "transferToNumber",
                    sort: "asc"
                },
                {
                    label: i18n.t("Email (Transfer To)"),
                    field: "transferToEmail",
                    sort: "asc"
                },
                {
                    label: i18n.t("Name (Transfer To)"),
                    field: "transferToName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Member ID (Transfer To)"),
                    field: "transferToMemberId",
                    sort: "asc"
                },
            ],
            rows: this.state.transfer
        };

        const transferCsvData = {
            headers: [
                { label: "Transfer Date", key: "date" },
                { label: "Transfer Amount (Token)", key: "amount" },
                { label: "Number (Transfer From)", key: "transferFromNumber" },
                { label: "Email (Transfer From)", key: "transferFromEmail" },
                { label: "Name (Transfer From)", key: "transferFromName" },
                { label: "Member ID (Transfer From)", key: "transferFromMemberId" },
                { label: "Number (Transfer To)", key: "transferToNumber" },
                { label: "Email (Transfer To)", key: "transferToEmail" },
                { label: "Name (Transfer To)", key: "transferToName" },
                { label: "Member ID (Transfer To)", key: "transferToMemberId" },
            ],
            data: []
        };

        if (this.state.transfer.length > 0) {
            for (let i = 0; i < this.state.transfer.length; i++) {
                const transferDataObject = {
                    date: this.state.transfer[i].date,
                    amount: this.state.transfer[i].amount,
                    transferFromNumber: `=""${this.state.transfer[i].transferFromNumber}""`,
                    transferFromEmail: this.state.transfer[i].transferFromEmail,
                    transferFromName: this.state.transfer[i].transferFromName,
                    transferFromMemberId: this.state.transfer[i].transferFromMemberId,
                    transferToNumber: `=""${this.state.transfer[i].transferToNumber}""`,
                    transferToEmail: this.state.transfer[i].transferToEmail,
                    transferToName: this.state.transfer[i].transferToName,
                    transferToMemberId: this.state.transfer[i].transferToMemberId
                }
                transferCsvData.data.push(transferDataObject)
            }
        }

        let voucher = {
            columns: [
                {
                    label: i18n.t("Grant Date"),
                    field: "grantDate",
                    sort: "asc"
                },
                {
                    label: i18n.t("Claim Date"),
                    field: "claimDate",
                    sort: "asc"
                },
                {
                    label: i18n.t("Voucher Amount"),
                    field: "voucherAmount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Voucher Type"),
                    field: "from",
                    sort: "asc"
                },
                {
                    label: i18n.t("Status"),
                    field: "status",
                    sort: "asc"
                },
                {
                    label: i18n.t("Washer Use"),
                    field: "washer_use",
                    sort: "asc"
                },
                {
                    label: i18n.t("Dryer Use"),
                    field: "dryer_use",
                    sort: "asc"
                },
                {
                    label: i18n.t("Package Use"),
                    field: "package_use",
                    sort: "asc"
                },
            ],
            rows: this.state.voucher
        };

        const voucherCsvData = {
            headers: [
                { label: "Grant Date", key: "grantDate" },
                { label: "Claim Date", key: "claimDate" },
                { label: "Voucher Amount", key: "voucherAmount" },
                { label: "Voucher Type", key: "from" },
                { label: "Status", key: "status" },
                { label: "Washer Use", key: "washer_use" },
                { label: "Dryer Use", key: "dryer_use" },
                { label: "Package Use", key: "package_use" },
            ],
            data: this.state.voucher
        };

        let totalToken = {
            columns: [
                {
                    label: i18n.t("Date"),
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: i18n.t("Method"),
                    field: "method",
                    sort: "asc"
                },
                {
                    label: i18n.t("Amount"),
                    field: "totalAmount",
                    sort: "asc"
                },
            ],
            rows: this.state.totalToken
        };


        const totalTokenCsvData = {
            headers: [
                { label: "Date", key: "createdAt" },
                { label: "Method", key: "method" },
                { label: "Amount", key: "totalAmount" },
            ],
            data: this.state.totalToken
        };

        let stamp = {
            columns: [
                {
                    label: i18n.t("Date"),
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: i18n.t("Type"),
                    field: "type",
                    sort: "asc"
                },
                {
                    label: i18n.t("Washer Stamp"),
                    field: "washer",
                    sort: "asc"
                },
                {
                    label: i18n.t("Dryer Stamp"),
                    field: "dryer",
                    sort: "asc"
                },
            ],
            rows: this.state.stamp
        };

        const stampCsvData = {
            headers: [
                { label: "Date", key: "createdAt" },
                { label: "Type", key: "type" },
                { label: "Washer Stamp", key: "washer" },
                { label: "Dryer Stamp", key: "dryer" },
            ],
            data: this.state.stamp
        };

        let dng = {
            columns: [
                {
                    label: i18n.t("Order Date"),
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: i18n.t("Outlet"),
                    field: "outlet_name",
                    sort: "asc"
                },
                {
                    label: i18n.t("Pack Number"),
                    field: "pack_number",
                    sort: "asc"
                },
                {
                    label: i18n.t("Method"),
                    field: "method",
                    sort: "asc"
                },
                {
                    label: i18n.t("Size"),
                    field: "size",
                    sort: "asc"
                },
                {
                    label: i18n.t("Capacity"),
                    field: "capacity",
                    sort: "asc"
                },
                {
                    label: i18n.t("Mode"),
                    field: "mode",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Price")} (${currency(this.props.location.state.country)})`,
                    field: "price",
                    sort: "asc"
                },
                {
                    label: i18n.t("Machine Payment"),
                    field: "payment",
                    sort: "asc"
                },
                {
                    label: i18n.t("Machine"),
                    field: "machine",
                    sort: "asc"
                },
                {
                    label: i18n.t("Status"),
                    field: "status",
                    sort: "asc"
                }
            ],
            rows: this.state.dng
        };

        const dngCsvData = {
            headers: [
                { label: "Order Date", key: "createdAt" },
                { label: "Outlet", key: "outlet_name" },
                { label: "Pack Number", key: "pack_number" },
                { label: "Method", key: "method" },
                { label: "Size", key: "size" },
                { label: "Capacity", key: "capacity" },
                { label: "Mode", key: "mode" },
                { label: `Price (${currency(this.props.location.state.country)})`, key: "price" },
                { label: "Machine Payment", key: "payment" },
                { label: "Machine", key: "machine" },
                { label: "Status", key: "status" },
            ],
            data: this.state.dng
        };

        let point = {
            columns: [
                {
                    label: i18n.t("Date"),
                    field: "createdAt",
                    sort: "asc"
                },
                {
                    label: i18n.t("Type"),
                    field: "type",
                    sort: "asc"
                },
                {
                    label: i18n.t("Amount"),
                    field: "amount",
                    sort: "asc"
                },
            ],
            rows: this.state.point
        };

        const pointCsvData = {
            headers: [
                { label: "Date", key: "createdAt" },
                { label: "Type", key: "type" },
                { label: "Amount", key: "amount" },
            ],
            data: this.state.point
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Member Details Report")} ({this.props.location.state ? this.props.location.state.memberId : ""})</h5>
                            </div>

                            <div className="card-body">
                                {
                                    this.state.loading ? <Loading /> : (
                                        <div>
                                            <Tabs activeKey={this.state.selectedTabs} onSelect={(k) => this.setState({ ...this.state, selectedTabs: k })}>
                                                <Tab eventKey="transaction" title={i18n.t("Transaction")}>
                                                    <div className="mb-3 mt-3">
                                                        <h6>{i18n.t("Filter")}</h6>
                                                        <div>
                                                            <label htmlFor="transactionStatus">{i18n.t("Transaction Status")}: </label>
                                                            <select id="transactionStatus" name="transactionStatus" className="browser-default form-select" value={this.state.transactionStatus} onChange={this.handleFilter}>
                                                                <option value="Success">{i18n.t("Success")}</option>
                                                                <option value="Failed">{i18n.t("Failed")}</option>
                                                                <option value="Refund">{i18n.t("Refund")}</option>
                                                                <option value="Pending">{i18n.t("Pending")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <MDBDataTable data={transaction} noBottomColumns striped hover responsive bordered />
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <CSVLink className="btn btn-primary" data={transactionCsvData.data} headers={transactionCsvData.headers} filename={`transaction_report(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                            {i18n.t("Download Report")}
                                                        </CSVLink>
                                                    </div>
                                                </Tab>
                                                {
                                                    this.props.client.token ? (
                                                        <Tab eventKey="purchaseHistory" title={i18n.t("Token Reload")}>
                                                            <MDBDataTable data={purchaseHistory} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={purchaseHistoryCsvData.data} headers={purchaseHistoryCsvData.headers} filename={`token_purchase_report(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                                    {i18n.t("Download Report")}
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                }
                                                {
                                                    this.props.client.token ? (
                                                        <Tab eventKey="transfer" title={i18n.t("Token Transfer")}>
                                                            <MDBDataTable data={transfer} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={transferCsvData.data} headers={transferCsvData.headers} filename={`token_transfer_report(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                                    {i18n.t("Download Report")}
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                }
                                                <Tab eventKey="giveaway" title={i18n.t("User Compensation")}>
                                                    <MDBDataTable data={giveaway} noBottomColumns striped hover responsive bordered />
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <CSVLink className="btn btn-primary" data={giveawayCsvData.data} headers={giveawayCsvData.headers} filename={`user_reward_report(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                            {i18n.t("Download Report")}
                                                        </CSVLink>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="voucher" title={i18n.t("Voucher History")}>
                                                    <MDBDataTable data={voucher} noBottomColumns striped hover responsive bordered />
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <CSVLink className="btn btn-primary" data={voucherCsvData.data} headers={voucherCsvData.headers} filename={`voucher_report(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                            {i18n.t("Download Report")}
                                                        </CSVLink>
                                                    </div>
                                                </Tab>
                                                {
                                                    this.props.client.token ? (
                                                        <Tab eventKey="totalToken" title={i18n.t("Token History")}>
                                                            <MDBDataTable data={totalToken} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={totalTokenCsvData.data} headers={totalTokenCsvData.headers} filename={`token_history(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                                    {i18n.t("Download Report")}
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                }
                                                {
                                                    this.props.client.stamp ? (
                                                        <Tab eventKey="stamp" title={i18n.t("Stamp History")}>
                                                            <MDBDataTable data={stamp} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={stampCsvData.data} headers={stampCsvData.headers} filename={`stamp_history(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                                    {i18n.t("Download Report")}
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                }
                                                {
                                                    this.props.client.loyalty_point ? (
                                                        <Tab eventKey="point" title={i18n.t("Point History")}>
                                                            <MDBDataTable data={point} noBottomColumns striped hover responsive bordered />
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                                <CSVLink className="btn btn-primary" data={pointCsvData.data} headers={pointCsvData.headers} filename={`point_history(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                                    {i18n.t("Download Report")}
                                                                </CSVLink>
                                                            </div>
                                                        </Tab>
                                                    ) : null
                                                }
                                                <Tab eventKey="dng" title={i18n.t("DNG History")}>
                                                    <MDBDataTable data={dng} noBottomColumns striped hover responsive bordered />
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <CSVLink className="btn btn-primary" data={dngCsvData.data} headers={dngCsvData.headers} filename={`dng_report(${this.props.location.state.memberId})_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                            {i18n.t("Download Report")}
                                                        </CSVLink>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={this.state.showModal}
                    onHide={() => this.setState({
                        ...this.state,
                        showModal: false,
                        transactionId: "",
                        remark: "",
                        refundAmount: ""
                    })}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{i18n.t("Refund")} ({this.state.refundAmount} {i18n.t("TOKEN")})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="form-group">
                                <label htmlFor="remark">{i18n.t("Remark")}</label>
                                <input type="text" id="remark" name="remark" value={this.state.remark} onChange={this.handleChange} required />
                            </div>

                            <div className="text-center">
                                <button type="button" className="btn btn-primary" onClick={e => this.refund()}>
                                    {i18n.t("Refund")}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(MemberDetailsReport);
