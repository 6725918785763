import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import FloatingPointReport from '../../../admin/components/FloatingPointReport';

const UserFloatingPointReport = ({ client, token }) => {
    const history = useHistory();

    const [toggled, setToggled] = useState(false)

    useEffect(() => {
        if (!client || !client.floating_point_report) history.push("/client");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={e => setToggled(!toggled)} />
            <div className="admin-page">
                <Topbar handleToggle={e => setToggled(!toggled)} />
                <div className="admin-content">
                    <FloatingPointReport
                        operatorId=""
                        operators={client?.admin}
                        distributors={client?.distributor}
                        token={token}
                        role="client"
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
	return {
		client: state.client.client,
		token: state.client.token
	};
};

export default connect(mapStateToProps, null)(UserFloatingPointReport);