import { CLIENT_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, CLIENT_TOKEN_UPDATE, SAVE_CLIENT_REDIRECT_URL, SAVE_CLIENT_USER_TYPE, CLIENT_POINT_UPDATE } from '../actions/type';

const initState = {
    isAuthenticated: false,
    isLoading: false,
    error: null,
    token: localStorage.getItem("laundry-client"),
    client: null,
    redirectUrl: localStorage.getItem("laundry-client-redirect-url"),
    userType: localStorage.getItem("laundry-client-user-type")
};

const clientReducer = (state = initState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false
            };
        case SHOW_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            };
        case CLIENT_LOGIN:
            localStorage.setItem("laundry-client", action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                client: action.payload.client
            };
        case SAVE_CLIENT_REDIRECT_URL:
            localStorage.setItem("laundry-client-redirect-url", action.payload);
            return {
                ...state,
                redirectUrl: action.payload
            };
        case SAVE_CLIENT_USER_TYPE:
            localStorage.setItem("laundry-client-user-type", action.payload);
            return {
                ...state,
                userType: action.payload
            };
        case REMOVE_TOKEN:
            localStorage.removeItem("laundry-client");
            localStorage.removeItem("laundry-client-redirect-url");
            localStorage.removeItem("laundry-client-user-type");
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                client: null,
                redirectUrl: null,
                userType: null
            };
        case CLIENT_TOKEN_UPDATE:
            return {
                ...state,
                client: {
                    ...state.client,
                    token: action.payload.token,
                    distributor: action.payload.distributor
                }
            };
        case CLIENT_POINT_UPDATE:
            return {
                ...state,
                client: {
                    ...state.client,
                    loyalty_point: action.payload.loyalty_point,
                    distributor: action.payload.distributor
                }
            };
        default:
            return state;
    }
};

export default clientReducer;