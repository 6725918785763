import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import { useTranslation } from "react-i18next";

const TaskDetails = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const [toggled, setToggled] = useState(false);
    const [taskList, setTaskList] = useState([]);

    useEffect(() => {
        if (!location.state) {
            history.push("/staff")
        } else {
            setTaskList(location.state.taskList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToggle = () => {
        setToggled(!toggled);
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="mb-3 d-flex" onClick={e => history.push('/staff/dashboard')}>
                        <i className="bi bi-chevron-left"></i>
                        <p className="ps-2">{t("Back")}</p>
                    </div>
                    {
                        taskList.length > 0 ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{t("Pack Number")} {taskList[0].pack_number}</h5>
                                </div>

                                <div className="card-body">
                                    <p>{t("Order Date")}: {taskList[0].date}</p>
                                    <p>{t("Outlet")}: {taskList[0].outlet_fullname} ({taskList[0].outlet_name})</p>
                                    <p>{t("Size")}: {taskList[0].size}</p>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        taskList.map((task, i) => {
                            return (
                                <div className="card" key={i}>
                                    <div className="card-header">
                                        <h5 className="card-title">{t(task.name)}</h5>
                                    </div>

                                    <div className="card-body">
                                        <p>{t("Task ID")}: {task.id}</p>
                                        {task.capacity ? <p>{t("Capacity")}: {task.capacity}</p> : null}
                                        {task.mode ? <p>{t("Mode")}: {t(task.mode)}</p> : null}
                                        {task.machine_duration ? <p>{t("Duration")}: {task.machine_duration} {t("min")}</p> : null}
                                        {task.machine ? <p>{t("Machine")}: {task.machine}</p> : null}
                                        {task.status ? <p style={{ color: task.status === "Completed" ? "green" : "#FF3C00" }}>{t("Status")}: {t(task.status)}</p> : null}

                                        {task.status !== "Completed" ? (
                                            <div className='text-center'>
                                                <button className="btn btn-primary" type="button" onClick={() => history.push('/staff/task/start', { task })}>{t("Start Task")}</button>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default TaskDetails; 
