import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const FaqDetails = ({ language }) => {
    const history = useHistory();
    const location = useLocation();

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [groupTitle, setGroupTitle] = useState(location.state ? t(location.state.title) : "")

    useEffect(() => {
        if (!location.state) {
            history.push('/')
        } else if (location.state.id) {
            setLoading(true);
            axios
                .post("/api/user/getFaqWithGroupId", { faqGroupId: location.state.id })
                .then(res => {
                    let faqGroupLang = res.data.data.faq_group_langs.filter(lang => lang.language === language)[0]
                    if (faqGroupLang) {
                        setGroupTitle(faqGroupLang.title)
                    } else {
                        setGroupTitle(res.data.data.title)
                    }
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5><i onClick={e => history.goBack()} className="bi bi-chevron-left me-3"></i> <strong>{groupTitle}</strong></h5>
                </div>

                <div id="user-body" style={{ textAlign: "justify" }}>
                    <Accordion>
                        {
                            location.state ?
                                location.state.title === "Cleanpro Plus" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("How can I wash my laundry using Cleanpro Plus?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to wash your laundry using Cleanpro Plus:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Find an unoccupied washing machine in the store, and insert your laundry in the washer.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("Select the desired temperature that you would like to use for your laundry.")}</p>
                                                <p className="pt-2"><b>{t("Step 3")}</b>: {t("Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 4")}</b>: {t("Tap on “Pay with credits” to pay the amount shown on the app.")}</p>
                                                <p className="pt-2"><b>{t("Step 5")}</b>: {t("Once the payment has been accepted, press the START button on the washer machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 6")}</b>: {t("After the washing cycle ends, you can just remove your laundry from the machine.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("How can I dry my laundry using Cleanpro Plus?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to dry your laundry using Cleanpro Plus:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Find an unoccupied drying machine in the store, and insert your laundry in the dryer.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("Select the desired temperature and duration that you would like to use for your laundry.")}</p>
                                                <p className="pt-2"><b>{t("Step 3")}</b>: {t("Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 4")}</b>: {t('Tap on “Pay with credits” to pay the amount shown on the app.')}</p>
                                                <p className="pt-2"><b>{t("Step 5")}</b>: {t("Once the payment has been accepted, press the START button on the dryer machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 6")}</b>: {t("After the drying cycle ends, you can just remove your laundry from the machine.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("Why does the QR code I scanned take me to a different machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>{t("How can I add more time to the washer that I am currently using?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("The washing time for our washer is fixed and cannot add any additional time.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><b>{t("How can I add more time to the dryer that I am currently using?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to add more time to the dryer machine:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("By using the scanner in the app, scan the QR code provided at the dryer machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("Select the amount of time that you would like to add to the machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 3")}</b>: {t('Tap on “Pay with credits” to pay the amount shown on the app.')}</p>
                                                <p className="pt-2"><b>{t("Step 4")}</b>: {t("Once the payment has been accepted, press the START button on the dryer machine.")}</p>
                                                <p className="pt-2"><b>{t("Step 5")}</b>: {t("After the drying cycle ends, you can just remove the clothes from the machine.")}</p>
                                                <p className="pt-2">{t("REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header><b>{t("Will the timer keep running when I have stopped the drying machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header><b>{t("How do I reload my credits for Cleanpro Plus?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to reload your credits:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t('Select the "Top Up Credit" button on the homepage.')}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("Choose the amount you want to reload and the payment method you want to use.")}</p>
                                                <p className="pt-2"><b>{t("Step 3")}</b>: {t("You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header><b>{t("How many credits can I reload in one transaction?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Reloading credits requires a minimum of RM 10 and a maximum of RM 50.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header><b>{t("Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("No. Only a single payment method may be chosen when performing any transactions.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header><b>{t("The amount I have reloaded in my account was not reflected in the app.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Please close and restart the application and check if the new balance has been updated.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="10">
                                            <Accordion.Header><b>{t("Can I cash out the credits I reloaded in my account for this app?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("No. The credits that you have reloaded will remain in your Cleanpro Plus account.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="11">
                                            <Accordion.Header><b>{t("I have requested a refund, but I still have not received it.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="12">
                                            <Accordion.Header><b>{t("I have made the payment, and the app double or triple charged me.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="13">
                                            <Accordion.Header><b>{t("The washing machine cannot start even after I have paid using the app.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="14">
                                            <Accordion.Header><b>{t("Can I use multiple vouchers together in the same washing or drying cycle?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("No. Only a single voucher can be used for each washing and drying cycle.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="15">
                                            <Accordion.Header><b>{t("Why can't I use my free wash voucher that I received after signing up?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("To redeem or use the voucher, you must complete at least one washing or drying cycle.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="16">
                                            <Accordion.Header><b>{t("Can I change my phone number even after registering my account?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("No. Once registered to an account, the phone number is locked into the account.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "General questions" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("I have problems in the store, and who can I refer to for help?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("Should I add my own laundry detergent and softeners to the washing machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("I would like to add my own laundry detergent and softener to the washing machine.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>{t("What time does the store close?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><b>{t("Are you open on weekends?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Yes. Cleanpro is also open on weekends and even on public holidays.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header><b>{t("How many clothes can I fit in the washing or drying machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "E-stamps collection" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("How many e-stamps can I collect in a single day?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("No, the type of stamp collected is only applied to the service that you have used.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("What temperatures can I choose when I claim the free washing e-stamp reward?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Any washing temperature is available for the e-stamp reward.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>{t("What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Any drying temperature and duration can be selected for the e-stamp reward.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "Coin exchange machines" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("I'm unable to change the coin.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                                <p className="pt-2"><b>{t("Green Light")}</b> - {t("Machine functions as normal. Please try again.")}</p>
                                                <p className="pt-2"><b>{t("Red Light")}</b> {t("(flashing 2x) - There are no coins/tokens in the machine.")} <b>{t("OR")}</b> {t("Note is jammed, please proceed to contact customer service")}</p>
                                                <p className="pt-2"><b>{t("Red Light")}</b> {t("(flashing 3x) - Note is jammed, please proceed to contact customer service")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("My notes are stuck in the machine.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("My detergent is not dispensing in the vending machine.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "Washer machines" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("The water is leaking out of the washing machine.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please check the washer machine to see whether there are any clothes that are stuck at the door side.")}</p>
                                                <p className="pt-2">{t("If yes, here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Press the emergency stop button (RED Button) for 3 seconds.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)")}</p>
                                                <p className="pt-2"><b>{t("Step 3")}</b>: {t("Ensure the door is closed tightly, and press the START button.")}</p>
                                                <p className="pt-2">{t("REMINDER - The emergency stop button can only be used once.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("There is no detergent dispensing in the washing machine.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Ensure that the machine is in operation and that the START button was pressed.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("The price displayed on the machine's screen did not change after inserting the token.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>{t("The machine is not starting, and it displays an error code.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><b>{t("I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Try opening and closing the door again before starting the machine.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header><b>{t("Can I change the water temperature of the machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header><b>{t("Can I stop the machine?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to stop the machine:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Press the emergency stop button (RED Button) for 3 seconds.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)")}</p>
                                                <p className="pt-2"><b>{t("Step 3")}</b>: {t("Ensure the door is closed tightly, and press the START button.")}</p>
                                                <p className="pt-2">{t("REMINDER - The emergency stop button can only be used once.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header><b>{t("There is no water supply on the machine used.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="8">
                                            <Accordion.Header><b>{t("Why is the washing temperature cold even when I have selected HOT.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="9">
                                            <Accordion.Header><b>{t("The machine has already finished washing, but it does not spin well, and there is water left on the load.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "Dryer machines" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("Can I put wet laundry into the dryer to dry?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("No, as the dripping wet laundry might cause the dryer to short circuit.")}</p>
                                                <p className="pt-2">{t("REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("My clothes are still wet even after drying.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                                <p className="pt-2">{t("REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("How can I stop the dryer when the machine is still running?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Just open the door and the machine will automatically stop.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>{t("I added extra tokens for extra times, but the machine did not update the time.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.")}</p>
                                                <p className="pt-2">{t("REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><b>{t("Why is the machine making noise?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please check the drum to see if there are any coins or other small things inside.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header><b>{t("I have pressed the START button on the dryer, but it is still not working.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Here are the steps to solve it:")}</p>
                                                <p className="pt-2"><b>{t("Step 1")}</b>: {t("Please try to open the door and close it again. Next, press the START button again to start drying.")}</p>
                                                <p className="pt-2"><b>{t("Step 2")}</b>: {t("If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header><b>{t("The price displayed on the machine's screen did not change after inserting the token.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "Paywave issues" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("Paywave has deducted an amount from the card but the machine did not reflect the transaction.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header><b>{t("The Paywave transaction has failed but the amount has still been deducted from my card.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header><b>{t("Paywave has double/triple charge but the machine cannot start.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header><b>{t("Paywave after double/triple charge machines can't start.")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header><b>{t("Paywave is offline")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("Please contact our customer service line at 03-27700100 to receive further assistance.")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : location.state.title === "Cleanpro franchise" ? (
                                    <>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><b>{t("Do Cleanpro Express offer franchise business/selling machines/laundry products?")}</b></Accordion.Header>
                                            <Accordion.Body>
                                                <p>{t("For further inquiries, please email to info@cleanproexpress.com")}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>
                                ) : loading ? (
                                    <div className="text-center">
                                        <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"} role="status">
                                            <span className="sr-only">Loading ...</span>
                                        </div>
                                    </div>
                                ) : data ? (
                                    <>
                                        {
                                            data.faqs.map((faq, i) => {
                                                let faqLang = faq.faq_langs.filter(lang => lang.language === language)[0]
                                                return (
                                                    <Accordion.Item eventKey={faq.id} key={i}>
                                                        <Accordion.Header><b>{faqLang ? faqLang.question : faq.question}</b></Accordion.Header>
                                                        <Accordion.Body>
                                                            {
                                                                faq.image ? (
                                                                    <div className="text-center">
                                                                        <img src={faq.image} alt="" className="img-fluid" />
                                                                    </div>
                                                                ) : null
                                                            }
                                                            <div dangerouslySetInnerHTML={{ __html: faqLang ? faqLang.answer : faq.answer }}></div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            })
                                        }
                                    </>
                                ) : null
                                : null
                        }
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        language: state.user.language
    };
};

export default connect(mapStateToProps, null)(FaqDetails);