import React, { Component } from "react";
import { resendOtp } from "../../../../redux/actions/userActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class OtpButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timer: 60
		};
	}

	_timer;

	componentDidMount = () => {
		resendOtp({ number: this.props.number, email: this.props.email, operatorId: this.props.operatorId, verifyMethod: this.props.verifyMethod });
		this.startTimer();
	};
	componentWillUnmount = () => {
		clearInterval(this._timer);
		this._timer = null;
	};

	handleResend = e => {
		const numberRegex = /^\d+$/;
		const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

		if (this.props.number && !numberRegex.test(this.props.number)) {
			mySwal.fire(i18n.t("Error"), i18n.t("Invalid Phone Number"), "error");
		} else if (this.props.email && !emailRegex.test(this.props.email)) {
			mySwal.fire(i18n.t("Error"), i18n.t("Invalid Email Address"), "error");
		} else if (this.props.verifyMethod === "Phone Number" && this.props.country === "Taiwan" && (!numberRegex.test(this.props.number) || this.props.number.length !== 10 || this.props.number.substr(0, 2) !== "09")) {
			mySwal.fire(i18n.t("Error"), i18n.t("Invalid Phone Number"), "error");
		} else if (this.props.verifyMethod === "Phone Number" && this.props.country === "Malaysia" && (!numberRegex.test(this.props.number) || this.props.number.length <= 9 || this.props.number.length >= 12 || this.props.number.substr(0, 2) !== "01")) {
			mySwal.fire({
				title: i18n.t("Error"),
				icon: "error",
				html: i18n.t(`Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>`)
			});
		} else {
			resendOtp({ number: this.props.number, email: this.props.email, operatorId: this.props.operatorId, verifyMethod: this.props.verifyMethod });
			this.setState({
				...this.state,
				timer: 60
			});
			this.startTimer();
		}
	};

	startTimer = () =>
	(this._timer = setInterval(() => {
		this.setState({
			...this.state,
			timer: this.state.timer - 1
		});

		if (this.state.timer === 0) {
			clearInterval(this._timer);
			this._timer = undefined;
		}
	}, 1000));

	render() {
		return (
			<div className="text-center mb-3">
				{/* <p className="m-0 p-0 text-left" style={{color : "white"}}>We have sent a verification code to your number</p> */}
				<p className="m-0 p-0" id="didnt-receive-code" style={{ color: "white", fontSize: "11px" }}>
					{i18n.t("Didn't receive a code?")}
				</p>
				<button id="resend-code" type="button" onClick={this.handleResend} className={`btn btn-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"} font-weight-bold m-0 btn-block`} disabled={this.state.timer}>
					{i18n.t("Resend Code")} {this.state.timer ? `(${this.state.timer})` : null}
				</button>
			</div>
		);
	}
}

export default OtpButton;
