import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../../dynamicController";
import { DatePicker, Row, Col, Button } from "antd";
// import moment from "moment";
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);

class UserFloatingTokenReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			userTokenData: null,
			summaryTokenData: null,
			loading: true,
			startDate: "",
			endDate: ""
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated || !this.props.admin.admin.floating_token_report) {
				return this.props.history.push("/admin/dashboard");
			} else {
				const { operatorId } = this.props.admin.admin;
				axios
					.post("/api/admin/report/userFloatingTokenReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							userTokenData: res.data.data
						});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
					});

				axios
					.post("/api/admin/report/floatingTokenReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
					.then(res => {
						this.setState({
							...this.state,
							loading: false,
							summaryTokenData: res.data.data
						});
					})
					.catch(err => {
						this.setState({
							...this.state,
							loading: false
						});
						mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleDate = dates => {
		if (dates) {
			const [start, end] = dates;
			this.setState({
				startDate: start,
				endDate: end
			});
		} else {
			this.setState({
				startDate: null,
				endDate: null
			});
		}
	};

	handleFilter = e => {
		const { startDate, endDate } = this.state;
		let sendThis = {
			operatorId: this.props.admin.admin.operatorId
		};

		if (startDate && endDate) {
			sendThis.startDate = new Date(startDate);
			sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
		}
		this.setState({
			...this.state,
			loading: true
		});
		axios
			.post("/api/admin/report/userfloatingTokenReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					userTokenData: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
		axios
			.post("/api/admin/report/floatingTokenReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					summaryTokenData: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	handleReset = e => {
		this.setState({
			...this.state,
			startDate: "",
			endDate: "",
			loading: true
		});
		const operatorId = this.props.admin.admin.operatorId;
		axios
			.post("/api/admin/report/userfloatingTokenReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					userTokenData: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
		axios
			.post("/api/admin/report/floatingTokenReport", { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
			.then(res => {
				this.setState({
					...this.state,
					loading: false,
					summaryTokenData: res.data.data
				});
			})
			.catch(err => {
				this.setState({
					...this.state,
					loading: false
				});
				mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
			});
	};

	render() {
		let userTokenDataTable = {
			columns: [
				{
					label: i18n.t("User Name"),
					field: "name",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "member_id",
					sort: "asc"
				},
				{
					label: i18n.t("Phone Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email Address"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Balance"),
					field: "balance",
					sort: "asc"
				},
				{
					label: i18n.t("Reload & Transfer In"),
					field: "reload",
					sort: "asc"
				},
				{
					label: i18n.t("Usage & Transfer Out"),
					field: "usage",
					sort: "asc"
				},
				{
					label: i18n.t("Remaining"),
					field: "remaining",
					sort: "asc"
				},
			],
			rows: this.state.userTokenData ?? []
		};

		let userTokenCsv = [];

		if (this.state.userTokenData) {
			for (let i = 0; i < this.state.userTokenData.length; i++) {
				let data = {
					balance: this.state.userTokenData[i].balance,
					reload: this.state.userTokenData[i].reload,
					usage: this.state.userTokenData[i].usage,
					remaining: this.state.userTokenData[i].remaining,
					member_id: this.state.userTokenData[i].member_id,
					name: this.state.userTokenData[i].name,
					number: `=""${this.state.userTokenData[i].number}""`,
					email: this.state.userTokenData[i].email,
				}
				userTokenCsv.push(data);
			}
		}

		const userTokenCsvData = {
			headers: [
				{ label: "User Name", key: "name" },
				{ label: "Member ID", key: "member_id" },
				{ label: "Phone Number", key: "number" },
				{ label: "Email Address", key: "email" },
				{ label: "Balance", key: "balance" },
				{ label: "Reload & Transfer In", key: "reload" },
				{ label: "Usage & Transfer Out", key: "usage" },
				{ label: "Remaining", key: "remaining" },
			],
			data: userTokenCsv
		};

		let summaryTokenDataTable = {
			columns: [
				{
					label: i18n.t("Balance"),
					field: "balance",
					sort: "asc"
				},
				{
					label: i18n.t("Reload & Transfer In"),
					field: "reload",
					sort: "asc"
				},
				{
					label: i18n.t("Usage & Transfer Out"),
					field: "usage",
					sort: "asc"
				},
				{
					label: i18n.t("Remaining"),
					field: "remaining",
					sort: "asc"
				},
			],
			rows: this.state.summaryTokenData ?? []
		};

		let summaryTokenCsv = [];

		if (this.state.summaryTokenData) {
			for (let i = 0; i < this.state.summaryTokenData.length; i++) {
				let data = {
					balance: this.state.summaryTokenData[i].balance,
					reload: this.state.summaryTokenData[i].reload,
					usage: this.state.summaryTokenData[i].usage,
					remaining: this.state.summaryTokenData[i].remaining,
				}
				summaryTokenCsv.push(data);
			}
		}

		const summaryTokenCsvData = {
			headers: [
				{ label: "Balance", key: "balance" },
				{ label: "Reload & Transfer In", key: "reload" },
				{ label: "Usage & Transfer Out", key: "usage" },
				{ label: "Remaining", key: "remaining" },
			],
			data: summaryTokenCsv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">{i18n.t("Floating Token Report")}</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>{i18n.t("Filter")}</h6>
									<Row gutter={16} className="mt-3">
										<Col span={24}>
											<label htmlFor="date" >{i18n.t("Select Date")}: </label>
											<DatePicker.RangePicker
												value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
												onChange={this.handleDate}
												style={{ width: '100%' }}
												ranges={{
													'Today': [dayjs(), dayjs()],
													'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
													'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
													'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
													'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
													'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
												}}
											/>
										</Col>
									</Row>
									<Row gutter={16} className="mt-3">
										<Col span={12}>
											<Button type="primary" onClick={this.handleFilter} className="w-100">
												{i18n.t("Filter")}
											</Button>
										</Col>
										<Col span={12}>
											<Button onClick={this.handleReset} className="w-100">
												{i18n.t("Reset")}
											</Button>
										</Col>
									</Row>

								</div>
								<div className="text-center mt-5">
									{
										this.state.loading ? <Loading /> : (
											<div>
												<MDBDataTable data={summaryTokenDataTable} paging={false} searching={false} noBottomColumns striped hover responsive bordered />
												<div className="d-grid gap-2 d-md-flex justify-content-md-end">
													<CSVLink className="btn btn-primary" data={summaryTokenCsvData.data} headers={summaryTokenCsvData.headers} filename={`floating_token_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
														{i18n.t("Download Report")}
													</CSVLink>
												</div>
											</div>
										)
									}
								</div>
								<div className="text-center mt-5">
									{
										this.state.loading ? <Loading /> : (
											<div>
												<MDBDataTable data={userTokenDataTable} noBottomColumns striped hover responsive bordered />
												<div className="d-grid gap-2 d-md-flex justify-content-md-end">
													<CSVLink className="btn btn-primary" data={userTokenCsvData.data} headers={userTokenCsvData.headers} filename={`user_floating_token_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
														{i18n.t("Download Report")}
													</CSVLink>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(UserFloatingTokenReport);
