import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currency, dateFormat, timeFormat } from "../../../dynamicController";
import i18n from "i18next";
import { Select, DatePicker, Button, Row, Col } from 'antd';
// import moment from 'moment';
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);
const { Option } = Select;

class TokenReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: false,
            tableData: null,
            topChoice: null,
            totalAmount: 0,
            totalTokens: 0,
            outletId: [],
            startDate: "",
            endDate: "",
            operatorId: "",
            outlets: [],
        };
    }

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.token_report) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e =>
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });

    handleChangeOperator = value => {
        const selectedOutlet = this.props.distributor.outlet.filter(outlet => outlet.adminId === value);

        this.setState({
            ...this.state,
            outlets: selectedOutlet,
            operatorId: value,
            outletId: []
        });
    };

    handleChangeOutlet = value => {
        this.setState({
            ...this.state,
            outletId: value
        });
    };

    handleDate = (dates, dateStrings) => {
        this.setState({
            ...this.state,
            startDate: dateStrings[0],
            endDate: dateStrings[1]
        });
    };

    handleFilter = e => {
        const { operatorId, outletId, startDate, endDate } = this.state;

        if (!operatorId) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select operator"), "error");
        } else {
            let sendThis = {
                operatorId
            };
            if (outletId && outletId.length > 0) {
                sendThis.outletIds = outletId;
            }
            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }
            this.setState({
                ...this.state,
                loading: true
            });
            axios
                .post("/api/admin/report/token", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        tableData: res.data.tableData,
                        topChoice: res.data.topChoice,
                        totalAmount: res.data.totalAmount,
                        totalTokens: res.data.totalTokens
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            outletId: [],
            startDate: "",
            endDate: "",
            operatorId: "",
            tableData: null,
            topChoice: null,
            totalAmount: 0,
            totalTokens: 0,
            outlets: [],
        });
    };

    checkDetails = outletName => {
        const { startDate, endDate } = this.state;
        this.props.history.push("/distributor/token/report/detail", { outletName, startDate, endDate });
    };

    render() {
        let data = {
            columns: [
                {
                    label: i18n.t("Outlet Name"),
                    field: "outletName",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-token used in all the transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total e-Token Collected")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tokenCollected",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-token used in washer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Washer e-Token Collected")}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerTokens",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-token used in dryer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Dryer e-Token Collected")}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerTokens",
                    sort: "asc"
                }
            ],
            rows: []
        };

        if (this.state.tableData) {
            for (let i = 0; i < this.state.tableData.length; i++) {
                const dataObject = {
                    outletName: (
                        <button type="button" className="btn btn-link" onClick={e => this.checkDetails(this.state.tableData[i].outletName)}>
                            {this.state.tableData[i].outletName}
                        </button>
                    ),
                    tokenCollected: this.state.tableData[i].tokenCollected,
                    washerTokens: this.state.tableData[i].washerTokens,
                    dryerTokens: this.state.tableData[i].dryerTokens
                };
                data.rows.push(dataObject);
            }
        }

        const csvData = {
            headers: [
                { label: "Outlet Name", key: "outletName" },
                { label: "Total e-Token Collected", key: "tokenCollected" },
                { label: "Washer e-Token Collected", key: "washerTokens" },
                { label: "Dryer e-Token Collected", key: "dryerTokens" }
            ],
            data: this.state.tableData ?? []
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">{i18n.t("Token Report")}</h5>

                                <Link className="btn btn-primary" to="/distributor/token/report/purchasehistory">
                                    {i18n.t("Purchase History")}
                                </Link>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label htmlFor="operatorId">{i18n.t("Select Operator")}: </label>
                                            <Select
                                                id="operatorId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Operator")}
                                                value={this.state.operatorId}
                                                onChange={this.handleChangeOperator}
                                            >
                                                <Option disabled value="">{i18n.t("Select Operator")}</Option>
                                                {this.props.distributor.admin.map(admin => (
                                                    <Option key={admin.id} value={admin.id}>
                                                        {admin.username}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="outletId">{i18n.t("Select Outlet")}: </label>
                                            <Select
                                                mode="multiple"
                                                id="outletId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Outlet")}
                                                value={this.state.outletId}
                                                onChange={this.handleChangeOutlet}
                                                disabled={!this.state.operatorId}
                                                showSearch
                                                optionFilterProp="label"
                                            >
                                                <Option disabled value="">{i18n.t("Select Outlet")}</Option>
                                                {this.state.outlets.map(outlet => (
                                                    <Option
                                                        key={outlet.id}
                                                        value={outlet.id}
                                                        label={`${outlet.outlet_fullname} (${outlet.outlet_name})`}
                                                    >
                                                        {outlet.outlet_fullname} ({outlet.outlet_name})
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="date">{i18n.t("Select Transaction Date")}: </label>
                                            <DatePicker.RangePicker
                                                value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
                                                onChange={this.handleDate}
                                                style={{ width: '100%' }}
                                                ranges={{
                                                    'Today': [dayjs(), dayjs()],
                                                    'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
                                                    'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
                                                    'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
                                                    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={12}>
                                            <Button type="primary" onClick={this.handleFilter} className="w-100">
                                                {i18n.t("Filter")}
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button onClick={this.handleReset} className="w-100">
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                {this.state.loading ? (
                                    <Loading />
                                ) : (
                                    <div>
                                        <div>
                                            <p>
                                                {i18n.t("Most Bought e-Token Package")} :{" "}
                                                <strong>
                                                    [ {currency(this.props.distributor.country)}{this.state.topChoice ? this.state.topChoice.price.toFixed(2) : 0} {i18n.t("for")} {this.state.topChoice ? this.state.topChoice.token : 0} {i18n.t("Tokens")} ]
                                                </strong>
                                            </p>
                                            <p>
                                                {i18n.t("Total Earned from users buying e-Tokens")}: <strong>{currency(this.props.distributor.country)}{this.state.totalAmount.toFixed(2)}</strong>
                                            </p>
                                            <p>
                                                {i18n.t("Total e-Tokens Bought from you")}: <strong>{this.state.totalTokens} {i18n.t("Tokens")}</strong>
                                            </p>
                                        </div>
                                        <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`token_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {i18n.t("Download Report")}
                                            </CSVLink>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(TokenReport);
