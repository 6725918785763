import React, { Component } from "react";
import { connect } from "react-redux";
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { currency, dateFormat, timeFormat } from "../../../dynamicController";
import i18n from "i18next";
// import moment from "moment";
import { Button, DatePicker, Row, Col } from "antd";
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);

class UserPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: [],
            loading: true,
            startDate: "",
            endDate: "",
            operatorId: ""
        };
    }

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.user_performance) {
            return this.props.history.push('/distributor');
        } else {
            let sendThis = {
                operatorId: this.props.distributor.admin.map(admin => admin.id)
            }

            axios
                .post("/api/admin/report/user", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data.data
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleDate = dates => {
        if (dates) {
            const [start, end] = dates;
            this.setState({
                startDate: start,
                endDate: end
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    };

    handleFilter = e => {
        const { /*operatorId,*/ startDate, endDate } = this.state;
        // if (!operatorId) {
        //     mySwal.fire("Error", "Please select the operator", "error");
        // } else {
        let sendThis = {
            operatorId: this.props.distributor.admin.map(admin => admin.id)
        }

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate);
            sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
        }
        this.setState({
            ...this.state,
            loading: true
        });
        axios
            .post("/api/admin/report/user", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
        // }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            startDate: "",
            endDate: "",
            operatorId: "",
            loading: true,
            data: []
        });

        let sendThis = {
            operatorId: this.props.distributor.admin.map(admin => admin.id)
        }

        axios
            .post("/api/admin/report/user", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
    };

    render() {
        let data = {
            columns: [
                {
                    label: i18n.t("Operator"),
                    field: "operatorName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Name"),
                    field: "userName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Number"),
                    field: "number",
                    sort: "asc"
                },
                {
                    label: i18n.t("Email"),
                    field: "email",
                    sort: "asc"
                },
                {
                    label: i18n.t("Join Date"),
                    field: "joinedDate",
                    sort: "asc"
                },
                {
                    label: i18n.t("Member ID"),
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Store"),
                    field: "outlet",
                    sort: "asc"
                },
                {
                    label: i18n.t("Channel"),
                    field: "channel",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    <ul>
                                        <li><b>{i18n.t("Registered")}</b>: {i18n.t("User registered but no transaction record")}</li>
                                        <li><b>{i18n.t("Active")}</b>: {i18n.t("User spending is less than")} {currency(this.props.distributor.country)}50</li>
                                        <li><b>{i18n.t("Tier 1")}</b>: {i18n.t("User spending is from")} {currency(this.props.distributor.country)}50 {i18n.t("until")} {currency(this.props.distributor.country)}199</li>
                                        <li><b>{i18n.t("Tier 2")}</b>: {i18n.t("User spending is")} {currency(this.props.distributor.country)}200 {i18n.t("and above")}</li>
                                    </ul>
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Tier")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tier",
                    sort: "asc"
                },
                {
                    label: i18n.t("Referrer"),
                    field: "referrer",
                    sort: "asc"
                },
                {
                    label: i18n.t("Referrals"),
                    field: "referrals",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of transactions for last 7 days")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Number of Usage (in week)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "weekUsage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of transactions for last 1 month")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Number of Usage (in month)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "monthUsage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total amount spent in all the transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Spent")} ({currency(this.props.distributor.country)})</span>
                        </OverlayTrigger>
                    ),
                    field: "totalSpent",
                    sort: "asc"
                },
                {
                    label: i18n.t("Last Transaction Date"),
                    field: "lastTransaction",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of vouchers collected")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Voucher Collected")}</span>
                        </OverlayTrigger>
                    ),
                    field: "voucherAcquired",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of vouchers used")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Voucher Spent")}</span>
                        </OverlayTrigger>
                    ),
                    field: "voucherUsed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of washer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Washer Used")}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerUsed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of dryer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Dryer Used")}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerUsed",
                    sort: "asc"
                }
            ],
            rows: this.state.data
        };

        let csv = [];

        if (this.state.data.length > 0) {
            for (let i = 0; i < this.state.data.length; i++) {
                let data = {
                    operatorName: this.state.data[i].operatorName,
                    userName: this.state.data[i].userName,
                    number: `=""${this.state.data[i].number}""`,
                    email: this.state.data[i].email,
                    joinedDate: this.state.data[i].joinedDate,
                    memberId: this.state.data[i].memberId,
                    outlet: this.state.data[i].outlet,
                    channel: this.state.data[i].channel,
                    tier: this.state.data[i].tier,
                    referrer: this.state.data[i].referrer,
                    referrals: this.state.data[i].referrals,
                    weekUsage: this.state.data[i].weekUsage,
                    monthUsage: this.state.data[i].monthUsage,
                    totalStamps: this.state.data[i].totalStamps,
                    tokenSpent: this.state.data[i].tokenSpent,
                    totalSpent: this.state.data[i].totalSpent,
                    currentToken: this.state.data[i].currentToken,
                    lastTransaction: this.state.data[i].lastTransaction,
                    voucherAcquired: this.state.data[i].voucherAcquired,
                    voucherUsed: this.state.data[i].voucherUsed,
                    washerUsed: this.state.data[i].washerUsed,
                    dryerUsed: this.state.data[i].dryerUsed
                }
                csv.push(data);
            }
        }

        const csvData = {
            headers: [
                { label: "Operator", key: "operatorName" },
                { label: "Name", key: "userName" },
                { label: "Number", key: "number" },
                { label: "Email", key: "email" },
                { label: "Join Date", key: "joinedDate" },
                { label: "Member ID", key: "memberId" },
                { label: "Store", key: "outlet" },
                { label: "Channel", key: "channel" },
                { label: "Tier", key: "tier" },
                { label: "Referrer", key: "referrer" },
                { label: "Referrals", key: "referrals" },
                { label: "Number of Usage (in week)", key: "weekUsage" },
                { label: "Number of Usage (in month)", key: "monthUsage" },
                { label: `Total Spent (${currency(this.props.distributor.country)})`, key: "totalSpent" },
                { label: "Last Transaction Date", key: "lastTransaction" },
                { label: "Voucher Collected", key: "voucherAcquired" },
                { label: "Voucher Spent", key: "voucherUsed" },
                { label: "Washer Used", key: "washerUsed" },
                { label: "Dryer Used", key: "dryerUsed" },
            ],
            data: csv
        };

        if (this.props.distributor.token) {
            data.columns = [
                {
                    label: i18n.t("Operator"),
                    field: "operatorName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Name"),
                    field: "userName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Number"),
                    field: "number",
                    sort: "asc"
                },
                {
                    label: i18n.t("Email"),
                    field: "email",
                    sort: "asc"
                },
                {
                    label: i18n.t("Join Date"),
                    field: "joinedDate",
                    sort: "asc"
                },
                {
                    label: i18n.t("Member ID"),
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Store"),
                    field: "outlet",
                    sort: "asc"
                },
                {
                    label: i18n.t("Channel"),
                    field: "channel",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    <ul>
                                        <li><b>{i18n.t("Registered")}</b>: {i18n.t("User registered but no transaction record")}</li>
                                        <li><b>{i18n.t("Active")}</b>: {i18n.t("User spending is less than")} {currency(this.props.distributor.country)}50</li>
                                        <li><b>{i18n.t("Tier 1")}</b>: {i18n.t("User spending is from")} {currency(this.props.distributor.country)}50 {i18n.t("until")} {currency(this.props.distributor.country)}199</li>
                                        <li><b>{i18n.t("Tier 2")}</b>: {i18n.t("User spending is")} {currency(this.props.distributor.country)}200 {i18n.t("and above")}</li>
                                    </ul>
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Tier")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tier",
                    sort: "asc"
                },
                {
                    label: i18n.t("Referrer"),
                    field: "referrer",
                    sort: "asc"
                },
                {
                    label: i18n.t("Referrals"),
                    field: "referrals",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of transactions for last 7 days")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Number of Usage (in week)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "weekUsage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of transactions for last 1 month")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Number of Usage (in month)")}</span>
                        </OverlayTrigger>
                    ),
                    field: "monthUsage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total amount spent in all the transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Spent")} ({currency(this.props.distributor.country)})</span>
                        </OverlayTrigger>
                    ),
                    field: "totalSpent",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of e-Token spent in all the transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("e-Token Spent")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tokenSpent",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Current e-Token")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("e-Tokens")}</span>
                        </OverlayTrigger>
                    ),
                    field: "currentToken",
                    sort: "asc"
                },
                {
                    label: i18n.t("Last Transaction Date"),
                    field: "lastTransaction",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of vouchers collected")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Voucher Collected")}</span>
                        </OverlayTrigger>
                    ),
                    field: "voucherAcquired",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of vouchers used")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Voucher Spent")}</span>
                        </OverlayTrigger>
                    ),
                    field: "voucherUsed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of washer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Washer Used")}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerUsed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of dryer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Dryer Used")}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerUsed",
                    sort: "asc"
                }
            ];

            csvData.headers = [
                { label: "Operator", key: "operatorName" },
                { label: "Name", key: "userName" },
                { label: "Number", key: "number" },
                { label: "Email", key: "email" },
                { label: "Join Date", key: "joinedDate" },
                { label: "Member ID", key: "memberId" },
                { label: "Store", key: "outlet" },
                { label: "Channel", key: "channel" },
                { label: "Tier", key: "tier" },
                { label: "Referrer", key: "referrer" },
                { label: "Referrals", key: "referrals" },
                { label: "Number of Usage (in week)", key: "weekUsage" },
                { label: "Number of Usage (in month)", key: "monthUsage" },
                { label: `Total Spent (${currency(this.props.distributor.country)})`, key: "totalSpent" },
                { label: "e-Token Spent", key: "tokenSpent" },
                { label: "e-Tokens", key: "currentToken" },
                { label: "Last Transaction Date", key: "lastTransaction" },
                { label: "Voucher Collected", key: "voucherAcquired" },
                { label: "Voucher Spent", key: "voucherUsed" },
                { label: "Washer Used", key: "washerUsed" },
                { label: "Dryer Used", key: "dryerUsed" },
            ]
        }

        if (this.props.distributor.stamp) {
            data.columns.push({
                label: (
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {i18n.t("Total number of stamps collected")}
                            </Tooltip>
                        }
                    >
                        <span>{i18n.t("Total Stamps")}</span>
                    </OverlayTrigger>
                ),
                field: "totalStamps",
                sort: "asc"
            })

            csvData.headers.push({ label: "Total Stamps", key: "totalStamps" })
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("User Performance")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label htmlFor="date" >{i18n.t("Select Join Date")}: </label>
                                            <DatePicker.RangePicker
                                                value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
                                                onChange={this.handleDate}
                                                style={{ width: '100%' }}
                                                ranges={{
                                                    'Today': [dayjs(), dayjs()],
                                                    'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                                    'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                                    'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                                    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16} className="mt-3">
                                        <Col span={12}>
                                            <Button type="primary" onClick={this.handleFilter} className="w-100">
                                                {i18n.t("Filter")}
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button onClick={this.handleReset} className="w-100">
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center mt-5">{this.state.loading ? <Loading /> : (
                                    <div>
                                        <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                            <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_performance_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                {i18n.t("Download Report")}
                                            </CSVLink>
                                        </div>
                                    </div>
                                )}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(UserPerformance);
