import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            toggled: false,
            adminList: []
        };
    };

    componentDidMount() {
        axios
            .post("/api/distributor/getAdmin", { distributorId: this.props.distributor.distributorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    adminList: res.data.data,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    render() {

        let tableData = {
            columns: [
                {
                    label: i18n.t("Username"),
                    field: "username",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Users Registered"),
                    field: "users",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Active Outlets"),
                    field: "outlets",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Vouchers Given"),
                    field: "vouchers",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Transaction Counts"),
                    field: "transactions",
                    sort: "asc"
                }
            ],
            rows: this.state.adminList
        };

        if (this.props.distributor.stamp) {
            tableData.columns.push({
                label: i18n.t("Total User Stamps Collected"),
                field: "stamps",
                sort: "asc"
            })
        }

        return (
            <div id="admin-dashboard" className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="text-center">
                            <h3>{i18n.t("Welcome")}</h3>
                        </div>

                        <div className="mt-5">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between">
                                    <h5 className="card-title">{i18n.t("Dashboard")}</h5>
                                </div>

                                <div className="card-body text-center">
                                    {
                                        this.state.loading ? <Loading /> : <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(Dashboard);