import {
    CLIENT_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN,
    CLIENT_TOKEN_UPDATE, SAVE_CLIENT_REDIRECT_URL, SAVE_CLIENT_USER_TYPE,
    CLIENT_POINT_UPDATE
} from './type';
import axios from 'axios';

export const clientLogin = data => dispatch => {
    dispatch({ type: IS_LOADING });
    axios.post('/api/client/auth/login', data)
        .then(res => {
            dispatch({ type: CLIENT_LOGIN, payload: res.data.data });
            dispatch({ type: CLEAR_ERROR });
            dispatch({ type: IS_LOADED });
        })
        .catch(err => {
            dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
            dispatch({ type: IS_LOADED });
        });
};

export const checkToken = () => (dispatch, getState) => {
    if (getState().client.token) {
        axios.post('/api/client/auth/checkToken', { token: getState().client.token })
            .then(res => dispatch({ type: CLIENT_LOGIN, payload: res.data.data }))
            .catch(err => {
                console.log(err);
                dispatch({ type: REMOVE_TOKEN });
            });
    }
};
export const clientTokenLogin = token => dispatch => {
    axios
        .post("/api/client/auth/tokenLogin", { token })
        .then(res => dispatch({ type: CLIENT_LOGIN, payload: res.data.data }))
        .catch(err => {
            console.log(err);
            dispatch({ type: REMOVE_TOKEN });
        });
};

export const saveClientRedirectUrl = url => {
	return {
		type: SAVE_CLIENT_REDIRECT_URL,
		payload: url
	};
};

export const saveClientUserType = data => {
	return {
		type: SAVE_CLIENT_USER_TYPE,
		payload: data
	};
};

export const clientLogout = () => {
    return {
        type: REMOVE_TOKEN
    };
};

export const clientTokenUpdate = data => {
	return {
		type: CLIENT_TOKEN_UPDATE,
		payload: data
	};
};

export const clientPointUpdate = data => {
	return {
		type: CLIENT_POINT_UPDATE,
		payload: data
	};
};