import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MDBDataTable } from 'mdbreact';
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { currency, dateFormat, timeFormat } from '../../../dynamicController';
import i18n from "i18next";
import { Select, DatePicker, Button, Row, Col } from 'antd';
// import moment from 'moment';
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);
const { Option } = Select;
const { RangePicker } = DatePicker;

class StorePerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: [],
            loading: false,
            outletId: [],
            startDate: "",
            endDate: "",
            operatorId: "",
            outlets: []
        };
    };

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.store_performance) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleChangeOperator = value => {
        const selectedOutlet = this.props.distributor.outlet.filter(outlet => outlet.adminId === value);
        this.setState({
            ...this.state,
            outlets: selectedOutlet,
            operatorId: value,
            outletId: []
        });
    }

    handleDate = (dates, dateStrings) => {
        this.setState({
            ...this.state,
            startDate: dateStrings[0],
            endDate: dateStrings[1]
        });
    };

    handleFilter = e => {
        const { outletId, startDate, endDate, operatorId } = this.state;

        if (!operatorId) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select operator"), "error");
        } else {

            let sendThis = {
                operatorId
            };

            if (outletId) {
                sendThis.outletId = outletId;
            }
            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }
            this.setState({
                ...this.state,
                loading: true
            });
            axios.post('/api/admin/report/store', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data.data
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            outletId: [],
            startDate: "",
            endDate: "",
            operatorId: "",
            data: [],
            outlets: []
        });
    };

    render() {
        let data = {
            columns: [
                {
                    label: i18n.t("Store"),
                    field: "outletName",
                    sort: "asc"
                },
                {
                    label: i18n.t("Total Registered Users"),
                    field: "totalUser",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("User registered but no transaction record")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Registered")}</span>
                        </OverlayTrigger>
                    ),
                    field: "registered",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Registered")} (%)`,
                    field: "registeredPercentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("User spending is less than")} {currency(this.props.distributor.country)}50
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Active")}</span>
                        </OverlayTrigger>
                    ),
                    field: "active",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Active")} (%)`,
                    field: "activePercentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("User spending is from")} {currency(this.props.distributor.country)}50 {i18n.t("until")} {currency(this.props.distributor.country)}199
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Tier 1")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tier1",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Tier 1")} (%)`,
                    field: "tier1Percentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("User spending is")} {currency(this.props.distributor.country)}200 {i18n.t("and above")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Tier 2")}</span>
                        </OverlayTrigger>
                    ),
                    field: "tier2",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Tier 2")} (%)`,
                    field: "tier2Percentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Average spending in each transaction")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Average Spending")} ({currency(this.props.distributor.country)})</span>
                        </OverlayTrigger>
                    ),
                    field: "averageSpending",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Total Transactions")}</span>
                        </OverlayTrigger>
                    ),
                    field: "totalTransactions",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Number of vouchers used in all the transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Voucher Claimed")}</span>
                        </OverlayTrigger>
                    ),
                    field: "voucherClaimed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of washer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Washer Usage")}</span>
                        </OverlayTrigger>
                    ),
                    field: "washerUsed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {i18n.t("Total number of dryer machine transactions")}
                                </Tooltip>
                            }
                        >
                            <span>{i18n.t("Dryer Usage")}</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerUsed",
                    sort: "asc"
                }
            ],
            rows: this.state.data
        };

        const csvData = {
            headers: [
                { label: "Store", key: "outletName" },
                { label: "Total Registered Users", key: "totalUser" },
                { label: "Registered", key: "registered" },
                { label: "Registered (%)", key: "registeredPercentage" },
                { label: "Active", key: "active" },
                { label: "Active (%)", key: "activePercentage" },
                { label: "Tier 1", key: "tier1" },
                { label: "Tier 1 (%)", key: "tier1Percentage" },
                { label: "Tier 2", key: "tier2" },
                { label: "Tier 2 (%)", key: "tier2Percentage" },
                { label: "Average Spending", key: "averageSpending" },
                { label: "Total Transactions", key: "totalTransactions" },
                { label: "Voucher Claimed", key: "voucherClaimed" },
                { label: "Washer Usage", key: "washerUsed" },
                { label: "Dryer Usage", key: "dryerUsed" },
            ],
            data: this.state.data
        };


        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Store Performance")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <label htmlFor="operatorId">{i18n.t("Select Operator")}: </label>
                                            <Select
                                                id="operatorId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Operator")}
                                                value={this.state.operatorId}
                                                onChange={this.handleChangeOperator}
                                            >
                                                <Option disabled value="">{i18n.t("Select Operator")}</Option>
                                                {this.props.distributor.admin.map(admin => (
                                                    <Option key={admin.id} value={admin.id}>
                                                        {admin.username}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="outletId">{i18n.t("Select Outlet")}: </label>
                                            <Select
                                                mode="multiple"
                                                id="outletId"
                                                style={{ width: '100%' }}
                                                placeholder={i18n.t("Select Outlet")}
                                                value={this.state.outletId}
                                                onChange={value => this.setState({ outletId: value })}
                                                showSearch
                                                optionFilterProp="label"
                                            >
                                                <Option disabled value="">{i18n.t("Select Outlet")}</Option>
                                                {this.state.operatorId ? this.state.outlets.map(outlet => (
                                                    <Option
                                                        key={outlet.id}
                                                        value={outlet.id}
                                                        label={`${outlet.outlet_fullname} (${outlet.outlet_name})`}
                                                    >
                                                        {outlet.outlet_fullname} ({outlet.outlet_name})
                                                    </Option>
                                                )) : null}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="date" >{i18n.t("Select Transaction Date")}: </label>
                                            <RangePicker
                                                value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
                                                onChange={this.handleDate}
                                                style={{ width: '100%' }}
                                                ranges={{
                                                    'Today': [dayjs(), dayjs()],
                                                    'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                                    'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                                    'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                                    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row gutter={16} className="mt-3">
                                        <Col span={12}>
                                            <Button type="primary" onClick={this.handleFilter} className="w-100">
                                                {i18n.t("Filter")}
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button onClick={this.handleReset} className="w-100">
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center mt-5">
                                    {
                                        this.state.loading ? <Loading /> : (
                                            <div>
                                                <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`store_performance_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                        {i18n.t("Download Report")}
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(StorePerformance);