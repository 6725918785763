import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

class AddFaqGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            defaultLanguage: "en",
            loading: false,
            faqGroupArray: [{ language: "en", title: "" }],
            languageArray: [
                { value: "en", title: "English" },
                { value: "ms", title: "Malay" },
                { value: "zh", title: "Chinese (Simplified)" },
                { value: "zhtw", title: "Chinese (Traditional)" }
            ]
        };
    };

    componentDidMount() {
        if (!this.props.client || !this.props.location.state || !this.props.client.faq_setting) {
            this.props.history.push('/client');
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => {

        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    removeFields = (index) => {
        let list = [...this.state.faqGroupArray];
        list.splice(index, 1);
        this.setState({ ...this.state, faqGroupArray: list })
    };

    addFields = () => {
        this.setState({ ...this.state, faqGroupArray: [...this.state.faqGroupArray, { language: "en", title: "" }] })
    }

    handleSubmit = e => {
        e.preventDefault();

        const { defaultLanguage, faqGroupArray } = this.state;

        const defaultFaqGroup = faqGroupArray.filter(faqGroup => faqGroup.language === defaultLanguage)[0]

        let submit = true
        let checkLanguage = []
        for (let i = 0; i < faqGroupArray.length; i++) {
            if (!checkLanguage.includes(faqGroupArray[i].language)) {
                checkLanguage.push(faqGroupArray[i].language)
            } else {
                mySwal.fire(i18n.t("Error"), i18n.t("Duplicate language selected"), "error");
                submit = false
            }
        }

        if (submit) {
            if (defaultFaqGroup) {
                mySwal.fire({
                    title: i18n.t("Confirmation"),
                    text: i18n.t("Are you sure you want to add FAQ Group?"),
                    icon: "question",
                    showCancelButton: true,
                    cancelButtonText: i18n.t("No"),
                    confirmButtonText: i18n.t("Yes"),
                    cancelButtonColor: "#d33"
                })
                    .then(click => {

                        if (click.isConfirmed && click.value) {
                            this.setState({
                                ...this.state,
                                loading: true
                            });

                            const sendThis = {
                                id: this.props.location.state.distributorId,
                                role: "distributor",
                                title: defaultFaqGroup.title,
                                language: defaultLanguage,
                                faqGroupArray
                            }

                            axios.post('/api/admin/setting/addFaqGroup', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                                .then(res => {

                                    this.setState({
                                        ...this.state,
                                        defaultLanguage: "en",
                                        faqGroupArray: [{ language: "en", title: "" }],
                                        loading: false,
                                    });

                                    mySwal.fire(i18n.t("Success"), i18n.t("FAQ Group added successfully"), "success");

                                    this.props.history.push("/client/faq", { distributorId: this.props.location.state.distributorId });

                                })
                                .catch(err => {
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                    });
                                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                                });
                        }
                    })
            } else {
                mySwal.fire(i18n.t("Error"), i18n.t("Please provide the faq group with the default language"), "error");
            }
        }
    };

    render() {

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("Add FAQ Group")}</h5>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="defaultLanguage">{i18n.t("Default Language")} : </label><br />
                                        <select className="browser-default form-select" id="defaultLanguage" name="defaultLanguage" value={this.state.defaultLanguage} onChange={e => this.handleChange(e)} required>
                                            <option value="" disabled>{i18n.t("Select Default Language")}</option>
                                            {
                                                this.state.languageArray.map((language, index) => {
                                                    return (<option value={language.value} key={index}>{language.title}</option>)
                                                })
                                            }
                                        </select>
                                    </div>

                                    {
                                        this.state.faqGroupArray.map((faqGroup, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className="row">
                                                        <div className="form-group col">
                                                            <label htmlFor="title">{i18n.t("Title")}: </label>
                                                            <textarea id="title" name="title" className="form-control" required value={this.state.faqGroupArray[i].title} onChange={e => {
                                                                let list = [...this.state.faqGroupArray];
                                                                list[i].title = e.target.value
                                                                this.setState({ ...this.state, faqGroupArray: list })
                                                            }}></textarea>
                                                        </div>
                                                        <div className="form-group col-3">
                                                            <label htmlFor="language">{i18n.t("Title Language")} : </label>
                                                            <select className="browser-default form-select" id="language" name="language" required value={this.state.faqGroupArray[i].language} onChange={e => {
                                                                let list = [...this.state.faqGroupArray];
                                                                list[i].language = e.target.value
                                                                this.setState({ ...this.state, faqGroupArray: list })
                                                            }}>
                                                                <option value="" disabled>{i18n.t("Select Language")}</option>
                                                                {
                                                                    this.state.languageArray.map((language, index) => {
                                                                        return (<option value={language.value} key={index}>{language.title}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-1 m-auto">
                                                            <button type="button" className="btn btn-secondary" onClick={(e) => this.removeFields(i)}>
                                                                {i18n.t("Remove")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        this.state.languageArray.length !== this.state.faqGroupArray.length ? (
                                            <div className="form-group">
                                                <button type="button" className="btn btn-dark" onClick={(e) => this.addFields()}>
                                                    {i18n.t("Add Translation")}
                                                </button>
                                            </div>
                                        ) : null
                                    }

                                    <div className="mt-5">
                                        {
                                            this.state.loading
                                                ? <Loading />
                                                : (
                                                    <div>
                                                        <input type="submit" value={i18n.t("SUBMIT")} className="btn btn-primary form-control mb-1" />
                                                        <button type="button" onClick={() => this.props.history.push("/client/faq", { distributorId: this.props.location.state.distributorId })} className="btn btn-danger mt-1 form-control font-weight-bold">{i18n.t("Cancel")}</button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(AddFaqGroup);