import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOperators } from '../../../redux/actions/devActions';
import axios from 'axios';

class UpdateAllOutlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operatorId: "",
            outlets: [],
            loading: false
        };
    };

    componentDidMount = () => this.props.dev.isAuthenticated && this.props.fetchOperators();

    handleSelectOperator = e => {

        const allOperators = this.props.dev.operatorData;
        const selectedOperator = allOperators.filter(operator => operator.operatorId === e.target.value)[0];

        this.setState({
            ...this.state,
            operatorId: e.target.value,
            outlets: selectedOperator.outlets,
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        const { operatorId, outlets } = this.state;
        if (operatorId && outlets) {
            this.setState({
                ...this.state,
                loading: true
            });

            const sendThis = {
                operatorId,
                outlets,
            };
            
            axios.post('/api/dev/updateAllOutlet', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        operatorId: "",
                        outlets: [],
                        loading: false
                    });
                    alert("Success");
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    alert(err.response.data.error);
                });
        }
    };

    render() {
        if (!this.props.dev.isLoading && this.props.dev.error) {
            return (
                <div className="text-center pt-5">
                    <h1>{this.props.dev.error}</h1>
                </div>
            )
        } else if (this.props.dev.isLoading || !this.props.dev.operatorData) {
            return (
                <div className="text-center pt-5">
                    <h1>Loading ...</h1>
                </div>
            )
        } else {
            return (
                <div className="pt-5 px-5">
                    <form className="mt-4" onSubmit={this.handleSubmit}>
                        <div className="text-center">
                            <h1>Update All Outlet</h1>
                        </div>
                        <div className="form-group mt-5">
                            <label htmlFor="operatorId">Operators</label>
                            <select name="operatorId" id="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleSelectOperator}>
                                <option value="" disabled>Select an operator</option>
                                {this.props.dev.operatorData.map(operator => <option key={operator.operatorId} data={operator} value={operator.operatorId}>{operator.userName} ({operator.operatorId})</option>)}
                            </select>
                        </div>

                        <div>
                            {
                                this.state.loading
                                    ? (
                                        <div className="text-center pt-5">
                                            <p>Loading ...</p>
                                        </div>
                                    )
                                    : <input type="submit" value="SUBMIT" className="btn btn-primary form-control font-weight-bold mt-5" />
                            }
                        </div>
                    </form>
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        dev: state.dev
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchOperators: () => dispatch(fetchOperators())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAllOutlet);