import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./pages/Login";
import AdminDashboard from "./pages/Dashboard";
import VoucherSetting from "./pages/Setting";
import StorePerformance from "./pages/reports/StorePerformance";
import UserPerformance from "./pages/reports/UserPerformance";
import VoucherRedemption from "./pages/reports/VoucherRedemption";
import TokenReport from "./pages/reports/TokenReport";
import TokenPurchaseHistory from "./pages/reports/TokenPurchaseHistory";
import UserDetails from "./pages/UserDetails";
import Customize from "./pages/Customize";
import TokenSetting from "./pages/Token";
import TokenDetail from "./pages/reports/TokenDetail";
import Giveaway from "./pages/Reward";
import SmsReport from "./pages/reports/SmsReport";
import Faq from "./pages/Faq";
import FaqList from "./pages/FaqList";
import AddFaqGroup from "./pages/AddFaqGroup";
import AddFaq from "./pages/AddFaq";
import EditFaqGroup from "./pages/EditFaqGroup";
import EditFaq from "./pages/EditFaq";
import RewardHistory from "./pages/RewardHistory";
import Referral from "./pages/Referral";
import News from "./pages/News";
import AddNews from "./pages/AddNews";
import EditNews from "./pages/EditNews";
import MemberDetailsReport from "./pages/reports/MemberDetailsReport";
import DiscountCode from "./pages/DiscountCode";
import DiscountCodeReport from "./pages/reports/DiscountCodeReport";
import VoucherCodeGenerator from "./pages/VoucherCodeGenerator";
import VoucherCodeReport from "./pages/reports/VoucherCodeReport";
import UserFloatingTokenReport from "./pages/reports/UserFloatingTokenReport";
import EPaymentReport from "./pages/reports/EPaymentReport";
import UserUsageReport from "./pages/reports/UserUsageReport";
import UserFloatingPointReport from "./pages/reports/FloatingPointReport";
import ReferralRewardHistoryReport from "./pages/reports/ReferralRewardHistoryReport";

const Admin = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/admin/login" component={Login} />
				<Route exact path="/admin/dashboard" component={AdminDashboard} />
				<Route exact path="/admin/voucher/setting" component={VoucherSetting} />
				<Route exact path="/admin/outlet/performance" component={StorePerformance} />
				<Route exact path="/admin/user/performance" component={UserPerformance} />
				<Route exact path="/admin/voucher/redemption" component={VoucherRedemption} />
				<Route exact path="/admin/token/report" component={TokenReport} />
				<Route exact path="/admin/user/details" component={UserDetails} />
				<Route exact path="/admin/user/details/memberDetails" component={MemberDetailsReport} />
				<Route exact path="/admin/setting/customize" component={Customize} />
				<Route exact path="/admin/token/setting" component={TokenSetting} />
				<Route exact path="/admin/token/report/detail" component={TokenDetail} />
				<Route exact path="/admin/token/report/purchasehistory" component={TokenPurchaseHistory} />
				<Route exact path="/admin/giveaway" component={Giveaway} />
				<Route exact path="/admin/giveaway/history" component={RewardHistory} />
				<Route exact path="/admin/sms/report" component={SmsReport} />
				<Route exact path="/admin/faq" component={Faq} />
				<Route exact path="/admin/faq/list" component={FaqList} />
				<Route exact path="/admin/faq/group/add" component={AddFaqGroup} />
				<Route exact path="/admin/faq/add" component={AddFaq} />
				<Route exact path="/admin/faq/group/edit" component={EditFaqGroup} />
				<Route exact path="/admin/faq/edit" component={EditFaq} />
				<Route exact path="/admin/referral" component={Referral} />
				<Route exact path="/admin/news" component={News} />
				<Route exact path="/admin/news/add" component={AddNews} />
				<Route exact path="/admin/news/edit" component={EditNews} />
				<Route exact path="/admin/discountCode" component={DiscountCode} />
				<Route exact path="/admin/discountCode/report" component={DiscountCodeReport} />
				<Route exact path="/admin/voucherCode" component={VoucherCodeGenerator} />
				<Route exact path="/admin/voucherCode/report" component={VoucherCodeReport} />
				<Route exact path="/admin/floatingToken/report" component={UserFloatingTokenReport} />
				<Route exact path="/admin/floatingPoint/report" component={UserFloatingPointReport} />
				<Route exact path="/admin/epayment/report" component={EPaymentReport} />
				<Route exact path="/admin/userUsage/report" component={UserUsageReport} />
				<Route exact path="/admin/referralReward/report" component={ReferralRewardHistoryReport} />
				<Redirect to="/admin/login" />
			</Switch>
		</div>
	);
};

export default Admin;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// template

// import React , { Component } from 'react';
// import { connect } from 'react-redux';
// import Sidebar from '../../components/Sidebar';
// import Topbar from '../../components/Topbar';

// import { MDBDataTable } from 'mdbreact';

// class StorePerformance extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             toggled : false
//         };
//     };
//     _isMounted = false;

//     componentDidMount() {
//         this._isMounted = true;
//         if (this._isMounted) {
//             if (!this.props.admin.isAuthenticated) {
//                 return this.props.history.push('/admin/dashboard');
//             } else {
//                 console.log("Mounted");
//             }
//         }
//     };

//     componentWillUnmount() {this._isMounted = false;};

//     handleToggle = () => this.setState({...this.state, toggled : !this.state.toggled});

//     render() {
//         return (
//             <div className="admin-page-container">
//                 <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
//                 <div className="admin-page">
//                     <Topbar handleToggle={this.handleToggle} />
//                     <div className="admin-content">
//                         <div className="card">
//                             <div className="card-header">
//                                 <h5 className="card-title">Store Performance</h5>
//                             </div>

//                             <div className="card-body">
//                                 <h1>Table</h1>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     };
// };

// const mapStateToProps = state => {
//     return {
//         admin : state.admin
//     };
// };

// export default connect(mapStateToProps , null)(StorePerformance);
