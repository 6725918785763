import { useState } from 'react';
import { connect } from 'react-redux';
import { staffLogin } from '../../../redux/actions/staffActions';
import { logoController } from '../../../dynamicController';
import { useTranslation } from "react-i18next";

const StaffLogin = ({ login, staff, themeLogo }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { t } = useTranslation();

    const handleSubmit = e => {
        e.preventDefault();
        setUsername("");
        setPassword("");
        login({ username, password });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Loyalty Login',
            'user_id': username,
            'brand': process.env.REACT_APP_STORETYPE,
            'role': 'staff'
        })
    };

    return (
        <div className="admin-bgImg">
            <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center" }}>
                <div id="admin-login">
                    <div id="login-header">
                        <img src={logoController(themeLogo)} alt="main logo" className="img-fluid" />
                        <h4 className="text-left" style={{ color: "#4a536b" }}>{t("Easy")} &amp; {t("Fast Laundromate")}</h4>
                        <h6 className="text-left" style={{ color: "#4a536b" }}>{t("Smart Laundry Management System")}</h6>
                    </div>

                    <div id="login-form">
                        <h4>{t("Staff Login")}</h4>
                        <p>{t("Enter your username and password to login")}</p>

                        {
                            staff.error ? (
                                <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "alert alert-warning" : "alert alert-primary"} role="alert">
                                    <strong>{t(staff.error)}</strong>
                                </div>
                            ) : (null)
                        }

                        <form onSubmit={handleSubmit} className="mt-4">
                            <div className="form-group">
                                {/* <label htmlFor="username">Username: </label> */}
                                <input type="text" id="username" name="username" placeholder={t("Username")} className="form-control browser-default" value={username} onChange={e => setUsername(e.target.value)} required />
                            </div>

                            <div className="form-group">
                                {/* <label htmlFor="password">Password: </label> */}
                                <input type="password" id="password" name="password" placeholder={t("Password")} className="form-control browser-default" value={password} onChange={e => setPassword(e.target.value)} required />
                            </div>

                            <div className="mt-4">
                                {
                                    staff.isLoading ? (
                                        <div className="text-center">
                                            <div className={process.env.REACT_APP_STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"} role="status">
                                                <span className="sr-only">Loading ...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <input type="submit" value={t("LOGIN")} className="btn form-control admin-login-button" />
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        staff: state.staff,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: data => dispatch(staffLogin(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffLogin);