import { DEV_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, FETCH_OPERATORS, REMOVE_TOKEN } from './type';
import axios from 'axios';

export const devLogin = data => dispatch => {
    dispatch({type: IS_LOADING});
    axios.post('/api/dev/loginDev', data)
    .then(res => {
        dispatch({type: DEV_LOGIN, payload: res.data.token});
        dispatch({type: CLEAR_ERROR});
        dispatch({type: IS_LOADED});
    })
    .catch(err => {
        dispatch({type: SHOW_ERROR, payload: err.response.data.error});
        dispatch({type: IS_LOADED});
    });
};

// check token for session
export const checkToken = () => (dispatch , getState) => {
    if (getState().dev.token) {
        axios.post('/api/dev/checkTokenDev' , {token : getState().dev.token})
        .then(res => dispatch({type : DEV_LOGIN , payload : res.data.token}))
        .catch(err => {
            console.log(err);
            dispatch({type : REMOVE_TOKEN});
        });
    }
};

export const devLogout = () => {
	return {
		type: REMOVE_TOKEN
	};
};

export const fetchOperators = () => (dispatch, getState) =>{
    const token = getState().dev.token;
    dispatch({type: IS_LOADING});
    axios.get('/api/dev/get/admin' , {headers : {"Content-Type" : "application/json", "auth-token" : token}})
    .then(res => {
        dispatch({type: IS_LOADED});
        dispatch({type: CLEAR_ERROR});
        dispatch({
            type: FETCH_OPERATORS,
            payload: res.data.data
        });
    })
    .catch(err => {
        dispatch({type: IS_LOADED});
        dispatch({type : SHOW_ERROR, payload: err.response.data.error});
    });
};