import {
	IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, ADMIN_LOGIN, UPDATE_THEME, UPDATE_VOUCHER, UPDATE_MECHANIC, ADD_PACKAGE, DEL_PACKAGE, ENABLE_REFERRAL,
	UPDATE_REFERRAL, UPDATE_PAYMENT_SETTING, UPDATE_REGISTER_VOUCHER, UPDATE_BIRTHDAY_VOUCHER, UPDATE_RELOAD_VOUCHER, SAVE_ADMIN_REDIRECT_URL, SAVE_ADMIN_USER_TYPE,
	UPDATE_PAYMENT_METHOD_SETTING, UPDATE_POINT_SETTINGS
} from "./type";
import axios from "axios";

export const adminLogin = data => dispatch => {
	dispatch({ type: CLEAR_ERROR });
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/auth/login", data)
		.then(res => {
			dispatch({ type: ADMIN_LOGIN, payload: res.data.data });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const adminCheckSession = callback => (dispatch, getState) => {
	if (getState().admin.token && !getState().admin.isAuthenticated) {
		dispatch({ type: IS_LOADING });
		axios
			.post("/api/admin/auth/checkToken", { token: getState().admin.token })
			.then(res => {
				dispatch({ type: ADMIN_LOGIN, payload: res.data.data });
				dispatch({ type: IS_LOADED });
				callback();
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: REMOVE_TOKEN });
				dispatch({ type: IS_LOADED });
			});
	}
};

export const adminTokenLogin = token => dispatch => {
	axios
		.post("/api/admin/auth/tokenLogin", { token })
		.then(res => dispatch({ type: ADMIN_LOGIN, payload: res.data.data }))
		.catch(err => {
			console.log(err);
			dispatch({ type: REMOVE_TOKEN });
		});
};

export const saveAdminRedirectUrl = url => {
	return {
		type: SAVE_ADMIN_REDIRECT_URL,
		payload: url
	};
};

export const saveAdminUserType = data => {
	return {
		type: SAVE_ADMIN_USER_TYPE,
		payload: data
	};
};


export const updateTheme = theme => {
	return {
		type: UPDATE_THEME,
		payload: theme
	};
};

export const adminLogout = () => {
	return {
		type: REMOVE_TOKEN
	};
};

export const updateVoucher = data => {
	return {
		type: UPDATE_VOUCHER,
		payload: data
	};
};

export const updateMechanic = data => {
	return {
		type: UPDATE_MECHANIC,
		payload: data
	};
};

export const updatePaymentSetting = data => {
	return {
		type: UPDATE_PAYMENT_SETTING,
		payload: data
	};
};

export const updatePaymentMethodSetting = data => {
	return {
		type: UPDATE_PAYMENT_METHOD_SETTING,
		payload: data
	};
};

export const updatePointSettings = data => {
	return {
		type: UPDATE_POINT_SETTINGS,
		payload: data
	};
};

export const addPackage = data => {
	return {
		type: ADD_PACKAGE,
		payload: data
	};
};

export const delPackage = data => {
	return {
		type: DEL_PACKAGE,
		payload: data
	};
};

export const enableReferral = data => {
	return {
		type: ENABLE_REFERRAL,
		payload: data
	};
};

export const updateReferral = data => {
	return {
		type: UPDATE_REFERRAL,
		payload: data
	};
};

export const updateRegisterVoucher = data => {
	return {
		type: UPDATE_REGISTER_VOUCHER,
		payload: data
	};
};

export const updateBirthdayVoucher = data => {
	return {
		type: UPDATE_BIRTHDAY_VOUCHER,
		payload: data
	};
};

export const updateReloadVoucher = data => {
	return {
		type: UPDATE_RELOAD_VOUCHER,
		payload: data
	};
};