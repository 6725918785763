import { useTranslation } from "react-i18next";

const SizeSelection = ({ setSize, sizeSelection, setPage, cart }) => {
    const { t } = useTranslation();

    const selectSize = (s) => {
        setSize(s)
        setPage("Details")
    }

    return (
        <div>
            <div className="payment-section">
                <p><b>{t("Select Size")}</b></p>

                <div className="size-selection-buttons">
                    {
                        sizeSelection.map((s, index) => {
                            return (
                                <button key={index} onClick={() => selectSize(s.size)}>{s.size} {s.weight ? `(<${s.weight})` : null}</button>
                            )
                        })
                    }
                </div>
            </div>

            {
                cart.length > 0 ? (
                    <div className="px-3 pb-3">
                        <button
                            id="pay-dng"
                            className="btn form-control big-button"
                            onClick={() => setPage("Payment")}
                        >
                            {t("Pay now")}
                        </button>
                    </div>
                ) : null
            }
        </div>
    )
}

export default SizeSelection;