import React, { Component } from "react";
import { connect } from "react-redux";
import Topbar from '../components/Topbar';
import Sidebar from "../components/Sidebar";
import Loading from '../../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import i18n from "i18next";
import { Select, DatePicker, Button, Row, Col } from 'antd';
// import moment from 'moment';
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);
const { Option } = Select;
// const { RangePicker } = DatePicker;

class EPaymentReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			data: null,
			loading: false,
			startDate: "",
			endDate: "",
			operatorId: "",
			distributorId: "",
			operators: [],
			country: ""
		};
	}

	componentDidMount() {
		if (!this.props.client || !this.props.client.epayment_report) {
			return this.props.history.push('/client');
		}
	};


	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });



	handleDate = dates => {
		if (dates) {
			const [start, end] = dates;
			this.setState({
				startDate: start,
				endDate: end
			});
		} else {
			this.setState({
				startDate: null,
				endDate: null
			});
		}
	};

	handleFilter = e => {
		const { startDate, endDate, distributorId } = this.state;

		if (!distributorId) {
			mySwal.fire(i18n.t("Error"), i18n.t("Please select distributor"), "error");
		} else {
			let sendThis = {
				operatorId: this.props.client.admin.filter(admin => admin.distributorId === distributorId).map(admin => admin.id)
			}

			if (startDate && endDate) {
				sendThis.startDate = new Date(startDate);
				sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
			}
			this.setState({
				...this.state,
				loading: true
			});
			axios
				.post("/api/admin/report/ePaymentReport", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
				.then(res => {
					this.setState({
						...this.state,
						loading: false,
						data: res.data.data
					});
				})
				.catch(err => {
					this.setState({
						...this.state,
						loading: false
					});
					mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
				});
		}
	};

	handleReset = e => {
		this.setState({
			...this.state,
			startDate: "",
			endDate: "",
			operatorId: "",
			data: [],
			distributorId: "",
			operators: [],
			country: ""
		});
	};

	handleChangeDistributor = value => {
		const selectedOperator = this.props.client.admin.filter(admin => admin.distributorId === value);
		this.setState({
			...this.state,
			operators: selectedOperator,
			distributorId: value,
			operatorId: "",
			country: this.props.client.distributor.find(distributor => distributor.id === value).country
		});
	};

	render() {
		let data = {
			columns: [
				{
					label: i18n.t("Transaction Date"),
					field: "date",
					sort: "asc"
				},
				{
					label: i18n.t("User Name"),
					field: "name",
					sort: "asc"
				},
				{
					label: i18n.t("Phone Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email Address"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "member_id",
					sort: "asc"
				},
				{
					label: i18n.t("Transaction ID"),
					field: "transaction_id",
					sort: "asc"
				},
				{
					label: i18n.t("Method"),
					field: "method",
					sort: "asc"
				},
				{
					label: `${i18n.t("Total Amount")} ${this.state.country ? `(${currency(this.state.country)})` : ""}`,
					field: "total_amount",
					sort: "asc"
				},
				{
					label: i18n.t("Type"),
					field: "type",
					sort: "asc"
				},
			],
			rows: this.state.data ?? []
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					date: this.state.data[i].date,
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					email: this.state.data[i].email,
					member_id: this.state.data[i].member_id,
					transaction_id: this.state.data[i].transaction_id,
					method: this.state.data[i].method,
					total_amount: this.state.data[i].total_amount,
					type: this.state.data[i].type,
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "Transaction Date", key: "date" },
				{ label: "User Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Email Address", key: "email" },
				{ label: "Member ID", key: "member_id" },
				{ label: "Transaction ID", key: "transaction_id" },
				{ label: "Method", key: "method" },
				{ label: `Total Amount ${this.state.country ? `(${currency(this.state.country)})` : ""}`, key: "total_amount" },
				{ label: "Type", key: "type" },
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">{i18n.t("E-Payment Report")}</h5>
							</div>

							<div className="card-body">
								<div className="mb-5">
									<h6>{i18n.t("Filter")}</h6>
									<Row gutter={16}>
										<Col span={24}>
											<label htmlFor="distributorId">{i18n.t("Select Distributor")}: </label>
											<Select
												id="distributorId"
												style={{ width: '100%' }}
												placeholder={i18n.t("Select Distributor")}
												value={this.state.distributorId}
												onChange={this.handleChangeDistributor}
											>
												<Option disabled value="">{i18n.t("Select Distributor")}</Option>
												{this.props.client.distributor.map(distributor => (
													<Option key={distributor.id} value={distributor.id}>{distributor.name}</Option>
												))}
											</Select>
										</Col>
									</Row>
									<Row gutter={16} className="mt-3">
										<Col span={24}>
											<label htmlFor="date" >{i18n.t("Select Transaction Date")}: </label>
											<DatePicker.RangePicker
												value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : []}
												onChange={this.handleDate}
												style={{ width: '100%' }}
												ranges={{
													'Today': [dayjs(), dayjs()],
													'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
													'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
													'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
													'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
													'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
												}}
											/>
										</Col>
									</Row>
									<Row gutter={16} className="pt-2">
										<Col span={12}>
											<Button type="primary" onClick={this.handleFilter} block>
												{i18n.t("Filter")}
											</Button>
										</Col>
										<Col span={12}>
											<Button onClick={this.handleReset} block>
												{i18n.t("Reset")}
											</Button>
										</Col>
									</Row>
								</div>
								<div className="text-center mt-5">
									{
										this.state.loading ? <Loading /> : (
											<div>
												<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
												<div className="d-grid gap-2 d-md-flex justify-content-md-end">
													<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`epayment_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
														{i18n.t("Download Report")}
													</CSVLink>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		client: state.client.client,
		token: state.client.token
	};
};

export default connect(mapStateToProps, null)(EPaymentReport);
