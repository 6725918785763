import { REMOVE_TOKEN, KIOSK_LOGIN, SAVE_KIOSK_OUTLETID } from '../actions/type';

const initState = {
    isAuthenticated: false,
    token: localStorage.getItem("laundry-kiosk"),
    outletId: localStorage.getItem("laundry-kiosk-outletId"),
    data: null
};

const kioskReducer = (state = initState, action) => {
    switch (action.type) {
        case KIOSK_LOGIN:
            localStorage.setItem("laundry-kiosk", action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                data: action.payload.data
            };
        case REMOVE_TOKEN:
            localStorage.removeItem("laundry-kiosk");
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                data: null
            };
        case SAVE_KIOSK_OUTLETID:
            localStorage.setItem("laundry-kiosk-outletId", action.payload);
            return {
                ...state,
                outletId: action.payload
            };
        default:
            return state;
    }
};

export default kioskReducer;