import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { enableReferral, updateReferral, updateVoucher } from "../../../redux/actions/adminActions";
import { currency } from "../../../dynamicController";
import i18n from "i18next";

const mySwal = withReactContent(Swal);

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];

class ReferralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            referral: false,
            loading: false,
            tiers: []
        };
    }

    componentDidMount() {
        if (!this.props.admin.isAuthenticated || !this.props.admin.admin.referral_setting) {
            return this.props.history.push("/admin/dashboard");
        } else {
            let data = []
            for (let i = 0; i < this.props.admin.admin.referralSetting.length; i++) {
                const voucherSetting = this.props.admin.admin.setting.filter(setting => setting.id === this.props.admin.admin.referralSetting[i].voucherSettingId)[0]
                let tempData = {
                    voucher_eligible: voucherSetting ? voucherSetting.voucher_eligible : false,
                    voucher_amount: voucherSetting ? voucherSetting.voucher_amount : 1,
                    voucher_type: voucherSetting ? voucherSetting.voucher_type : "Flat",
                    voucher_expiry: voucherSetting ? voucherSetting.voucher_expiry : true,
                    voucher_duration: voucherSetting ? voucherSetting.voucher_duration : 30,
                    voucher_title: voucherSetting ? voucherSetting.voucher_title : "REFERRAL REWARDS",
                    voucher_description: voucherSetting ? voucherSetting.voucher_description : "Thank you for inviting friends, here's your referral rewards. Enjoy!",
                    voucher_washer_use: voucherSetting ? voucherSetting.voucher_washer_use : true,
                    voucher_dryer_use: voucherSetting ? voucherSetting.voucher_dryer_use : false,
                    voucher_package_use: voucherSetting ? voucherSetting.voucher_package_use : false,
                    token_eligible: this.props.admin.admin.referralSetting[i].token_eligible,
                    token_type: this.props.admin.admin.referralSetting[i].token_type,
                    token_amount: this.props.admin.admin.referralSetting[i].token_amount,
                    stamp_eligible: this.props.admin.admin.referralSetting[i].stamp_eligible,
                    stamp_combined: this.props.admin.admin.referralSetting[i].stamp_combined,
                    stamp_washer: this.props.admin.admin.referralSetting[i].stamp_washer,
                    stamp_dryer: this.props.admin.admin.referralSetting[i].stamp_dryer,
                    point_eligible: this.props.admin.admin.referralSetting[i].point_eligible,
                    point_type: this.props.admin.admin.referralSetting[i].point_type,
                    point_amount: this.props.admin.admin.referralSetting[i].point_amount,
                    number_of_referral: this.props.admin.admin.referralSetting[i].number_of_referral,
                    amount_transaction: this.props.admin.admin.referralSetting[i].amount_transaction,
                    reward_per_user: this.props.admin.admin.referralSetting[i].reward_per_user,
                }
                data.push(tempData)
            }
            this.setState({
                ...this.state,
                referral: this.props.admin.admin.referral,
                tiers: data
            });
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleEnable = e => {
        axios
            .post("/api/admin/setting/enableReferral", { id: this.props.admin.admin.operatorId, role: "operator", data: !this.state.referral }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({ ...this.state, referral: res.data.data });
                this.props.enableReferral(res.data.data);
            })
            .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
    };

    handleCheckbox = (e, index) => {
        const { id, checked } = e.target;
        const newTiers = [...this.state.tiers];
        newTiers[index][id] = checked;
        this.setState({ ...this.state, tiers: newTiers });
    };

    handleChange = (e, index) => {
        const { id, value } = e.target;
        const newTiers = [...this.state.tiers];
        newTiers[index][id] = value;
        this.setState({ ...this.state, tiers: newTiers });
    };

    handleSubmit = e => {
        e.preventDefault();

        if (this.state.tiers.length <= 0) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please add at least one tier"), "error");
        } else {
            const sendThis = {
                id: this.props.admin.admin.operatorId,
                role: "operator",
                tiers: this.state.tiers
            };

            this.setState({ ...this.state, loading: true });

            axios.post('/api/admin/setting/updateReferral', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({ ...this.state, loading: false });
                    this.props.updateReferral(res.data.referralSettings);
                    this.props.updateVoucher(res.data.data);
                    mySwal.fire({
                        title: i18n.t("Success"),
                        text: i18n.t("The referral setting has been updated successfully!"),
                        icon: "success",
                    });
                })
                .catch(err => {
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                    this.setState({ ...this.state, loading: false });
                });
        }
    };

    addTier = () => {
        this.setState({
            ...this.state,
            tiers: [...this.state.tiers, {
                voucher_eligible: false,
                voucher_amount: 1,
                voucher_type: "Flat",
                voucher_expiry: true,
                voucher_duration: 30,
                voucher_title: "REFERRAL REWARDS",
                voucher_description: "Thank you for inviting friends, here's your referral rewards. Enjoy!",
                voucher_washer_use: true,
                voucher_dryer_use: false,
                voucher_package_use: false,
                token_eligible: false,
                token_type: "Flat",
                token_amount: 1,
                stamp_eligible: false,
                stamp_combined: 1,
                stamp_washer: 1,
                stamp_dryer: 1,
                point_eligible: false,
                point_type: "Flat",
                point_amount: 5,
                number_of_referral: 3,
                amount_transaction: 5,
                reward_per_user: 1
            }]
        });
    };

    deleteTier = (index) => {
        mySwal.fire({
            title: i18n.t("Confirmation"),
            text: i18n.t("Are you sure you want to delete the tier setting?"),
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: i18n.t("Delete"),
            cancelButtonText: i18n.t("No"),
        }).then((result) => {
            if (result.isConfirmed) {
                let newTiers = [...this.state.tiers]
                newTiers.splice(index, 1)
                this.setState({ ...this.state, tiers: newTiers });
            }
        });
    };

    render() {

        const stampSetting = (index) => {
            const tier = this.state.tiers[index];
            if (this.props.admin.admin.combined) {
                return (
                    <div className="form-group">
                        <label htmlFor="stamp_combined">{i18n.t("Number of Stamp")} : </label>
                        <input type="number" id="stamp_combined" name="stamp_combined" value={tier.stamp_combined} onChange={(e) => this.handleChange(e, index)} min="0" required />
                    </div>
                );
            } else {
                if (this.props.admin.admin.specificStamp === "both") {
                    return (
                        <div>
                            <div className="form-group">
                                <label htmlFor="stamp_washer">{i18n.t("Number of Washer Stamp")}: </label>
                                <input type="number" id="stamp_washer" name="stamp_washer" value={tier.stamp_washer} onChange={(e) => this.handleChange(e, index)} min="0" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="stamp_dryer">{i18n.t("Number of Dryer Stamp")}: </label>
                                <input type="number" id="stamp_dryer" name="stamp_dryer" value={tier.stamp_dryer} onChange={(e) => this.handleChange(e, index)} min="0" required />
                            </div>
                        </div>
                    );
                } else if (this.props.admin.admin.specificStamp === "washerOnly") {
                    return (
                        <div className="form-group">
                            <label htmlFor="stamp_washer">{i18n.t("Number of Washer Stamp")}: </label>
                            <input type="number" id="stamp_washer" name="stamp_washer" value={tier.stamp_washer} onChange={(e) => this.handleChange(e, index)} min="0" required />
                        </div>
                    );
                } else if (this.props.admin.admin.specificStamp === "dryerOnly") {
                    return (
                        <div className="form-group">
                            <label htmlFor="stamp_dryer">{i18n.t("Number of Dryer Stamp")}: </label>
                            <input type="number" id="stamp_dryer" name="stamp_dryer" value={tier.stamp_dryer} onChange={(e) => this.handleChange(e, index)} min="0" required />
                        </div>
                    );
                }
            }
        }

        return (
            <div id="admin-dashboard" className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="mt-4 mb-5">
                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="enable" name="enable" checked={this.state.referral} onChange={this.handleEnable} />
                                    <span>{i18n.t("Enable Referral Feature?")}</span>
                                </label>
                            </div>
                        </div>

                        {this.props.admin.admin && this.props.admin.admin.referral ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{i18n.t("Referral Settings")}</h5>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-body">
                                        {this.state.tiers.map((tier, index) => (
                                            <div className="card mb-4" key={index}>
                                                <div className="card-header d-flex justify-content-between align-items-center">
                                                    <span>{i18n.t("Tier")} {index + 1}</span>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => this.deleteTier(index)}
                                                    >
                                                        {i18n.t("Delete")}
                                                    </button>
                                                </div>

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="amount_transaction">{i18n.t("Minimum referee transaction amount for referrer to get referral rewards")} : <small> ({currency(this.props.admin.admin.country)})</small></label>
                                                        <input type="number" id="amount_transaction" name="amount_transaction" value={tier.amount_transaction} required onChange={(e) => this.handleChange(e, index)} min="0" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="number_of_referral">{i18n.t("Maximum number of reward within this tier")} : </label>
                                                        <input type="number" id="number_of_referral" name="number_of_referral" value={tier.number_of_referral} required onChange={(e) => this.handleChange(e, index)} min="0" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="reward_per_user">{i18n.t("Maximum number of reward can get from same user")} : </label>
                                                        <input type="number" id="reward_per_user" name="reward_per_user" value={tier.reward_per_user} required onChange={(e) => this.handleChange(e, index)} min="0" />
                                                    </div>

                                                    <div className="d-flex justify-content-between">
                                                        {/* STAMP SETTINGS */}
                                                        {this.props.admin.admin.stamp && (
                                                            <div className="card" style={{ flex: 1, margin: '10px' }}>

                                                                <div className="card-header">
                                                                    <h6 className="m-0 p-0">{i18n.t("Stamp")}</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="stamp_eligible" name="stamp_eligible" checked={tier.stamp_eligible} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the system will give stamp to user")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Eligibility")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                    {stampSetting(index)}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* POINTS SETTINGS */}
                                                        {this.props.admin.admin.loyalty_point && (
                                                            <div className="card" style={{ flex: 1, margin: '10px' }}>

                                                                <div className="card-header">
                                                                    <h6 className="m-0 p-0">{i18n.t("Point")}</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="point_eligible" name="point_eligible" checked={tier.point_eligible} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the system will give points to user")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Eligibility")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="point_type">{i18n.t("Point Grant Type")} : </label>
                                                                        <select className="browser-default form-select" id="point_type" name="point_type" value={tier.point_type} onChange={(e) => this.handleChange(e, index)} required>
                                                                            <option value="" disabled>{i18n.t("Select Point Grant Type")}</option>
                                                                            <option value="Flat">{i18n.t("Flat")} ({i18n.t("Point")})</option>
                                                                            <option value="Rate">{i18n.t("Rate")} (%)</option>
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="point_amount">{i18n.t("Point Amount")} : </label>
                                                                        <input type="number" id="point_amount" name="point_amount" value={tier.point_amount} onChange={(e) => this.handleChange(e, index)} min="0" required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* TOKEN SETTINGS */}
                                                        {this.props.admin.admin.token && (
                                                            <div className="card" style={{ flex: 1 }}>
                                                                <div className="card-header">
                                                                    <h6 className="m-0 p-0">{i18n.t("Token")}</h6>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="token_eligible" name="token_eligible" checked={tier.token_eligible} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the system will give e-Token to user")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Eligibility")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="token_type">{i18n.t("Token Grant Type")} : </label>
                                                                        <select className="browser-default form-select" id="token_type" name="token_type" value={tier.token_type} onChange={(e) => this.handleChange(e, index)} required>
                                                                            <option value="" disabled>{i18n.t("Select Token Grant Type")}</option>
                                                                            <option value="Flat">{i18n.t("Flat")} ({i18n.t("Token")})</option>
                                                                            <option value="Rate">{i18n.t("Rate")} (%)</option>
                                                                        </select>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label htmlFor="token_amount">{i18n.t("Number of E-Token")} : </label>
                                                                        <input type="number" id="token_amount" name="token_amount" value={tier.token_amount} onChange={(e) => this.handleChange(e, index)} min="0" required />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* VOUCHER SETTINGS */}
                                                    <div className="card mt-3">
                                                        <div className="card-header">
                                                            <h6 className="m-0 p-0">{i18n.t("Voucher")}</h6>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="voucher mb-5">
                                                                <div className="voucher-ball left-ball"></div>
                                                                <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                                                    <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>{i18n.t("DISCOUNT")}</h4>
                                                                </div>
                                                                <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                                                    <div>
                                                                        <h1>{tier.voucher_type === "Flat" ? (`${currency(this.props.admin.admin.country)}${tier.voucher_amount}`) : (`${tier.voucher_amount}%`)}</h1>
                                                                    </div>
                                                                    <div>
                                                                        <h5>{tier.voucher_title}</h5>
                                                                        <h6>{tier.voucher_description}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="voucher-ball right-ball"></div>
                                                            </div>
                                                            <div className="text-center">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-md-3 col-sm-6 form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={tier.voucher_eligible} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the system will give voucher to user")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Eligibility")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6 form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={tier.voucher_expiry} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the voucher will have expired date")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Expiry")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6 form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={tier.voucher_washer_use} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the voucher can apply to washer machine transaction")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Washer Use")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6 form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={tier.voucher_dryer_use} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the voucher can apply to dryer machine transaction")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Dryer Use")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6 form-group">
                                                                        <label>
                                                                            <input type="checkbox" id="voucher_package_use" name="voucher_package_use" checked={tier.voucher_package_use} onChange={(e) => this.handleCheckbox(e, index)} />
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={<Tooltip>{i18n.t("If ticked, the voucher can apply to Drop n Go transaction")}</Tooltip>}
                                                                            >
                                                                                <span>{i18n.t("Package Use")}</span>
                                                                            </OverlayTrigger>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {tier.voucher_expiry && (
                                                                <div className="form-group">
                                                                    <label htmlFor="voucher_duration">{i18n.t("Voucher Lifespan")} : <small> ({i18n.t("days")})</small></label>
                                                                    <input type="number" id="voucher_duration" name="voucher_duration" value={tier.voucher_duration} onChange={(e) => this.handleChange(e, index)} min="0" required />
                                                                </div>
                                                            )}
                                                            <div className="form-group">
                                                                <label htmlFor="voucher_type">{i18n.t("Discount Type")} : </label>
                                                                <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={tier.voucher_type} onChange={(e) => this.handleChange(e, index)} required>
                                                                    <option value="" disabled>{i18n.t("Select a discount type")}</option>
                                                                    <option value="Flat">{i18n.t("Flat")} (- {currency(this.props.admin.admin.country)})</option>
                                                                    <option value="Rate">{i18n.t("Rate")} (- %)</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="voucher_title">{i18n.t("Voucher Title")} : </label>
                                                                <input type="text" id="voucher_title" name="voucher_title" placeholder={i18n.t("Voucher Title")} value={tier.voucher_title} onChange={(e) => this.handleChange(e, index)} required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="voucher_description">{i18n.t("Voucher Description")} : </label>
                                                                <input type="text" id="voucher_description" name="voucher_description" placeholder={i18n.t("Voucher Description")} value={tier.voucher_description} onChange={(e) => this.handleChange(e, index)} required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="voucher_amount">{i18n.t("Voucher Amount")} : <small>{tier.voucher_type === "Flat" ? `(${currency(this.props.admin.admin.country)})` : "(%)"}</small></label>
                                                                <input type="number" id="voucher_amount" name="voucher_amount" value={tier.voucher_amount} onChange={(e) => this.handleChange(e, index)} min="0" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-3 mx-5">
                                        <button type="button" onClick={this.addTier} className="btn big-button form-control">{i18n.t("Add Tier")}</button>
                                    </div>
                                    {this.state.loading ? <Loading /> : (
                                        <div className="mb-5 mx-5">
                                            <input type="submit" value={i18n.t("SUBMIT")} className="btn big-button form-control" />
                                        </div>
                                    )}
                                </form>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableReferral: data => dispatch(enableReferral(data)),
        updateReferral: data => dispatch(updateReferral(data)),
        updateVoucher: data => dispatch(updateVoucher(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSetting);
