import { combineReducers } from 'redux';
import userReducer from './userReducer';
import paymentReducer from './paymentReducer';
import swReducer from './swReducer';
import themeReducer from './themeReducer';
import adminReducer from './adminReducer';
import devReducer from './devReducer';
import assistantReducer from './assistantReducer';
import distributorReducer from './distributorReducer';
import clientReducer from './clientReducer';
import kioskReducer from './kioskReducer';
import staffReducer from './staffReducer';

const rootReducer = combineReducers({
    user : userReducer,
    payment : paymentReducer,
    serviceWorker : swReducer,
    admin : adminReducer,
    theme : themeReducer,
    assistant: assistantReducer,
    dev: devReducer,
    distributor: distributorReducer,
    client: clientReducer,
    kiosk: kioskReducer,
    staff: staffReducer,
});

export default rootReducer;