import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import StorePerformace from "./reports/StorePerformance";
import UserPerformance from "./reports/UserPerformance";
import VoucherRedemption from "./reports/VoucherRedemption";
import UserDetails from "./reports/UserDetails";
import TokenReport from "./reports/TokenReport";
import TokenDetail from "./reports/TokenDetails";
import TokenPurchaseHistory from "./reports/TokenPurchaseHistory";
import SmsReport from "./reports/SmsReport";
import Settings from "./Settings";
import Token from "./Token";
import Referral from "./Referral";
import Customize from "./Customize";
import Faq from "./Faq";
import FaqList from "./FaqList";
import AddFaq from "./AddFaq";
import AddFaqGroup from "./AddFaqGroup";
import EditFaq from "./EditFaq";
import EditFaqGroup from "./EditFaqGroup";
import News from "./News";
import AddNews from "./AddNews";
import EditNews from "./EditNews";
import MemberDetailsReport from "./reports/MemberDetailsReport";
import UserRewards from "./UserReward";
import RewardHistory from "./RewardHistory";
import DiscountCode from "./DiscountCode";
import DiscountCodeReport from "./reports/DiscountCodeReport";
import VoucherCodeGenerator from "./VoucherCodeGenerator";
import VoucherCodeReport from "./reports/VoucherCodeReport";
import UserFloatingTokenReport from "./reports/UserFloatingTokenReport";
import EPaymentReport from "./reports/EPaymentReport";
import UserUsageReport from "./reports/UserUsageReport";
import UserFloatingPointReport from "./reports/FloatingPointReport";
import ReferralRewardHistoryReport from "./reports/ReferralRewardHistoryReport";

const ClientDashboard = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/client/dashboard" component={Dashboard} />
				<Route exact path="/client/outlet/performance" component={StorePerformace} />
				<Route exact path="/client/user/performance" component={UserPerformance} />
				<Route exact path="/client/voucher/redemption" component={VoucherRedemption} />
				<Route exact path="/client/token/report" component={TokenReport} />
				<Route exact path="/client/token/report/detail" component={TokenDetail} />
				<Route exact path="/client/token/report/purchasehistory" component={TokenPurchaseHistory} />
				<Route exact path="/client/user/details" component={UserDetails} />
				<Route exact path="/client/user/details/memberDetails" component={MemberDetailsReport} />
				<Route exact path="/client/giveaway" component={UserRewards} />
				<Route exact path="/client/giveaway/history" component={RewardHistory} />
				<Route exact path="/client/sms/report" component={SmsReport} />
				<Route exact path="/client/voucher/setting" component={Settings} />
				<Route exact path="/client/token/setting" component={Token} />
				<Route exact path="/client/referral" component={Referral} />
				<Route exact path="/client/setting/customize" component={Customize} />
				<Route exact path="/client/faq" component={Faq} />
				<Route exact path="/client/faq/list" component={FaqList} />
				<Route exact path="/client/faq/group/add" component={AddFaqGroup} />
				<Route exact path="/client/faq/add" component={AddFaq} />
				<Route exact path="/client/faq/group/edit" component={EditFaqGroup} />
				<Route exact path="/client/faq/edit" component={EditFaq} />
				<Route exact path="/client/news" component={News} />
				<Route exact path="/client/news/add" component={AddNews} />
				<Route exact path="/client/news/edit" component={EditNews} />
				<Route exact path="/client/discountCode" component={DiscountCode} />
				<Route exact path="/client/discountCode/report" component={DiscountCodeReport} />
				<Route exact path="/client/voucherCode" component={VoucherCodeGenerator} />
				<Route exact path="/client/voucherCode/report" component={VoucherCodeReport} />
				<Route exact path="/client/floatingToken/report" component={UserFloatingTokenReport} />
				<Route exact path="/client/epayment/report" component={EPaymentReport} />
				<Route exact path="/client/userUsage/report" component={UserUsageReport} />
				<Route exact path="/client/floatingPoint/report" component={UserFloatingPointReport} />
				<Route exact path="/client/referralReward/report" component={ReferralRewardHistoryReport} />
				
				<Redirect to="/client/dashboard" />
			</Switch>
		</div>
	);
};

export default ClientDashboard;