import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Select, DatePicker, Button, Row, Col } from "antd";
import dayjs from 'dayjs';

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from '../../admin/components/Loading';
import { dateFormat, timeFormat } from '../../../dynamicController';

const mySwal = withReactContent(Swal);

const VoucherCodeReport = ({ token, distributor }) => {

    const history = useHistory();

    const { t } = useTranslation();

    const [toggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [campaignOption, setCampaignOption] = useState([])
    const [campaignFilter, setCampaignFilter] = useState([]);

    useEffect(() => {
        if (!distributor || !distributor.voucher_code_report) {
            history.push('/distributor')
        } else {
            axios
                .post("/api/admin/report/voucherCodeReport", { distributorId: distributor.distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data);
                    setCampaignOption(res.data.campaign)
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleDate = (dates) => {
        if (dates && dates.length === 2) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    }

    const handleFilter = e => {
        setLoading(true);
        axios
            .post("/api/admin/report/voucherCodeReport", { distributorId: distributor.distributorId, role: "distributor", startDate, endDate, campaignFilter }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    }

    const handleReset = e => {
        setLoading(true);
        setStartDate("");
        setEndDate("");
        setCampaignFilter([]);
        axios
            .post("/api/admin/report/voucherCodeReport", { distributorId: distributor.distributorId, role: "distributor" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    }

    let tableData = {
        columns: [
            {
                label: t("Redemption Date"),
                field: "redemptionDate",
                sort: "asc"
            },
            {
                label: t("Name"),
                field: "description",
                sort: "asc"
            },
            {
                label: t("Redemption Code"),
                field: "redemptionCode",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "memberId",
                sort: "asc"
            },
            {
                label: t("User Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "phoneNumber",
                sort: "asc"
            },
            {
                label: t("Email"),
                field: "email",
                sort: "asc"
            },
        ],
        rows: data
    }

    const csvData = {
        headers: [
            { label: "Redemption Date", key: "redemptionDate" },
            { label: "Name", key: "description" },
            { label: "Redemption Code", key: "redemptionCode" },
            { label: "Member ID", key: "memberId" },
            { label: "User Name", key: "name" },
            { label: "Phone Number", key: "phoneNumber" },
            { label: "Email", key: "email" },
        ],
        data: data
    };

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h5 className="card-title">{t("Voucher Code Report")}</h5>
                        </div>

                        <div className="card-body">
                            <div className="mb-5">
                                <h6>{t("Filter")}</h6>
                                <div>
                                    <label htmlFor="campaignFilter">{t("Select Name")}: </label>
                                    <Select
                                        id="campaignFilter"
                                        mode="multiple"
                                        style={{ width: '100%', marginBottom: '10px' }}
                                        placeholder={t("Select Name")}
                                        value={campaignFilter}
                                        onChange={(value) => setCampaignFilter(value)}
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {
                                            campaignOption.map((data, i) => (
                                                <Select.Option key={i} value={data.id}>
                                                    {data.description}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div>
                                    <label htmlFor="date">{t("Select Redemption Date")}: </label>
                                    <DatePicker.RangePicker
                                        onChange={handleDate}
                                        style={{ width: '100%', marginBottom: '10px' }}
                                        value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
                                        ranges={{
                                            'Today': [dayjs(), dayjs()],
                                            'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                            'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                            'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                            'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                            'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                        }}
                                    />
                                </div>

                                <Row gutter={16} className="mt-3">
                                    <Col span={12}>
                                        <Button type="primary" onClick={handleFilter} className="w-100">
                                            {t("Filter")}
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button onClick={handleReset} className="w-100">
                                            {t("Reset")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="text-center mt-5">
                                {
                                    loading ? <Loading /> : (
                                        <div>
                                            <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`vouchercode_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                    {t("Download Report")}
                                                </CSVLink>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(VoucherCodeReport);