import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currency } from "../../../dynamicController";
import { distributorTokenUpdate, distributorPointUpdate } from "../../../redux/actions/distributorActions";

import i18n from "i18next";
import { Trans } from "react-i18next";

const mySwal = withReactContent(Swal);

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];

class TokenSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            toggled: false,
            token: false,
            data: null,
            epayment: false,
            member_payment: false,
            nonMemberPayment: false,
            non_member_promotion: true,
            showModal: false,
            numberOfToken: 0,
            price: 0,
            voucher_eligible: false,
            voucher_amount: 3,
            voucher_type: "Flat",
            voucher_expiry: true,
            voucher_duration: 60,
            voucher_title: "E-TOKEN RELOAD SUCCESSFUL",
            voucher_description: "Your e-token have reloaded successfully!",
            voucher_washer_use: true,
            voucher_dryer_use: false,
            voucher_package_use: false,
            recurring: false,
            recurring_number: 1,
            recurring_period: 60,
            token_package_id: "",
            purchase_limit: false,
            max_purchase: 1,
            token_point_eligible: false,
            token_point_amount: 0,
            notification: false,
            sms_notification_amount: 0.1,
            customize_payment_method_amount: 50,
            loyalty_point_name: "Point",
            loyalty_point: false,
            point_transaction_amount: 0,
            point_grant_type: "Flat",
            point_grant_amount: 0,
            point_currency: 0,
            point_expiry: true,
            point_duration: 12,
            member_free_sms: false,
            paymentMethod: [
                {
                    type: "rm",
                    payment_method: "WECHATPAY_CN",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "WECHATPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "PRESTO_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "ALIPAY_CN",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "TNG_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "BOOST_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "MAYBANK_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "GRABPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "SHOPEEPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "GOBIZ_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "PAYDEE_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "ZAPP_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "TAPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "MCASH_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "LAZADA_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "FPX_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "SARAWAKPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "FAVEPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "BINFINITE_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "ALIPAYPLUS_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "SENHENGPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "SPLIT_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "IOUPAY_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "ATOME_MY",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "NETSPAY_SG",
                    exclude: false
                },
                {
                    type: "rm",
                    payment_method: "SETEL_MY",
                    exclude: false
                },
            ],
            showPaymentMethodModal: false,
            rm: false
        };
    }

    componentDidMount() {
        if (!this.props.distributor || !this.props.distributor.token_setting) {
            return this.props.history.push('/distributor');
        } else {
            axios.post('/api/distributor/setting/getTokenSettings', { distributorId: this.props.distributor.distributorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {

                    let userMethods = res.data.paymentMethod

                    for (let i = 0; i < this.state.paymentMethod.length; i++) {
                        const currentMethod = userMethods.filter(method => method.type === this.state.paymentMethod[i].type && method.payment_method === this.state.paymentMethod[i].payment_method)[0]
                        if (!currentMethod) {
                            userMethods.push(this.state.paymentMethod[i])
                        }
                    }

                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data,
                        token: res.data.token,
                        epayment: res.data.epayment,
                        member_payment: res.data.member_payment,
                        nonMemberPayment: res.data.nonMemberPayment,
                        non_member_promotion: res.data.non_member_promotion,
                        notification: res.data.notification,
                        sms_notification_amount: res.data.sms_notification_amount,
                        customize_payment_method_amount: res.data.customize_payment_method_amount,
                        paymentMethod: userMethods,
                        rm: res.data.rm,
                        loyalty_point_name: res.data.loyalty_point_name,
                        loyalty_point: res.data.loyalty_point,
                        point_transaction_amount: res.data.point_transaction_amount,
                        point_grant_type: res.data.point_grant_type,
                        point_grant_amount: res.data.point_grant_amount,
                        point_currency: res.data.point_currency,
                        point_expiry: res.data.point_expiry,
                        point_duration: res.data.point_duration,
                        member_free_sms: res.data.member_free_sms
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleCheckbox = e => {
        this.setState({
            ...this.state,
            [e.target.id]: !this.state[e.target.id]
        });
    };

    handlePaymentMethodCheckbox = e => {
        let updateMethod = []
        for (let i = 0; i < this.state.paymentMethod.length; i++) {
            let currentMethod = this.state.paymentMethod[i]
            if (currentMethod.payment_method === e.target.id) {
                currentMethod.exclude = !currentMethod.exclude
                updateMethod.push(currentMethod)
            } else {
                updateMethod.push(currentMethod)
            }
        }

        this.setState({
            ...this.state,
            paymentMethod: updateMethod
        });
    };

    hideModal = () => {
        this.setState({
            ...this.state,
            showModal: false,
            numberOfToken: 0,
            price: 0,
            voucher_eligible: false,
            voucher_amount: 3,
            voucher_type: "Flat",
            voucher_expiry: true,
            voucher_duration: 60,
            voucher_title: "E-TOKEN RELOAD SUCCESSFUL",
            voucher_description: "Your e-token have reloaded successfully!",
            voucher_washer_use: true,
            voucher_dryer_use: false,
            voucher_package_use: false,
            recurring: false,
            recurring_number: 1,
            recurring_period: 60,
            token_package_id: "",
            purchase_limit: false,
            max_purchase: 1,
            token_point_eligible: false,
            token_point_amount: 0,
        })
    }

    handlePayment = e => {
        e.preventDefault();
        if (!this.state.token && !this.state.epayment) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please choose at least one payment method"), "error");
        } else if (!this.state.nonMemberPayment && !this.state.member_payment) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please choose at least one member or non-member payment"), "error");
        } else {
            mySwal.fire({
                title: i18n.t("Confirmation"),
                text: i18n.t("Are you sure you want to change the payment setting?"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: i18n.t("No"),
                confirmButtonText: i18n.t("Yes"),
                cancelButtonColor: "#d33"
            })
                .then(click => {
                    if (click.isConfirmed) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const sendThis = {
                            id: this.props.distributor.distributorId,
                            role: "distributor",
                            token: this.state.token,
                            epayment: this.state.epayment,
                            member_payment: this.state.member_payment,
                            nonMemberPayment: this.state.nonMemberPayment,
                            non_member_promotion: this.state.non_member_promotion,
                            notification: this.state.notification,
                            sms_notification_amount: this.state.sms_notification_amount,
                            member_free_sms: this.state.member_free_sms
                        };

                        axios.post('/api/admin/setting/paymentSetting', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    loading: false,
                                    data: {
                                        ...this.state.data,
                                        token: this.state.token,
                                        epayment: this.state.epayment,
                                        member_payment: this.state.member_payment,
                                        nonMemberPayment: this.state.nonMemberPayment,
                                        non_member_promotion: this.state.non_member_promotion,
                                        notification: this.state.notification,
                                        sms_notification_amount: this.state.sms_notification_amount,
                                        member_free_sms: this.state.member_free_sms
                                    }
                                });

                                this.props.distributorTokenUpdate({ token: this.state.token })
                                mySwal.fire(i18n.t("Success"), i18n.t("The payment setting has updated successfully!"), "success");
                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                            });
                    }
                })
        }
    }

    addPackage = e => {
        e.preventDefault();
        const {
            numberOfToken,
            price,
            voucher_eligible,
            voucher_amount,
            voucher_type,
            voucher_expiry,
            voucher_duration,
            voucher_title,
            voucher_description,
            voucher_washer_use,
            voucher_dryer_use,
            voucher_package_use,
            recurring,
            recurring_number,
            recurring_period,
            purchase_limit,
            max_purchase,
            token_point_eligible,
            token_point_amount,
        } = this.state

        if (parseFloat(numberOfToken) < 0 || parseFloat(price) < 0) {
            mySwal.fire(i18n.t("Invalid input"), i18n.t("The price and token must be more than 0"), "info");
        } else if (voucher_eligible && !voucher_washer_use && !voucher_dryer_use && !voucher_package_use) {
            mySwal.fire(i18n.t("Missing Field"), i18n.t("Must select voucher can be used for washer, dryer or package"), "info");
        } else if (purchase_limit && parseInt(max_purchase) <= 0) {
            mySwal.fire(i18n.t("Invalid input"), i18n.t("Maximum number of purchase must be more than 1"), "info");
        } else if (token_point_eligible && parseInt(token_point_amount) <= 0) {
            mySwal.fire(i18n.t("Invalid input"), i18n.t("Point amount must be more than 1"), "info");
        } else {
            this.setState({
                ...this.state,
                loading: true
            })

            const sendThis = {
                id: this.props.distributor.distributorId,
                role: "distributor",
                price,
                token: numberOfToken,
                voucher_eligible,
                voucher_amount,
                voucher_type,
                voucher_expiry,
                voucher_duration,
                voucher_title,
                voucher_description,
                voucher_washer_use,
                voucher_dryer_use,
                voucher_package_use,
                recurring,
                recurring_number,
                recurring_period,
                purchase_limit,
                max_purchase,
                token_point_eligible,
                token_point_amount,
            }

            axios
                .post("/api/admin/setting/addPackage", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.hideModal();
                    this.setState({
                        ...this.state,
                        loading: false,
                        showModal: false,
                        data: {
                            ...this.state.data,
                            tokenPackages: [...this.state.data.tokenPackages, res.data]
                        }
                    })
                    mySwal.fire(i18n.t("Success"), i18n.t("Added a new Token Bundle Package"), "success")
                })
                .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));

        }
    };

    delPackage = id => {
        mySwal
            .fire({
                title: i18n.t("Confirmation"),
                text: i18n.t("Delete this entry?"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: i18n.t("No"),
                confirmButtonText: i18n.t("Yes")
            })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    axios
                        .delete("/api/admin/setting/delPackage", {
                            headers: {
                                "Content-Type": "application/json",
                                "auth-token": this.props.token
                            },
                            data: { id }
                        })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    tokenPackages: this.state.data.tokenPackages.filter(pkg => pkg.id !== id)
                                }
                            });
                            mySwal.fire(i18n.t("Success"), i18n.t("Bundle deleted successfully!"), "success");
                        })
                        .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
                }
            });
    };

    editPackage = id => {
        const selectedPackage = this.state.data.tokenPackages.filter(tokenPackage => tokenPackage.id === id)[0]
        this.setState({
            ...this.state,
            showModal: true,
            numberOfToken: selectedPackage.token,
            price: selectedPackage.price,
            voucher_eligible: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_eligible : false,
            voucher_amount: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_amount : 3,
            voucher_type: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_type : "Flat",
            voucher_expiry: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_expiry : true,
            voucher_duration: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_duration : 60,
            voucher_title: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_title : "E-TOKEN RELOAD SUCCESSFUL",
            voucher_description: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_description : "Your e-token have reloaded successfully!",
            voucher_washer_use: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_washer_use : true,
            voucher_dryer_use: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_dryer_use : false,
            voucher_package_use: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_package_use : false,
            recurring: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.recurring : false,
            recurring_number: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.recurring_number : 1,
            recurring_period: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.recurring_period : 60,
            purchase_limit: selectedPackage.purchase_limit,
            max_purchase: selectedPackage.max_purchase,
            token_package_id: id,
            token_point_eligible: selectedPackage.point_eligible,
            token_point_amount: selectedPackage.point_amount,
        })
    }

    submitEditVoucher = e => {
        e.preventDefault();
        const {
            token_package_id,
            voucher_eligible,
            voucher_amount,
            voucher_type,
            voucher_expiry,
            voucher_duration,
            voucher_title,
            voucher_description,
            voucher_washer_use,
            voucher_dryer_use,
            voucher_package_use,
            recurring,
            recurring_number,
            recurring_period,
            purchase_limit,
            max_purchase,
            token_point_eligible,
            token_point_amount,
        } = this.state

        if (voucher_eligible && !voucher_washer_use && !voucher_dryer_use && !voucher_package_use) {
            mySwal.fire(i18n.t("Missing Field"), i18n.t("Must select voucher can be used for washer, dryer or package"), "info");
        } else if (purchase_limit && parseInt(max_purchase) <= 0) {
            mySwal.fire(i18n.t("Invalid input"), i18n.t("Maximum number of purchase must be more than 1"), "info");
        } else if (token_point_eligible && parseInt(token_point_amount) <= 0) {
            mySwal.fire(i18n.t("Invalid input"), i18n.t("Point amount must be more than 1"), "info");
        } else {
            this.setState({
                ...this.state,
                loading: true
            })

            const sendThis = {
                id: this.props.distributor.distributorId,
                role: "distributor",
                token_package_id,
                voucher_eligible,
                voucher_amount,
                voucher_type,
                voucher_expiry,
                voucher_duration,
                voucher_title,
                voucher_description,
                voucher_washer_use,
                voucher_dryer_use,
                voucher_package_use,
                recurring,
                recurring_number,
                recurring_period,
                purchase_limit,
                max_purchase,
                token_point_eligible,
                token_point_amount,
            }

            axios
                .post("/api/admin/setting/editReloadVoucher", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.hideModal();
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: {
                            ...this.state.data,
                            tokenPackages: res.data
                        }
                    })
                    mySwal.fire(i18n.t("Success"), i18n.t("Reload voucher setting have edited successfully!"), "success")
                })
                .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
        }
    }

    submitPaymentMethod = e => {
        e.preventDefault();
        const { customize_payment_method_amount, paymentMethod } = this.state

        if (!customize_payment_method_amount || customize_payment_method_amount < 0) {
            mySwal.fire(i18n.t("Error"), i18n.t("Amount must be more than 1"), "error");
        } else {
            this.setState({
                ...this.state,
                loading: true
            })

            const sendThis = {
                id: this.props.distributor.distributorId,
                role: "distributor",
                customize_payment_method_amount,
                paymentMethod
            }

            axios
                .post("/api/admin/setting/paymentMethodSetting", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        showPaymentMethodModal: false
                    })
                    mySwal.fire(i18n.t("Success"), i18n.t("Payment method setting have updated successfully!"), "success")
                })
                .catch(err => mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error"));
        }
    }

    // SEND DATA TO BACKEND FOR POINTS SETTINGS
    handlePointSettings = e => {
        e.preventDefault();
        const {
            loyalty_point_name,
            loyalty_point,
            point_transaction_amount,
            point_grant_type,
            point_grant_amount,
            point_currency,
            point_expiry,
            point_duration
        } = this.state;

        this.setState({ ...this.state, loading: true });

        const sendThis = {
            id: this.props.distributor.distributorId,
            role: "distributor",
            loyalty_point_name,
            loyalty_point,
            point_transaction_amount,
            point_grant_type,
            point_grant_amount,
            point_currency,
            point_expiry,
            point_duration
        };

        axios
            .post("/api/admin/setting/pointSetting", sendThis, {
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": this.props.token
                }
            })
            .then(res => {
                this.setState({ ...this.state, loading: false });
                this.props.distributorPointUpdate({ loyalty_point: this.state.loyalty_point })
                mySwal.fire(i18n.t("Success"), i18n.t("Point settings updated successfully!"), "success");
            })
            .catch(err => {
                this.setState({ ...this.state, loading: false });
                mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
            });
    };

    render() {

        const renderList = () => {
            if (this.state.data.token) {
                if (this.state.data.tokenPackages && this.state.data.tokenPackages.length) {
                    return (
                        <div>
                            <ul className="list-group">
                                {this.state.data.tokenPackages.sort((a, b) => a.price - b.price).map(pkg => {
                                    return (
                                        <li key={pkg.id} className="list-group-item px-5 py-4">
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <h4 className="m-0 p-0">{currency(this.props.distributor.country)} {parseFloat(pkg.price).toFixed(2)}</h4>

                                                    <h3 className="m-0 p-0">{pkg.token} {i18n.t("e-Tokens")}</h3>
                                                </div>

                                                <div className="mt-3">
                                                    <p className="m-0 p-0" style={{ fontSize: "12px" }}>
                                                        {i18n.t("Added on")}: {new Date(pkg.createdAt).toLocaleString()}{" "}
                                                        <small className="ms-2">
                                                            <strong>
                                                                ({" "}
                                                                <span onClick={e => this.delPackage(pkg.id)} style={{ textDecoration: "underline", textDecorationStyle: "dotted", cursor: "pointer" }}>
                                                                    {i18n.t("remove")}
                                                                </span>{" "}
                                                                )
                                                            </strong>
                                                        </small>
                                                        <small className="ms-2">
                                                            <strong>
                                                                ({" "}
                                                                <span onClick={e => this.editPackage(pkg.id)} style={{ textDecoration: "underline", textDecorationStyle: "dotted", cursor: "pointer" }}>
                                                                    {i18n.t("edit")}
                                                                </span>{" "}
                                                                )
                                                            </strong>
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <div className="text-center">
                            <h1> - {i18n.t("NO PACKAGES ON SALES")} - </h1>
                        </div>
                    );
                }
            } else return null;
        };
        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        {
                            this.state.data && !this.state.loading ? (
                                <div>
                                    {/* PAYMENT SETTINGS */}
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <h5 className="card-title">{i18n.t("Payment Settings")}</h5>

                                            <div>
                                                {
                                                    this.state.rm ? (
                                                        <button type="button" className="btn btn-primary" onClick={e => this.setState({ ...this.state, showPaymentMethodModal: true })}>
                                                            {i18n.t("Edit Payment Method")}
                                                        </button>
                                                    ) : null
                                                }
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <form onSubmit={this.handlePayment}>
                                                <p>{i18n.t("Select Payment Method")}</p>
                                                <div className="d-flex">
                                                    <div className="form-group">
                                                        <label>
                                                            <input type="checkbox" id="epayment" name="epayment" checked={this.state.epayment} onChange={this.handleCheckbox} />
                                                            <span>{i18n.t("Enable E-Payment?")}</span>
                                                        </label>
                                                    </div>

                                                    <div className="form-group ms-5">
                                                        <label>
                                                            <input type="checkbox" id="token" name="token" checked={this.state.token} onChange={this.handleCheckbox} />
                                                            <span>{i18n.t("Enable Token Currency?")}</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.epayment ? (
                                                        <div className="form-group mt-3">
                                                            <p>{i18n.t("Member and Non-Member Settings")}</p>

                                                            <div className="d-flex">
                                                                <div className="form-group">
                                                                    <label>
                                                                        <input type="checkbox" id="member_payment" name="member_payment" checked={this.state.member_payment} onChange={this.handleCheckbox} />
                                                                        <span>{i18n.t("Enable Member Payment?")}</span>
                                                                    </label>
                                                                </div>

                                                                <div className="form-group ms-5">
                                                                    <label>
                                                                        <input type="checkbox" id="nonMemberPayment" name="nonMemberPayment" checked={this.state.nonMemberPayment} onChange={this.handleCheckbox} />
                                                                        <span>{i18n.t("Enable Non-Member Payment?")}</span>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <p>
                                                                <small>*
                                                                    {
                                                                        this.state.nonMemberPayment && this.state.member_payment
                                                                            ? i18n.t("User can login or skip to payment without login to make the payment")
                                                                            : !this.state.nonMemberPayment && this.state.member_payment
                                                                                ? i18n.t("User have to login only can make the payment")
                                                                                : this.state.nonMemberPayment && !this.state.member_payment
                                                                                    ? i18n.t("User only can skip to payment without login to make the payment")
                                                                                    : ""
                                                                    }
                                                                </small>
                                                            </p>
                                                        </div>
                                                    ) : null
                                                }

                                                {
                                                    this.state.epayment && this.state.nonMemberPayment ? (
                                                        <div className="form-group mt-5">
                                                            <p>{i18n.t("Promotion Settings")}</p>
                                                            <label>
                                                                <input type="checkbox" id="non_member_promotion" name="non_member_promotion" checked={this.state.non_member_promotion} onChange={this.handleCheckbox} />
                                                                <span>{i18n.t("Enable Non Member Promotion?")}</span>
                                                            </label>
                                                        </div>
                                                    ) : null
                                                }

                                                <div className="form-group mt-5">
                                                    <p>{i18n.t("Notification")}</p>
                                                    <label>
                                                        <input type="checkbox" id="notification" name="notification" checked={this.state.notification} onChange={this.handleCheckbox} />
                                                        <span>{i18n.t("Enable Notification When Cycle Done?")}</span>
                                                    </label>
                                                </div>

                                                <div className="form-group mt-5">
                                                    <p>{i18n.t("SMS Settings")}</p>
                                                    <label>
                                                        <input type="checkbox" id="member_free_sms" name="member_free_sms" checked={this.state.member_free_sms} onChange={this.handleCheckbox} />
                                                        <span>{i18n.t("Enable Free Sms For Members")}</span>
                                                    </label>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="sms_notification_amount">{i18n.t("SMS charges")}: </label>
                                                    <input type="number" id="sms_notification_amount" name="sms_notification_amount" value={this.state.sms_notification_amount} onChange={this.handleChange} min="0" step="0.01" required />
                                                </div>

                                                <div>
                                                    <p><small>* {i18n.t("If enable notification, user can choose either receive email or sms when complete washing or drying")}</small></p>
                                                    {
                                                        this.state.member_free_sms ? (
                                                            <p>
                                                                <small>*
                                                                    <Trans i18nKey="If user choose sms, non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms">
                                                                        If user choose sms, non member have to pay extra {{ currency: currency(this.props.distributor.country) }}{{ sms_notification_amount: parseFloat(this.state.sms_notification_amount).toFixed(2) }} for the sms
                                                                    </Trans>
                                                                </small>
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                <small>*
                                                                    <Trans i18nKey="If user choose sms, member and non member have to pay extra {{ currency }}{{ sms_notification_amount }} for the sms">
                                                                        If user choose sms, member and non member have to pay extra {{ currency: currency(this.props.distributor.country) }}{{ sms_notification_amount: parseFloat(this.state.sms_notification_amount).toFixed(2) }} for the sms
                                                                    </Trans>
                                                                </small>
                                                            </p>
                                                        )
                                                    }
                                                </div>

                                                <div className="text-center">
                                                    {
                                                        this.state.loading
                                                            ? (
                                                                <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            )
                                                            : <input type="submit" value={i18n.t("SUBMIT")} className="btn btn-block btn-warning big-button" />
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {/* POINT SETTING */}
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between">
                                            <h5 className="card-title">{i18n.t("Point Settings")}</h5>
                                        </div>

                                        <div className="card-body">
                                            <form onSubmit={this.handlePointSettings}>
                                                {/* ENABLE LOYALTY POINTS */}
                                                <div className="form-group">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="loyalty_point"
                                                            name="loyalty_point"
                                                            checked={this.state.loyalty_point}
                                                            onChange={this.handleCheckbox}
                                                        />
                                                        <span>{i18n.t("Enable Loyalty Point")}</span>
                                                    </label>
                                                </div>

                                                {/* POINT NAME */}
                                                <div className="form-group">
                                                    <label htmlFor="loyalty_point_name">{i18n.t("Point Name")} : </label>
                                                    <input
                                                        type="text"
                                                        id="loyalty_point_name"
                                                        name="loyalty_point_name"
                                                        value={this.state.loyalty_point_name}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>

                                                {/* POINT CURRENCY */}
                                                <div className="form-group">
                                                    <label htmlFor="point_currency">{i18n.t("Number Of Point Equals To")} {currency(this.props.distributor.country)}1 :</label>
                                                    <input
                                                        type="number"
                                                        id="point_currency"
                                                        name="point_currency"
                                                        value={this.state.point_currency}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        step="any"
                                                        required
                                                    />
                                                </div>

                                                {/* POINT EXPIRY */}
                                                <div className="form-group">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            id="point_expiry"
                                                            name="point_expiry"
                                                            checked={this.state.point_expiry}
                                                            onChange={this.handleCheckbox}
                                                        />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {i18n.t("If ticked, the point will have expired date")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>{i18n.t("Expiry")}</span>
                                                        </OverlayTrigger>
                                                    </label>
                                                </div>

                                                {/* POINT DURATION */}
                                                {
                                                    this.state.point_expiry ? (
                                                        <div className="form-group">
                                                            <label htmlFor="point_duration">{i18n.t("Point Lifespan")} : <small> ({i18n.t("months")})</small></label>
                                                            <input
                                                                type="number"
                                                                id="point_duration"
                                                                name="point_duration"
                                                                value={this.state.point_duration}
                                                                onChange={this.handleChange}
                                                                className="form-control"
                                                                min="1"
                                                                step="1"
                                                                required
                                                            />
                                                        </div>
                                                    ) : null
                                                }

                                                {/* POINT TRANSACTION AMOUNT */}
                                                <div className="form-group">
                                                    <label htmlFor="point_transaction_amount">{i18n.t("Minimum Transaction Amount To Get Point")} : <small> ({currency(this.props.distributor.country)})</small></label>
                                                    <input
                                                        type="number"
                                                        id="point_transaction_amount"
                                                        name="point_transaction_amount"
                                                        value={this.state.point_transaction_amount}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        min="0"
                                                        step="0.01"
                                                        required
                                                    />
                                                </div>

                                                {/* POINT TYPE */}
                                                <div className="form-group">
                                                    <label htmlFor="point_grant_type">{i18n.t("Point Grant Type")} : </label>
                                                    <select
                                                        id="point_grant_type"
                                                        name="point_grant_type"
                                                        value={this.state.point_grant_type}
                                                        onChange={this.handleChange}
                                                        className="form-select"
                                                        required
                                                    >
                                                        <option value="" disabled>{i18n.t("Select Point Grant Type")}</option>
                                                        <option value="Flat">{i18n.t("Flat")} ({i18n.t("Point")})</option>
                                                        <option value="Rate">{i18n.t("Rate")} (%)</option>
                                                    </select>
                                                </div>

                                                {/* POINT GRANT AMOUNT */}
                                                <div className="form-group">
                                                    <label htmlFor="point_grant_amount">{i18n.t("Point Grant Amount")}</label>
                                                    <input
                                                        type="number"
                                                        id="point_grant_amount"
                                                        name="point_grant_amount"
                                                        value={this.state.point_grant_amount}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        min="0"
                                                        step="0.01"
                                                        required
                                                    />
                                                </div>

                                                {/* SUBMIT BUTTON */}
                                                <div className="text-center pt-3">
                                                    {this.state.loading ? (
                                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        <input
                                                            type="submit"
                                                            value={i18n.t("Submit")}
                                                            className="btn btn-block btn-warning big-button"
                                                        />
                                                    )}
                                                </div>

                                            </form>
                                        </div>
                                    </div>

                                    {/* TOKEN SETTINGS */}
                                    {this.state.data && this.state.data.token ? (
                                        <div className="card">
                                            <div className="card-header d-flex justify-content-between">
                                                <h5 className="card-title">{i18n.t("Token Settings")}</h5>

                                                <div>
                                                    <button type="button" className="btn btn-primary" onClick={e => this.setState({ ...this.state, showModal: true })}>
                                                        {i18n.t("Add Package")}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="card-body">{renderList()}</div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : this.state.loading ? <Loading />
                                : null
                        }

                    </div>
                </div>
                <Modal
                    show={this.state.showModal}
                    onHide={e => this.hideModal()}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.token_package_id ? i18n.t("Edit e-Token Package") : i18n.t("Add e-Token Package")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form onSubmit={this.state.token_package_id ? this.submitEditVoucher : this.addPackage}>
                                <p><small><strong>* {i18n.t("Please only input numberic value to prevent any bugs.")}</strong></small></p>
                                {
                                    this.state.token_package_id ? (
                                        <div>
                                            <div className="form-group text-left">
                                                <label htmlFor="price">{i18n.t("Price")} ({currency(this.props.distributor.country)})</label>
                                                <input type="number" id="price" name="price" value={this.state.price} className="form-control browser-default" min="1" step="0.01" required readOnly />
                                            </div>

                                            <div className="form-group text-left">
                                                <label htmlFor="numberOfToken">{i18n.t("Number of e-Token")}</label>
                                                <input type="number" id="numberOfToken" name="numberOfToken" value={this.state.numberOfToken} className="form-control browser-default" min="1" required readOnly />
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="form-group text-left">
                                                <label htmlFor="price">{i18n.t("Price")} ({currency(this.props.distributor.country)})</label>
                                                <input type="number" id="price" name="price" value={this.state.price} className="form-control browser-default" min="1" step="0.01" onChange={this.handleChange} required />
                                            </div>

                                            <div className="form-group text-left">
                                                <label htmlFor="numberOfToken">{i18n.t("Number of e-Token")}</label>
                                                <input type="number" id="numberOfToken" name="numberOfToken" value={this.state.numberOfToken} className="form-control browser-default" min="1" onChange={this.handleChange} required />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="form-group">
                                    <label htmlFor="purchase_limit">
                                        <input type="checkbox" id="purchase_limit" name="purchase_limit" checked={this.state.purchase_limit} onChange={this.handleCheckbox} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    {i18n.t("If ticked, the system will limit the number of purchase")}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{i18n.t("Limit Number of Purchase")}</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>

                                {
                                    this.state.purchase_limit ? (
                                        <div className="form-group text-left">
                                            <label htmlFor="max_purchase">{i18n.t("Number of maximum purchase")}</label>
                                            <input type="number" id="max_purchase" name="max_purchase" value={this.state.max_purchase} className="form-control browser-default" min="1" step="1" onChange={this.handleChange} required />
                                        </div>
                                    ) : null
                                }

                                {
                                    this.props.distributor?.loyalty_point ? (
                                        <div className="form-group">
                                            <label htmlFor="token_point_eligible">
                                                <input type="checkbox" id="token_point_eligible" name="token_point_eligible" checked={this.state.token_point_eligible} onChange={this.handleCheckbox} />
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {i18n.t("If ticked, the system will give point to user when user reload token successfully")}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span>{i18n.t("Point Eligibility")}</span>
                                                </OverlayTrigger>
                                            </label>
                                        </div>
                                    ) : null
                                }

                                {
                                    this.props.distributor?.loyalty_point && this.state.token_point_eligible ? (
                                        <div className="form-group text-left">
                                            <label htmlFor="token_point_amount">{i18n.t("Point Amount")}</label>
                                            <input type="number" id="token_point_amount" name="token_point_amount" value={this.state.token_point_amount} className="form-control browser-default" min="1" step="1" onChange={this.handleChange} required />
                                        </div>
                                    ) : null
                                }

                                <div className="card">
                                    <div className="card-header">
                                        <h6 className="m-0 p-0">{i18n.t("Reload Voucher")}</h6>
                                    </div>

                                    <div className="card-body">
                                        <div>
                                            <div>
                                                {/* voucher preview */}
                                                <div className="voucher mb-5">
                                                    <div className="voucher-ball left-ball"></div>
                                                    <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                                        <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>{i18n.t("DISCOUNT")}</h4>
                                                    </div>

                                                    <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                                        <div>
                                                            <h1>{this.state.voucher_type === "Flat" ? (`${currency(this.props.distributor.country)}${this.state.voucher_amount}`) : (`${this.state.voucher_amount}%`)}</h1>
                                                        </div>

                                                        <div>
                                                            <h5>{this.state.voucher_title}</h5>
                                                            <h6>{this.state.voucher_description}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="voucher-ball right-ball"></div>
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                {/* checkboxes */}
                                                <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <div className="form-group">
                                                        <label htmlFor="voucher_eligible">
                                                            <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={this.state.voucher_eligible} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {i18n.t("If ticked, the system will give voucher to user when user reload token successfully")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{i18n.t("Eligibility")}</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="voucher_expiry">
                                                            <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={this.state.voucher_expiry} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {i18n.t("If ticked, the voucher will have expired date")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{i18n.t("Expiry")}</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="recurring">
                                                            <input type="checkbox" id="recurring" name="recurring" checked={this.state.recurring} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {i18n.t("If ticked, the system will give voucher repeatly")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{i18n.t("Recurring")}</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                    <div className="form-group">
                                                        <label>
                                                            <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={this.state.voucher_washer_use} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {i18n.t("If ticked, the voucher can apply to washer machine transaction")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{i18n.t("Washer Use")}</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={this.state.voucher_dryer_use} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {i18n.t("If ticked, the voucher can apply to dryer machine transaction")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{i18n.t("Dryer Use")}</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            <input type="checkbox" id="voucher_package_use" name="voucher_package_use" checked={this.state.voucher_package_use} onChange={this.handleCheckbox} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {i18n.t("If ticked, the voucher can apply to Drop n Go transaction")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>{i18n.t("Package Use")}</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                {/* text and select */}
                                                {
                                                    this.state.voucher_expiry
                                                        ? (
                                                            <div className="form-group">
                                                                <label htmlFor="voucher_duration">{i18n.t("Voucher Lifespan")} : <small> ({i18n.t("days")})</small></label>
                                                                <input type="number" id="voucher_duration" name="voucher_duration" value={this.state.voucher_duration} onChange={this.handleChange} min="1" step="1" required />
                                                            </div>
                                                        )
                                                        : (
                                                            null
                                                        )
                                                }

                                                {
                                                    this.state.recurring ? (
                                                        <div>
                                                            <div className="form-group">
                                                                <label htmlFor="recurring_number">{i18n.t("Total number of voucher given to user")} : </label>
                                                                <input type="number" id="recurring_number" name="recurring_number" value={this.state.recurring_number} onChange={this.handleChange} min="1" step="1" required />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="recurring_period">{i18n.t("Period for giving next voucher")} : <small> ({i18n.t("days")})</small></label>
                                                                <input type="number" id="recurring_period" name="recurring_period" value={this.state.recurring_period} onChange={this.handleChange} min="1" step="1" required />
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="voucher_type">{i18n.t("Discount Type")} : </label>
                                                    <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={this.state.voucher_type} onChange={this.handleChange} required>
                                                        <option value="" disabled>{i18n.t("Select a discount type")}</option>
                                                        <option value="Flat">{i18n.t("Flat")} (- {currency(this.props.distributor.country)})</option>
                                                        <option value="Rate">{i18n.t("Rate")} (- %)</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="voucher_title">{i18n.t("Voucher Title")} : </label>
                                                    <input type="text" id="voucher_title" name="voucher_title" placeholder={i18n.t("Voucher Title")} value={this.state.voucher_title} onChange={this.handleChange} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="voucher_description">{i18n.t("Voucher Description")} : </label>
                                                    <input type="text" id="voucher_description" name="voucher_description" placeholder={i18n.t("Voucher Description")} value={this.state.voucher_description} onChange={this.handleChange} required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="voucher_amount">{i18n.t("Voucher Amount")} : <small>{this.state.voucher_type === "Flat" ? `(${currency(this.props.distributor.country)})` : "(%)"}</small></label>
                                                    <input type="number" id="voucher_amount" name="voucher_amount" value={this.state.voucher_amount} onChange={this.handleChange} min="0" step="0.01" required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="p-0 m-0">
                                    <small>*
                                        <Trans i18nKey="Token package {{ currency }}{{ price }} for {{ numberOfToken }} tokens" count={parseFloat(this.state.numberOfToken)}>
                                            Token package {{ currency: currency(this.props.distributor.country) }}{{ price: this.state.price }} for {{ numberOfToken: this.state.numberOfToken }} tokens
                                        </Trans>
                                    </small>
                                </p>
                                {
                                    this.state.purchase_limit
                                        ? <p className="p-0 m-0">
                                            <small>*
                                                <Trans i18nKey="Each user only can purchase {{ max_purchase }} times for the token package">
                                                    Each user only can purchase {{ max_purchase: this.state.max_purchase }} times for the token package
                                                </Trans>
                                            </small>
                                        </p>
                                        : null
                                }
                                {
                                    this.state.token_point_eligible && this.props.distributor?.loyalty_point
                                        ? <p className="p-0 m-0">
                                            <small>*
                                                <Trans i18nKey="{{ point_amount }} point will be given after user reload token successfully">
                                                    {{ point_amount: this.state.token_point_amount }} point will be given after user reload token successfully
                                                </Trans>
                                            </small>
                                        </p>
                                        : null
                                }
                                {this.state.voucher_eligible ? <p className="p-0 m-0"><small>* {i18n.t("Voucher will be given after user reload token successfully")}</small></p> : <p className="p-0 m-0"><small>* {i18n.t("Voucher will not given after user reload tokens successfully")}</small></p>}
                                {
                                    this.state.voucher_eligible && this.state.voucher_expiry
                                        ? <p className="p-0 m-0">
                                            <small>*
                                                <Trans i18nKey="The voucher will be expired after {{ voucher_duration }} days" count={parseFloat(this.state.voucher_duration)}>
                                                    The voucher will be expired after {{ voucher_duration: this.state.voucher_duration }} days
                                                </Trans>
                                            </small>
                                        </p>
                                        : null
                                }
                                {this.state.voucher_eligible && !this.state.voucher_expiry ? <p className="p-0 m-0"><small>* {i18n.t("The voucher will not expired")}</small></p> : null}
                                {this.state.voucher_eligible && this.state.voucher_washer_use && this.state.voucher_dryer_use && this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}</small></p> : null}
                                {this.state.voucher_eligible && this.state.voucher_washer_use && this.state.voucher_dryer_use && !this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for dryer or washer machine.")}</small></p> : null}
                                {this.state.voucher_eligible && this.state.voucher_washer_use && !this.state.voucher_dryer_use && this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}</small></p> : null}
                                {this.state.voucher_eligible && !this.state.voucher_washer_use && this.state.voucher_dryer_use && this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}</small></p> : null}
                                {this.state.voucher_eligible && !this.state.voucher_washer_use && this.state.voucher_dryer_use && !this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for dryer machine only.")}</small></p> : null}
                                {this.state.voucher_eligible && this.state.voucher_washer_use && !this.state.voucher_dryer_use && !this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for washer machine only.")}</small></p> : null}
                                {this.state.voucher_eligible && !this.state.voucher_washer_use && !this.state.voucher_dryer_use && this.state.voucher_package_use ? <p className="p-0 m-0"><small>* {i18n.t("The voucher can be used for Drop n Go transaction only.")}</small></p> : null}
                                {
                                    this.state.voucher_eligible && this.state.recurring
                                        ? <p className="p-0 m-0">
                                            <small>*
                                                <Trans i18nKey="Total {{ recurring_number }} vouchers will be given to user and each voucher will discount" count={parseFloat(this.state.recurring_number)}>
                                                    Total {{ recurring_number: this.state.recurring_number }} vouchers will be given to user and each voucher will discount
                                                </Trans> {this.state.voucher_type === "Flat" ? `${currency(this.props.distributor.country)}${this.state.voucher_amount}` : `${this.state.voucher_amount}%`}
                                            </small>
                                        </p>
                                        : null
                                }

                                <div className="text-center pt-3">
                                    {this.state.loading ? (
                                        <div className="text-center">
                                            <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : <input type="submit" value={this.state.token_package_id ? i18n.t("Edit Package") : i18n.t("Add Package")} className="btn btn-primary mx-2" />}
                                    <button type="button" className="btn btn-secondary" onClick={e => this.hideModal()}>
                                        {i18n.t("Close")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.showPaymentMethodModal}
                    onHide={e => this.setState({ ...this.state, showPaymentMethodModal: false })}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{i18n.t("Edit Payment Method")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <form onSubmit={this.submitPaymentMethod}>
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="customize_payment_method_amount">{i18n.t("Minimum payment amount to include all the payment methods")} : ({currency(this.props.distributor.country)})</label>
                                        <input type="number" id="customize_payment_method_amount" name="customize_payment_method_amount" value={this.state.customize_payment_method_amount} onChange={this.handleChange} min="0" step="any" required />
                                    </div>

                                    <div>
                                        <p>Exclude Payment Method in Revenue Monster</p>
                                        {
                                            this.state.paymentMethod.map((method, i) => {
                                                return (
                                                    <div className="form-group" key={i}>
                                                        <label>
                                                            <input type="checkbox" id={method.payment_method} name={method.payment_method} checked={method.exclude} onChange={this.handlePaymentMethodCheckbox} />
                                                            <span>{method.payment_method}</span>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="text-center pt-3">
                                    {this.state.loading ? (
                                        <div className="text-center">
                                            <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : <input type="submit" value={i18n.t("Edit Payment Method")} className="btn btn-primary mx-2" />}
                                    <button type="button" className="btn btn-secondary" onClick={e => this.setState({ ...this.state, showPaymentMethodModal: false })}>
                                        {i18n.t("Close")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        distributorTokenUpdate: data => dispatch(distributorTokenUpdate(data)),
        distributorPointUpdate: data => dispatch(distributorPointUpdate(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSetting);
