import {
	IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, ADMIN_LOGIN, UPDATE_THEME, UPDATE_VOUCHER, UPDATE_MECHANIC, ADD_PACKAGE, DEL_PACKAGE, ENABLE_REFERRAL,
	UPDATE_REFERRAL, UPDATE_PAYMENT_SETTING, UPDATE_REGISTER_VOUCHER,
	UPDATE_BIRTHDAY_VOUCHER, UPDATE_RELOAD_VOUCHER, SAVE_ADMIN_REDIRECT_URL, SAVE_ADMIN_USER_TYPE, UPDATE_PAYMENT_METHOD_SETTING, UPDATE_POINT_SETTINGS,
} from "../actions/type";

const initState = {
	admin: null,
	token: localStorage.getItem("laundry-admin"),
	isAuthenticated: false,
	isLoading: false,
	error: null,
	redirectUrl: localStorage.getItem("laundry-admin-redirect-url"),
	userType: localStorage.getItem("laundry-admin-user-type")
};

const adminReducer = (state = initState, action) => {
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};
		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};
		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};
		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};
		case REMOVE_TOKEN:
			localStorage.removeItem("laundry-admin");
			localStorage.removeItem("laundry-admin-redirect-url");
			localStorage.removeItem("laundry-admin-user-type");
			return {
				...state,
				token: null,
				isAuthenticated: false,
				admin: null,
				redirectUrl: null,
				userType: null
			};
		case ADMIN_LOGIN:
			localStorage.setItem("laundry-admin", action.payload.token);
			return {
				...state,
				admin: action.payload.admin,
				token: action.payload.token,
				isAuthenticated: true
			};
		case SAVE_ADMIN_REDIRECT_URL:
			localStorage.setItem("laundry-admin-redirect-url", action.payload);
			return {
				...state,
				redirectUrl: action.payload
			};
		case SAVE_ADMIN_USER_TYPE:
			localStorage.setItem("laundry-admin-user-type", action.payload);
			return {
				...state,
				userType: action.payload
			};
		case UPDATE_THEME:
			localStorage.setItem("theme", action.payload.className);
			return {
				...state,
				admin: {
					...state.admin,
					theme: action.payload
				}
			};

		case UPDATE_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					setting: action.payload
				}
			};

		case UPDATE_MECHANIC:
			return {
				...state,
				admin: {
					...state.admin,
					combined: action.payload.combined,
					specificStamp: action.payload.specificStamp,
					stampTransactionAmount: action.payload.stampTransactionAmount
				}
			};

		case UPDATE_PAYMENT_SETTING:
			return {
				...state,
				admin: {
					...state.admin,
					token: action.payload.token,
					epayment: action.payload.epayment,
					member_payment: action.payload.member_payment,
					nonMemberPayment: action.payload.nonMemberPayment,
					non_member_promotion: action.payload.non_member_promotion,
					notification: action.payload.notification,
					sms_notification_amount: action.payload.sms_notification_amount,
					member_free_sms: action.payload.member_free_sms
				}
			};

		case UPDATE_PAYMENT_METHOD_SETTING:
			return {
				...state,
				admin: {
					...state.admin,
					customize_payment_method_amount: action.payload.customize_payment_method_amount,
					paymentMethod: action.payload.paymentMethod
				}
			}

		case ADD_PACKAGE:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: [...state.admin.tokenPackages, action.payload]
				}
			};

		case DEL_PACKAGE:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: state.admin.tokenPackages.filter(pkg => pkg.id !== action.payload)
				}
			};

		case ENABLE_REFERRAL:
			return {
				...state,
				admin: {
					...state.admin,
					referral: action.payload
				}
			};

		case UPDATE_REFERRAL:
			return {
				...state,
				admin: {
					...state.admin,
					referralSetting: action.payload
				}
			};

		case UPDATE_REGISTER_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					payAndGetRegVoucher: action.payload.payAndGetRegVoucher,
					regVoucherAmount: action.payload.regVoucherAmount,
					reg_voucher_method: action.payload.reg_voucher_method
				}
			};

		case UPDATE_BIRTHDAY_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					payAndGetBirthdayVoucher: action.payload.payAndGetBirthdayVoucher,
					birthdayVoucherAmount: action.payload.birthdayVoucherAmount
				}
			};

		case UPDATE_RELOAD_VOUCHER:
			return {
				...state,
				admin: {
					...state.admin,
					tokenPackages: action.payload
				}
			};

		case UPDATE_POINT_SETTINGS:
			return {
				...state,
				admin: {
					...state.admin,
					loyalty_point: action.payload.loyalty_point,
					loyalty_point_name: action.payload.loyalty_point_name,
					point_transaction_amount: action.payload.point_transaction_amount,
					point_grant_type: action.payload.point_grant_type,
					point_grant_amount: action.payload.point_grant_amount,
					point_currency: action.payload.point_currency,
					point_expiry: action.payload.point_expiry,
					point_duration: action.payload.point_duration,
				}
			};
	
		default:
			return state;
	}
};

export default adminReducer;
