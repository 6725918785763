import React, { useState } from "react";

import axios from "axios";
import { connect } from "react-redux";
import { loginUser, changePage } from "../../../redux/actions/userActions";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";

import { useTranslation } from "react-i18next";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Login = ({ isLoading, error, login, change, query, storedId }) => {
  const [show, setShow] = useState(false);
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");

  let operatorId = queryString.parse(query).operatorCode || storedId;

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (operatorId) {

      // washstudio hardcode outlet
      if (operatorId === "rp1399v8xhob5mektsfzy796") {
        operatorId = "644b4c0584769f297963e0f5"
      }

      login({ number, password, operatorId });
      setNumber("");
      setPassword("");
    } else {
      mySwal.fire(t("Info"), t("Please scan QRCode to Login"), "info");
    }
  };

  const handleReset = (e) => {
    mySwal
      .fire({
        title: t("Forgot Password"),
        html: t("Seems like you have forgotten your password<br />Create a temporary password?"),
        icon: "question",
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
        confirmButtonText: t("Confirm"),
        confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
        customClass: {
          confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
          cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
        }
      })
      .then((click) => {
        if (click.isConfirmed && click.value) {
          mySwal
            .fire({
              title: t("Reset Your Password"),
              html: t("Enter Your Phone Number / Email Address"),
              icon: "warning",
              input: "text",
              showCancelButton: true,
              cancelButtonText: t("Cancel"),
              confirmButtonText: t("Submit"),
              confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
              customClass: {
                confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
                cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
              },
              showLoaderOnConfirm: true,
              inputValidator: (val) => {
                if (!val) {
                  return t("Please enter phone number / email address");
                }
              },
              preConfirm: async (data) => {
                let operatorId = queryString.parse(query).operatorCode || storedId;
                const number = data;

                // washstudio hardcode outlet
                if (operatorId === "rp1399v8xhob5mektsfzy796") {
                  operatorId = "644b4c0584769f297963e0f5"
                }
                const sendThis = {
                  number,
                  operatorId,
                };

                try {
                  const res = await axios.post(
                    "/api/user/setting/resetPassword",
                    sendThis
                  );
                  if (res.data.status === "success") {
                    return true;
                  }
                } catch (err) {
                  return mySwal.showValidationMessage(err.response.data.error);
                }
              },
              allowOutsideClick: () => !Swal.isLoading(),
            })
            .then((click) => {
              if (click.isConfirmed && click.value)
                mySwal.fire(
                  t("Success!"),
                  t("You may login using your one time password now."),
                  "success"
                );
            });
        }
      });
  };

  return (
    <div className="px-5 mt-5">
      {/* <div className="text-center mb-4">
                <h3 className="font-weight-bold">LOGIN</h3>
            </div> */}

      <form onSubmit={handleSubmit} style={{ marginTop: "50px" }}>
        <div className="input-field col">
          <input
            type="text"
            id="number"
            name="number"
            className="form-control form-input"
            onChange={(e) => setNumber(e.target.value.toLowerCase())}
            value={number}
            placeholder={STORETYPE === "cleanpro" ? t("Enter your e-mail/mobile number") : ""}
            required
          />
          <span className="form-prepend">
            <i className="bi bi-person-fill"></i>
          </span>
          {
            STORETYPE !== "cleanpro" ? (
              <label className="form-label" htmlFor="number">
                {t("Phone Number")} / {t("Email")}
              </label>
            ) : null
          }
        </div>

        <div className="input-field col">
          <input
            type={show ? "text" : "password"}
            id="password"
            name="password"
            className="form-control form-input"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder={STORETYPE === "cleanpro" ? t("Password") : ""}
            required
          />
          <span onClick={() => setShow(!show)} className="form-prepend">
            <i className={show ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i>
          </span>
          {
            STORETYPE !== "cleanpro" ? (
              <label className="form-label" htmlFor="password">
                {t("Password")}
              </label>
            ) : null
          }
        </div>

        {error ? (
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50% , -10px)",
            }}
            className="text-center m-0 p-0"
            onClick={() =>
              error === "Click here to register"
                ? change("register")
                : console.log("nothing here")
            }
          >
            <p
              style={{
                color: "red",
                textDecoration:
                  error === "Click here to register" ? "underline" : "none",
              }}
              className="m-0 p-0"
            >
              <small>
                <strong>{error}</strong>
              </small>
            </p>
          </div>
        ) : null}

        <div className="mb-1 px-4 mt-5">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <input
              id="login"
              type="submit"
              value={STORETYPE === "cleanpro" ? t("Sign in") : t("LOGIN")}
              className="big-button form-control btn"
            />
          )}
        </div>
      </form>

      {
        STORETYPE === "cleanpro" ? (
          <div className="text-center mt-4">
            <p style={{ color: "black" }}>{t("Don't have an account?")} <span style={{ color: "#004FC1" }} onClick={() => change("register")}>{t("Sign up now")}</span></p>
            <p className="mt-3" style={{ color: "#004FC1" }} onClick={handleReset}>{t("Forgot password?")}</p>
          </div>
        ) : (
          <div className="text-center mt-3 p-0">
            <div className="row">
              <div className="col p-0 m-0" /*style={{border : "1px red solid"}}*/>
                <p
                  className="m-0 p-0"
                  id="dont-have-account"
                  onClick={() => change("register")}
                  style={{ fontSize: "12px", fontWeight: 500, color: "white" }}
                >
                  {t("Don't have an account?")}
                </p>
              </div>
              <div
                className="col-1 p-0 m-0"
                style={{ color: "white" }}
                id="login-line"
              >
                |
              </div>
              <div className="col p-0 m-0">
                <p
                  className="m-0 p-0"
                  id="forget-password"
                  onClick={handleReset}
                  style={{ fontSize: "12px", fontWeight: 500, color: "white" }}
                >
                  {t("Forgot password?")}
                </p>
              </div>
            </div>
          </div>
        )
      }

      {STORETYPE === "cleanpro" && (
        <p style={{ fontSize: "12px", color: "grey" }} className="mt-3 link-text">
          {t("Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.")}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.user.isLoading,
    error: state.user.error,
    storedId: state.user.operatorId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(loginUser(data)),
    change: (page) => dispatch(changePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
