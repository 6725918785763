import {
    DISTRIBUTOR_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN,
    DISTRIBUTOR_TOKEN_UPDATE, UPDATE_THEME, SAVE_DISTRIBUTOR_REDIRECT_URL,
    SAVE_DISTRIBUTOR_USER_TYPE,
    DISTRIBUTOR_POINT_UPDATE
} from './type';
import axios from 'axios';

export const distributorLogin = data => dispatch => {
    dispatch({ type: IS_LOADING });
    axios.post('/api/distributor/loginDistributor', data)
        .then(res => {
            dispatch({ type: DISTRIBUTOR_LOGIN, payload: res.data.data });
            dispatch({ type: CLEAR_ERROR });
            dispatch({ type: IS_LOADED });
        })
        .catch(err => {
            dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
            dispatch({ type: IS_LOADED });
        });
};

export const checkToken = () => (dispatch, getState) => {
    if (getState().distributor.token) {
        axios.post('/api/distributor/checkTokenDistributor', { token: getState().distributor.token })
            .then(res => dispatch({ type: DISTRIBUTOR_LOGIN, payload: res.data.data }))
            .catch(err => {
                console.log(err);
                dispatch({ type: REMOVE_TOKEN });
            });
    }
};
export const distributorTokenLogin = token => dispatch => {
    axios
        .post("/api/distributor/tokenLogin", { token })
        .then(res => dispatch({ type: DISTRIBUTOR_LOGIN, payload: res.data.data }))
        .catch(err => {
            console.log(err);
            dispatch({ type: REMOVE_TOKEN });
        });
};

export const saveDistributorRedirectUrl = url => {
	return {
		type: SAVE_DISTRIBUTOR_REDIRECT_URL,
		payload: url
	};
};

export const saveDistributorUserType = data => {
	return {
		type: SAVE_DISTRIBUTOR_USER_TYPE,
		payload: data
	};
};

export const distributorLogout = () => {
    return {
        type: REMOVE_TOKEN
    };
};

export const distributorTokenUpdate = data => {
	return {
		type: DISTRIBUTOR_TOKEN_UPDATE,
		payload: data
	};
};

export const distributorPointUpdate = data => {
	return {
		type: DISTRIBUTOR_POINT_UPDATE,
		payload: data
	};
};

export const updateTheme = theme => {
	return {
		type: UPDATE_THEME,
		payload: theme
	};
};
