import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LaundroHeader = ({ user, setToggled }) => {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div id="user-header">
            <h5>
                <i onClick={e => setToggled(true)} className="bi bi-list me-3"></i> <strong>{user.stamp ? t("Stamping Collection") : t("Home")}</strong>
            </h5>
            <div id="user-info-container">
                <div id="user-info-flex">
                    {!user.tokenSetting && !user.loyalty_point ? (
                        <div
                            id="user-pp"
                            onClick={() => history.push("/user/setting")}
                        >
                            <div id="profile-picture">
                                {/* image */}
                                {user && user.profilePicture ? (
                                    <img
                                        src={user ? user.profilePicture : ""}
                                        alt=""
                                        className="img-fluid"
                                    />
                                ) : (
                                    <i id="temp-pp" className="bi bi-person-fill"></i>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                height: "70px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                borderRight: "1px white solid",
                                paddingRight: "15px",
                            }}
                        >
                            {
                                user.tokenSetting ? (
                                    <>
                                        <h6 className="m-0 p-0">{t("Total e-Token")}</h6>
                                        <h6 className="m-0 p-0 mx-auto">
                                            <strong>{user.token}</strong>
                                        </h6>
                                    </>
                                ) : null
                            }
                            {
                                user.loyalty_point ? (
                                    <>
                                        <h6 className="m-0 p-0">{t("Total")} {user.loyalty_point_name}</h6>
                                        <h6 className="m-0 p-0 mx-auto">
                                            <strong>{user.point}</strong>
                                        </h6>
                                    </>
                                ) : null
                            }
                        </div>
                    )}

                    <div id="user-info">
                        <h6 className="m-0 p-0">{user ? user.name : null}</h6>
                        <h5 className="m-0 p-0">
                            <strong>{user ? user.memberId : null}</strong>
                        </h5>
                        <div className="d-flex m-0 p-0">
                            <p className="m-0 p-0">
                                <small>
                                    {t("Total Wash")} :{" "}
                                    <span style={{ color: "#ffd109" }}>
                                        <strong>{user ? user.totalWash : 0}</strong>
                                    </span>
                                </small>
                            </p>
                            <p className="m-0 p-0 ms-3">
                                <small>
                                    {t("Total Dry")} :{" "}
                                    <span style={{ color: "#ffd109" }}>
                                        <strong>{user ? user.totalDry : 0}</strong>
                                    </span>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>

                {user.tokenSetting && (user.reload || user.transfer) ? (
                    <div /*style={{ marginLeft: "11%", marginTop: "5px", display: "flex", padding: 0 }}*/
                    >
                        {/* <p className="m-0 p-0 me-5">
                            e-Token: <span className="font-weight-bold">{user.token}</span>
                            </p> */}
                        <div className="row" style={{ marginBottom: "0px" }}>
                            {
                                user.reload ? (
                                    <Link
                                        to="/user/reload"
                                        style={{ textDecoration: "none" }}
                                        className="col"
                                    >
                                        <div id="reload" className="reload-button">
                                            <i className="bi bi-plus-lg"></i> {t("Reload")}
                                        </div>
                                    </Link>
                                ) : null
                            }
                            {
                                user.transfer ? (
                                    <Link
                                        to="/user/transfer"
                                        style={{ textDecoration: "none" }}
                                        className="col"
                                    >
                                        <div id="transfer" className="reload-button">
                                            <i className="bi bi-arrow-left-right"></i> {t("Transfer")}
                                        </div>
                                    </Link>
                                ) : null
                            }
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default LaundroHeader;