import { useState } from "react";
import { useTranslation } from "react-i18next";

import Combined from "../stamp/Combined";
import Washer from "../stamp/Washer";
import Dryer from "../stamp/Dryer";
import NewsSlider from "../NewsSlider";

const LaundroDashboardBody = ({ user, renderTimer, history }) => {

    const [selected, setSelect] = useState("washer");

    const { t } = useTranslation();

    const selectColor = (val) => {
        if (val === selected) {
            return "selected-button";
        } else {
            return null;
        }
    };

    const renderPage = () => {
        if (user.stamp) {
            if (user.combinedSetting) {
                return <Combined />;
            } else {
                if (user.specificStamp === "both") {
                    if (selected === "washer") {
                        return <Washer />;
                    } else if (selected === "dryer") {
                        return <Dryer />;
                    } else {
                        return (
                            <div className="text-center">
                                <h1>{t("Something went wrong")} :/</h1>
                            </div>
                        );
                    }
                } else {
                    if (user.specificStamp === "washerOnly") {
                        return <Washer />;
                    } else if (user.specificStamp === "dryerOnly") {
                        return <Dryer />;
                    } else {
                        return (
                            <div className="text-center">
                                <h1>{t("Something went wrong")} :/</h1>
                            </div>
                        );
                    }
                }
            }
        }
    };

    return (
        <div id="user-body">
            {
                user.loyalty_point && user.point_expiry ? (
                    <p className="text-center pt-3"><b>{user.expirePoint}</b> {user.loyalty_point_name} {t("expiring on")} <b>{new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</b></p>
                ) : null
            }

            {
                renderTimer
            }

            {!user.stamp ||
                user.combinedSetting ||
                user.specificStamp !== "both" ? null : (
                <div className="px-3 mt-4">
                    <div id="type-selector">
                        <button
                            id="washer"
                            onClick={(e) => setSelect("washer")}
                            className={selectColor("washer")}
                        >
                            {t("Washer")}
                        </button>
                        <button
                            id="dryer"
                            onClick={(e) => setSelect("dryer")}
                            className={selectColor("dryer")}
                        >
                            {t("Dryer")}
                        </button>
                    </div>
                </div>
            )}

            <div className="mt-3">{renderPage()}</div>

            <div>
                <h5>
                    <strong>{t("News")}</strong>
                </h5>
                <div>
                    <NewsSlider page="dashboard" history={history} />
                </div>
            </div>
        </div>
    )
}

export default LaundroDashboardBody;