import { useState } from 'react';
import { connect } from 'react-redux';
import { devLogin } from '../../../redux/actions/devActions';
import tAntlysisLogo from '../../../assets/images/logo/tAntlysisLogo.png';


const DevLogin = ({ login, dev }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = e => {
        e.preventDefault();
        setUsername("");
        setPassword("");
        login({username, password});
    };
    
    return (
        <div>
            <img src={tAntlysisLogo} alt="antlysis" className="img-fluid" id="login-logo"/>
            <form className="mx-5 px-3" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input type="text" id="username" name="username" className="browser-default form-control" onChange={e => setUsername(e.target.value)} value={username} required />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input type="password" name="password" id="password" className="browser-default form-control" onChange={e => setPassword(e.target.value)} value={password} required />
                </div>

                {
                    dev.isLoading
                    ? (
                        <div className="text-center">
                            <div className="spinner-border text-primary">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <input type="submit" value="Login" className="btn btn-primary form-control mt-5 font-weight-bold" />
                        </div>
                    )
                }

                {
                    dev.error
                    ? (
                        <div className="alert alert-warning mt-3" role="alert">
                            <strong>{dev.error}</strong>
                        </div>
                    )
                    : null
                }
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        dev: state.dev
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: data => dispatch(devLogin(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevLogin);