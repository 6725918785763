import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Loading from "../../components/Loading";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import i18n from "i18next";
import { dateFormat, timeFormat } from "../../../../dynamicController";

class TokenDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			toggled: false,
			loading: true
		};
	}

	componentDidMount() {
		if (!this.props.location.state || !this.props.admin.admin || !this.props.admin.admin.token_report) {
			this.props.history.push("/admin/dashboard");
		} else {
			axios
				.post("/api/admin/report/token/detail", this.props.location.state, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
				.then(res => {
					this.setState({
						...this.state,
						data: res.data,
						loading: false
					});
				})
				.catch(err => this.props.history.push("/admin/dashboard"));
		}
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	render() {
		let tableData = {
			columns: [
				{
					label: i18n.t("User Name"),
					field: "name",
					sort: "asc"
				},
				{
					label: i18n.t("Phone Number"),
					field: "number",
					sort: "asc"
				},
				{
					label: i18n.t("Email Address"),
					field: "email",
					sort: "asc"
				},
				{
					label: i18n.t("Member ID"),
					field: "memberId",
					sort: "asc"
				},
				{
					label: i18n.t("e-Token Spent"),
					field: "tokenSpent",
					sort: "asc"
				},
				{
					label: i18n.t("Machine Type"),
					field: "machineType",
					sort: "asc"
				},
				{
					label: i18n.t("Transaction ID"),
					field: "transactionId",
					sort: "asc"
				},
				{
					label: i18n.t("Transaction Date"),
					field: "date",
					sort: "asc"
				},
				{
					label: i18n.t("Status"),
					field: "status",
					sort: "asc"
				},
			],
			rows: this.state.data
		};

		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					email: this.state.data[i].email,
					memberId: this.state.data[i].memberId,
					tokenSpent: this.state.data[i].tokenSpent,
					machineType: this.state.data[i].machineType,
					transactionId: this.state.data[i].transactionId,
					date: this.state.data[i].date,
					status: this.state.data[i].status
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "User Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Email Address", key: "email" },
				{ label: "Member ID", key: "memberId" },
				{ label: "e-Token Spent", key: "tokenSpent" },
				{ label: "Machine Type", key: "machineType" },
				{ label: "Transaction ID", key: "transactionId" },
				{ label: "Transaction Date", key: "date" },
				{ label: "Status", key: "status" }
			],
			data: csv
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">{i18n.t("e-Token Detailed Report")} ({this.props.location.state.outletName})</h5>
							</div>

							<div className="card-body">
								{
									this.state.loading ? (
										<Loading />
									) : (
										<div>
											<MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
											<div className="d-grid gap-2 d-md-flex justify-content-md-end">
												<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`etoken_detail_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
													{i18n.t("Download Report")}
												</CSVLink>
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		token: state.admin.token,
		admin: state.admin
	};
};

export default connect(mapStateToProps, null)(TokenDetail);
